import { createIsRouteParamsHidden } from "legacy/components/propertyControls/Routing/utils";
import { PropertyPaneControlConfig } from "legacy/constants/PropertyControlConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import { getRoutesList } from "legacy/selectors/routeSelectors";

export enum LinkToType {
  Page = "page",
  CustomUrl = "customUrl",
  App = "app",
}

export function isApp(props: { linkTo: LinkToType }) {
  return props.linkTo === LinkToType.App;
}

export function isPage(props: { linkTo: LinkToType }) {
  return props.linkTo === LinkToType.Page;
}

export function isCustomUrl(props: { linkTo: LinkToType }) {
  return props.linkTo === LinkToType.CustomUrl;
}

const isRouteParamsHidden = createIsRouteParamsHidden({
  isHiddenForType: (props) => !isPage(props),
});

type QueryParamsPropertyOverrides = Partial<
  PropertyPaneControlConfig & { id?: string }
>;

export const queryParamsProperty = (
  overrides: QueryParamsPropertyOverrides = {},
) => ({
  propertyName: "queryParams",
  label: "Query parameters",
  controlType: "KEY_VALUE_INPUT",
  headerControlType: "ADD_KEY_VALUE",
  hidden: isCustomUrl,
  isBindProperty: false,
  isTriggerProperty: false,
  isJSConvertible: true,
  ...overrides,
});

export const keepExistingQueryParamsProperty = (
  overrides: QueryParamsPropertyOverrides = {},
) => ({
  propertyName: "keepExistingQueryParams",
  label: "Keep existing query parameters",
  helpText:
    "When enabled, the new query parameters are merged with the old parameters",
  controlType: "SWITCH",
  isJSConvertible: true,
  isBindProperty: true,
  isTriggerProperty: false,
  ...overrides,
});

export const openInNewTabProperty = (
  overrides: QueryParamsPropertyOverrides = {},
) => ({
  propertyName: "openInNewTab",
  label: "Open in new tab",
  helpText: "Opens the link in a new tab",
  controlType: "SWITCH",
  isJSConvertible: true,
  isBindProperty: true,
  isTriggerProperty: false,
  validation: VALIDATION_TYPES.BOOLEAN,
  ...overrides,
});

export const urlProperty = (
  overrides: QueryParamsPropertyOverrides = {},
): PropertyPaneControlConfig => ({
  propertyName: "url",
  label: "URL",
  helpText:
    "The URL to hyperlink to. Can be a relative path to another page (i.e. /users) or a full URL",
  controlType: "INPUT_TEXT",
  placeholderText: "https://superblocks.com",
  isBindProperty: true,
  isTriggerProperty: false,
  validation: VALIDATION_TYPES.URL_FOR_HYPERLINK,
  hidden: (props) => !isCustomUrl(props),
  ...overrides,
});

export const pageProperties = (
  overridesRouteId: QueryParamsPropertyOverrides = {},
  overridesRouteParams: QueryParamsPropertyOverrides = {},
): PropertyPaneControlConfig[] => [
  {
    propertyName: "routeId",
    label: "Page",
    controlType: "SELECT_ROUTE_DROP_DOWN",
    isBindProperty: false,
    isTriggerProperty: false,
    placeholderText: "Select a page",
    hidden: (props) => !isPage(props),
    ...overridesRouteId,
  },
  {
    propertyName: "routeParams",
    label: "Route parameters",
    helpText: "Values for the route parameters required by this route",
    controlType: "SELECT_ROUTE_PARAMS",
    hidden: isRouteParamsHidden,
    getAdditionalHiddenData: {
      routes: getRoutesList,
    },
    isBindProperty: false,
    isTriggerProperty: false,
    ...overridesRouteParams,
  },
];

export const appProperty = (
  overrides: QueryParamsPropertyOverrides = {},
): PropertyPaneControlConfig => ({
  propertyName: "targetApp",
  label: "Deployed app",
  controlType: "SELECT_APP_DROP_DOWN",
  hidden: (props) => !isApp(props),
  isBindProperty: false,
  isTriggerProperty: false,
  placeholderText: "Select a deployed app",
  ...overrides,
});

export const linkToProperty = (
  overrides: QueryParamsPropertyOverrides = {},
): PropertyPaneControlConfig => ({
  propertyName: "linkTo",
  label: "Link to",
  controlType: "RADIO_BUTTON_GROUP",
  options: [
    {
      value: LinkToType.Page,
      label: "Page",
    },
    {
      value: LinkToType.App,
      label: "App",
    },
    {
      value: LinkToType.CustomUrl,
      label: "URL",
    },
  ],
  defaultValue: LinkToType.Page,
  isBindProperty: false,
  isTriggerProperty: false,
  ...overrides,
});
