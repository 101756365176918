import { GroupMemberStatus, UserType } from "@superblocksteam/shared";
import { Tooltip } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import TruncatedTextTooltip from "components/TruncatedTextTooltip";
import { PlainLink } from "components/ui/Link";
import { UnderlinedLinkClass } from "components/ui/Link";
import { DeleteModal } from "components/ui/Modal";
import RecommendedTable, {
  DEFAULT_TABLE_PAGE_SIZE,
  RecColumn,
  RecommendedTableRef,
  TagStyle,
} from "components/ui/RecommendedTable";
import { MANAGE_USERS, OWNER_ROLE_NAME } from "constants/rbac";
import { useFeatureFlag } from "hooks/ui";
import { useAuthorizationCheck } from "hooks/ui/rbac/useAuthorizationCheck";
import { SUPPORT_EMAIL_ADDRESS } from "legacy/constants/routes";
import { getCurrentUser } from "legacy/selectors/usersSelectors";
import { OrganizationRoleDropdown } from "pages/Permissions/RoleDropdown";
import { USER_PAGE_URL } from "pages/routes";
import { Flag } from "store/slices/featureFlags";
import { selectOnlyOrganization } from "store/slices/organizations";
import { nameWithEmail } from "store/utils/group";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { sendSuccessUINotification } from "utils/notification";
import { deactivateUserRequest } from "./client";
import { UserToRender, formatDate } from "./constants";

const NameStyle = styleAsClass`
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ActiveStyle = styleAsClass`
  color: ${colors.ACCENT_GREEN};
  border: 1px solid ${colors.ACCENT_GREEN}28;
  background: ${colors.ACCENT_GREEN}14;
`;

const DeactivatedStyle = styleAsClass`
  color: ${colors.DANGER};
  border: 1px solid ${colors.DANGER}28;
  background: ${colors.DANGER}14;
`;

const PendingStyle = styleAsClass`
  color: ${colors.GREY_500};
  border: 1px solid ${colors.GREY_100};
  background: ${colors.GREY_50};
`;

const getTagStyle = (status: string) => {
  switch (status) {
    case "active":
      return ActiveStyle;
    case "deactivated":
      return DeactivatedStyle;
    case "pending":
      return PendingStyle;
    default:
      return TagStyle;
  }
};

type ColType = RecColumn<UserToRender>;

const renderTime = ({ value }: { value: Date | undefined }) =>
  value ? (
    <div>{formatDate(value)}</div>
  ) : (
    <div style={{ color: colors.GREY_200 }}>–</div>
  );

const renderStatus = ({ value }: { value: string }) => (
  <div style={{ textTransform: "capitalize" }}>
    <div className={`${TagStyle} ${getTagStyle(value)}`}>{value}</div>
  </div>
);

const UserList = ({
  type,
  users,
  setUsers,
  loading,
  totalCount,
  groupId,
  updateUser,
  onMultiRowsSelectChange,
  userTableRef,
}: {
  type: UserType;
  users: UserToRender[];
  setUsers: React.Dispatch<React.SetStateAction<UserToRender[]>>;
  loading: boolean;
  totalCount: number;
  groupId: string;
  updateUser: (userId: string, updates: Partial<UserToRender>) => void;
  onMultiRowsSelectChange?: (selectedRows: UserToRender[]) => void;
  userTableRef: React.Ref<RecommendedTableRef<UserToRender>>;
}) => {
  const navigate = useNavigate();
  const rbacV2Enabled = useFeatureFlag(Flag.ENABLE_RBAC_V2_ENFORCEMENT);

  const [userToModify, setUserToModify] = useState<UserToRender | undefined>(
    undefined,
  );

  const currentUser = useSelector(getCurrentUser);

  const [canManageUsers] = useAuthorizationCheck([MANAGE_USERS]);

  const canCheckDetails = rbacV2Enabled && canManageUsers;

  const removeTooltip = useCallback(
    (member: UserToRender) => {
      if (!canManageUsers) {
        return `You do not have permission to remove users.`;
      }

      if (member.status === GroupMemberStatus.DEACTIVATED) {
        return `Contact Superblocks Support at ${SUPPORT_EMAIL_ADDRESS} to reactivate this user.`;
      }

      if (currentUser?.id && member.email === currentUser?.email) {
        return `You cannot remove yourself from the organization.`;
      }
      return "";
    },
    [canManageUsers, currentUser?.email, currentUser?.id],
  );

  const roleAssignmentsEnabled = useFeatureFlag(
    Flag.ENABLE_RBAC_ROLE_ASSIGNMENTS,
  );

  const onRoleUpdated = useCallback(
    (userId: string, updates: Record<string, string | null>) => {
      updateUser(userId, updates);
    },
    [updateUser],
  );

  const onNameClick = useCallback(
    (user: UserToRender) => {
      navigate({ pathname: USER_PAGE_URL(user.id, "details") });
    },
    [navigate],
  );

  const renderRole = useCallback(
    ({ value, row }: { value: string; row: { original: UserToRender } }) => {
      const isDeactivated = row.original.status === "deactivated";
      return (
        <div style={{ marginLeft: "-12px" }}>
          <OrganizationRoleDropdown
            currentRole={value}
            currentRoleName={row.original.roleName}
            principalId={row.original.id}
            principalType="user"
            principalName={row.original.name}
            onRoleChanged={onRoleUpdated}
            isDisabled={
              !canManageUsers || row.original.status === "deactivated"
            }
            currentRoleAssignmentId={row.original.roleAssignmentId}
            tooltip={
              !canManageUsers ? (
                "You do not have permission to assign roles"
              ) : isDeactivated ? (
                <div>
                  Deactivated user roles cannot be changed.{" "}
                  <PlainLink
                    href={`mailto:${SUPPORT_EMAIL_ADDRESS}`}
                    target="_blank"
                  >
                    Contact support
                  </PlainLink>{" "}
                  to reactivate users.
                </div>
              ) : undefined
            }
          />
        </div>
      );
    },
    [onRoleUpdated, canManageUsers],
  );

  const columns: ColType[] = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value, cell }) => {
          return (
            <TruncatedTextTooltip
              text={
                cell.row.original.email === currentUser?.email
                  ? `${value} (You)`
                  : value
              }
              onClick={
                canCheckDetails
                  ? () => onNameClick(cell.row.original)
                  : undefined
              }
              className={`${NameStyle} ${
                canCheckDetails ? UnderlinedLinkClass : ""
              }`}
            />
          );
        },
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value }) => (
          <TruncatedTextTooltip text={value} className={NameStyle} />
        ),
      },
      {
        Header: "Last login",
        accessor: "lastLogin",
        Cell: renderTime,
        hidden: type !== UserType.EXTERNAL,
      },
      ...(roleAssignmentsEnabled
        ? ([
            {
              Header: "Role",
              accessor: "roleId",
              Cell: renderRole,
              hidden: type === UserType.EXTERNAL,
            },
          ] as ColType[])
        : []),
      {
        Header: "Status",
        accessor: "status",
        Cell: renderStatus,
        hidden: type === UserType.EXTERNAL,
      },
    ],
    [
      type,
      roleAssignmentsEnabled,
      renderRole,
      currentUser?.email,
      canCheckDetails,
      onNameClick,
    ],
  );

  const menuItems = useCallback(
    (user: UserToRender) => {
      const tooltip = removeTooltip(user);
      if (user.status === "deactivated" || user.roleName === OWNER_ROLE_NAME) {
        return [];
      }
      return [
        ...(rbacV2Enabled
          ? [
              {
                key: "view-details",
                label: "View details",
                onClick: () => {
                  navigate({ pathname: USER_PAGE_URL(user.id, "details") });
                },
                disabled: !canCheckDetails,
                tooltip: "you do not have permission to view user details",
              },
            ]
          : []),
        {
          key: "remove-user",
          label: (
            <Tooltip title={tooltip}>
              <div data-test="remove-invite">
                {user.status === "active" ? "Deactivate user" : "Remove invite"}
              </div>
            </Tooltip>
          ),
          onClick: () => {
            setUserToModify(user);
            setDeleteModalOpen(true);
          },
          "data-test": "delete-user-button",
          disabled: Boolean(tooltip),
        },
      ];
    },
    [canCheckDetails, rbacV2Enabled, navigate, removeTooltip],
  );

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const currentOrg = useSelector(selectOnlyOrganization);

  const [deleteError, setDeleteError] = useState<string>();
  const [isDeleting, setIsDeleting] = useState(false);
  const onDelete = useCallback(async () => {
    if (!userToModify) {
      return;
    }
    setIsDeleting(true);
    try {
      const { group, error } = await deactivateUserRequest({
        orgId: currentOrg.id,
        userEmail: userToModify.email,
        groupId,
      });
      if (group) {
        if (userToModify.status === "pending") {
          setUsers((users) =>
            users.filter((u) => u.email !== userToModify.email),
          );
        } else {
          updateUser(userToModify.id, {
            status: GroupMemberStatus.DEACTIVATED,
          });
        }
        setUserToModify(undefined);
        setDeleteModalOpen(false);
        sendSuccessUINotification({
          message:
            userToModify.status === "pending"
              ? "Invite was revoked"
              : `Deactivate ${nameWithEmail(
                  userToModify.name,
                  userToModify.email,
                )}`,
        });
      }
      if (error) {
        setDeleteError(error ?? "Failed to delete user");
      }
    } catch (e: any) {
      setDeleteError(e?.message ?? "Failed to delete user");
    }
    setIsDeleting(false);
  }, [currentOrg.id, groupId, setUsers, updateUser, userToModify]);

  const onCancel = useCallback(() => {
    setDeleteModalOpen(false);
    setUserToModify(undefined);
  }, []);

  const rowSelectable = useCallback(
    (user: UserToRender) =>
      user.status !== "deactivated" && user.roleName !== OWNER_ROLE_NAME,
    [],
  );

  const getRowSelectableTooltip = useCallback(
    (user: UserToRender) =>
      user.status === "deactivated"
        ? "Deactivated user roles can't be changed"
        : user.roleName === OWNER_ROLE_NAME
          ? "Owner role can't be changed"
          : canManageUsers
            ? ""
            : "You do not have permission to manage users",
    [canManageUsers],
  );

  return (
    <>
      <RecommendedTable<UserToRender>
        ref={userTableRef}
        data={users}
        dataLabel="users"
        uniqueKey="email"
        columns={columns}
        actionMenuItems={
          type === UserType.SUPERBLOCKS && canManageUsers
            ? menuItems
            : undefined
        }
        // using totalCount to avoid frequent update when search which could cause crash
        paginationOptions={
          totalCount > DEFAULT_TABLE_PAGE_SIZE
            ? { pageSize: DEFAULT_TABLE_PAGE_SIZE }
            : undefined
        }
        loading={loading}
        // TODO(rbac): make it always true once we ga rbac v2, currently based on roleAssignmentsEnabled because change role is the only action
        enableMultiSelect={roleAssignmentsEnabled}
        onMultiRowsSelectChange={onMultiRowsSelectChange}
        rowSelectable={
          type === UserType.SUPERBLOCKS && canManageUsers
            ? rowSelectable
            : undefined
        }
        getRowSelectableTooltip={getRowSelectableTooltip}
      />
      <DeleteModal
        open={deleteModalOpen}
        title={
          userToModify?.status === "active"
            ? "Deactivate user"
            : "Remove invite"
        }
        onCancel={onCancel}
        onDelete={onDelete}
        error={deleteError}
        isDeleting={isDeleting}
        dataTestName="user"
        confirmText={
          userToModify?.status === "active" ? "Deactivate" : "Remove"
        }
      >
        User <b>{userToModify?.name}</b>
        {userToModify?.status === "active"
          ? ` will be deactivated from your
        organization.`
          : `'s invite will be removed from your organization.`}
      </DeleteModal>
    </>
  );
};

export default UserList;
