import {
  containsBindingsAnywhere,
  Dimension,
  Padding,
  PerCornerBorderRadius,
  PerSideBorder,
  Typographies,
  WidgetTypes,
} from "@superblocksteam/shared";

import { flatMap, get, set, unset } from "lodash";
import moment from "moment";
import tinycolor from "tinycolor2";
import {
  NEUTRAL_COLOR_KEYS,
  THEME_COLOR_KEYS,
} from "legacy/components/propertyControls/ColorPickerControl";

import { getOutputComputedValue } from "legacy/components/propertyControls/ComputeTablePropertyControl";
import {
  PropertyPaneConfig,
  PropertyPaneControlConfig,
} from "legacy/constants/PropertyControlConstants";
import { Position } from "legacy/constants/WidgetConstants";
import { ISO_DATE_FORMAT } from "legacy/constants/WidgetValidation";
import {
  getWidgetPropertyThemeValue,
  isPropertyRemovableOrResettable,
} from "legacy/pages/Editor/PropertyPane/widgetPropertyPaneConfigUtils";
import { GeneratedTheme } from "legacy/themes";
import {
  DEFAULT_INPUT_TEXT_STYLE_VARIANT,
  SB_CUSTOM_TEXT_STYLE,
} from "legacy/themes/typographyConstants";
import { DynamicPath } from "legacy/utils/DynamicBindingTypes";
import { unsetPropertyPath } from "legacy/utils/DynamicBindingUtils";
import { WidgetProps } from "legacy/widgets/BaseWidget/types";
import { DEFAULT_BUTTON_WIDGET_TEXT_STYLE_VARIANT } from "legacy/widgets/ButtonWidget/constants";
import { normalizeDateFormat } from "legacy/widgets/DatePickerWidget/utils";
import {
  DEFAULT_INPUT_WIDGET_INPUT_STYLE_VARIANT,
  DEFAULT_INPUT_WIDGET_LABEL_STYLE_VARIANT,
} from "legacy/widgets/InputWidget/InputWidgetConstants";
import {
  DEFAULT_CELL_TEXT_STYLE_VARIANT,
  DEFAULT_HEADING_TEXT_STYLE_VARIANT,
  TABLE_COLUMN_HEADER_DEFAULT_TYPOGRAPHY,
} from "legacy/widgets/TableWidget/TableComponent/Constants";
import { customStylesProperties } from "legacy/widgets/styleProperties";
import { getMaterialIcons } from "utils/materialIconUtils";
import { AllFlags } from "../featureFlags";

let iconDataCache: Record<string, any> | undefined;
type DiscardedEdit = {
  propertyName: string;
  reason: string;
  propertyValue: unknown;
};

const parseDimension = (
  value: string | number | object,
): undefined | Dimension<"px"> => {
  if (typeof value === "string") {
    if (value.endsWith("px")) {
      const val = parseInt(value.replace("px", ""));
      if (isNaN(val)) {
        return undefined;
      }
      return Dimension.px(val);
    } else {
      // try to just parse the number
      const val = parseInt(value);
      if (isNaN(val)) {
        return undefined;
      }
      return Dimension.px(val);
    }
  }
  if (typeof value === "number") {
    return Dimension.px(value);
  }
  if (
    typeof value === "object" &&
    value != null &&
    "value" in value &&
    (typeof value.value === "number" || typeof value.value === "string")
  ) {
    let numValue = value.value;
    if (typeof numValue === "string") {
      numValue = parseInt(numValue);
    }
    return Dimension.px(numValue);
  }
  if (typeof value === "object" && value != null && "width" in value) {
    return parseDimension(value.width as any);
  }
};

const UNEDITABLE_PROPERTY_NAMES = new Set([
  "widgetId",
  "width",
  "height",
  "minWidth",
  "minHeight",
  "maxWidth",
  "maxHeight",
]);

const sanitizeEdit = async ({
  propertyName,
  config,
  edits,
  discardedEdits,
  dynamicProperties,
  previousWidget,
  theme,
  featureFlags,
  changedKeys,
  dependentChangesByWidgetId,
}: {
  propertyName: string;
  config: PropertyPaneControlConfig;
  edits: Record<string, any>;
  discardedEdits: any[];
  dynamicProperties: DynamicPath[];
  previousWidget: Partial<WidgetProps>;
  theme: GeneratedTheme;
  featureFlags: Partial<AllFlags>;
  changedKeys: string[];
  dependentChangesByWidgetId: Record<string, unknown>;
}) => {
  const addDynamicProperty = (key: string) => {
    dynamicProperties.push({ key });
  };
  const removeDynamicProperty = (key: string) => {
    const indexToRemove = dynamicProperties.findIndex(
      (prop) => prop.key === key,
    );
    if (indexToRemove !== -1) {
      dynamicProperties.splice(indexToRemove, 1);
    }
  };

  const copyDerivedColumnChange = (propertyName: string, value: string) => {
    const columnName = propertyName.split(".")[1];
    if (
      columnName &&
      ((previousWidget as any).derivedColumns?.[columnName] ||
        !(previousWidget as any).primaryColumns?.[columnName])
    ) {
      const restOfPath = propertyName.split(".").slice(1).join(".");
      const derivedProperty = `derivedColumns.${restOfPath}`;
      set(edits, derivedProperty, value);
    }
  };

  if (UNEDITABLE_PROPERTY_NAMES.has(propertyName)) {
    discardedEdits.push({
      propertyName,
      reason: `Cannot update ${propertyName}`,
    });
    unset(edits, propertyName);
    removeDynamicProperty(propertyName);
    return;
  }

  const value = get(edits, propertyName);
  // special case for text style properties of columns. These won't use the text_Style control, but should be handled the same way
  if (
    propertyName.startsWith("primaryColumns") &&
    propertyName.includes("cellProps.textStyle")
  ) {
    // todo: probably should sanitize this
    if (
      !propertyName.includes("variant") &&
      !propertyName.includes("textColor")
    ) {
      const parentPropertyName = propertyName.split(".").slice(0, -1).join(".");
      set(edits, `${parentPropertyName}.variant`, SB_CUSTOM_TEXT_STYLE);
    }
  }

  const { isRemovable, isResettable } = isPropertyRemovableOrResettable(
    config,
    previousWidget,
    { theme, featureFlags },
  );
  if (value === undefined && (isRemovable || isResettable)) {
    // the property has been removed or reset, there's nothing to verify
    return;
  }

  // based on the control type, we need to validate in different ways
  switch (config.controlType) {
    case "STICKY_POSITION": {
      if (!Object.values(Position).includes(value)) {
        // if the value is not a valid position, set it to undefined, which is the default
        set(edits, propertyName, undefined);
      }
      break;
    }
    case "LOCATION_SEARCH": {
      // make it dynamic
      addDynamicProperty(propertyName);
      break;
    }
    case "DATE_PICKER": {
      if (typeof value !== "string") {
        discardedEdits.push({
          propertyName,
          reason: "Invalid date value",
          propertyValue: value,
        });
      }
      const dateFormat =
        get(edits, "dateFormat") || get(previousWidget, "dateFormat");
      const normalizedFormat = normalizeDateFormat(
        dateFormat || ISO_DATE_FORMAT,
      );
      if (!moment(value, normalizedFormat, true).isValid()) {
        addDynamicProperty(propertyName);
      }
      break;
    }
    case "COLOR_PICKER": {
      const {
        isValid,
        isDynamic,
        value: parsedValue,
      } = colorPickerContainsColor(value);
      set(edits, propertyName, parsedValue);
      if (!isValid) {
        discardedEdits.push({
          propertyName,
          reason: "Invalid color value",
          propertyValue: value,
        });
      } else if (!isDynamic) {
        removeDynamicProperty(propertyName);
      } else {
        addDynamicProperty(propertyName);
      }
      break;
    }
    case "TEXT_STYLE": {
      if (typeof value !== "object" || value === null) {
        discardedEdits.push({
          propertyName,
          reason: "Invalid text style value",
          propertyValue: value,
        });
        unset(edits, propertyName);
        break;
      }
      sanitizeTextStyle({
        previousWidget,
        theme,
        value,
        propertyName,
        setTopLevelProperty: (path: string, value: any) => {
          if (value === undefined) {
            unset(edits, path);
          } else {
            set(edits, path, value);
          }
        },
        discardedEdits,
        dynamicProperties,
        featureFlags,
        changedKeys,
        dependentChangesByWidgetId,
      });
      break;
    }
    case "BORDER_CONTROL": {
      if (typeof value !== "object" || value === null) {
        discardedEdits.push({
          propertyName,
          reason: "Invalid border value",
          propertyValue: value,
        });
        unset(edits, propertyName);
        break;
      }
      const parsedBorder: any = {};
      let borderColor: string | undefined;
      const themeValue = getWidgetPropertyThemeValue({
        props: config,
        propertyName,
        itemProperties: previousWidget,
        flags: featureFlags,
        theme,
        widgets: {},
      });
      for (const side of [
        "left",
        "right",
        "top",
        "bottom",
      ] as (keyof PerSideBorder)[]) {
        const existingColor: string | undefined =
          get(previousWidget, `${propertyName}.${side}.color`) ??
          themeValue?.[side]?.color;
        const existingWidth =
          get(previousWidget, `${propertyName}.${side}.width`) ??
          themeValue?.[side]?.width;

        const updatedColor =
          get(value, `${side}.color`) !== existingColor
            ? get(value, `${side}.color`)
            : undefined;

        if (updatedColor) {
          const {
            value: color,
            isValid,
            isDynamic,
          } = colorPickerContainsColor(updatedColor);
          if (isValid && !isDynamic) {
            borderColor = color;
          }
        }

        let updatedWidth = get(value, `${side}.width`);
        if (updatedWidth) {
          updatedWidth = parseDimension(updatedWidth);
        }
        parsedBorder[side] = {
          style: "solid",
          width: updatedWidth ?? existingWidth,
          color: updatedColor ?? existingColor,
        };
      }
      // if borderColor is set, set it on all sides
      if (borderColor) {
        for (const side of [
          "left",
          "right",
          "top",
          "bottom",
        ] as (keyof PerSideBorder)[]) {
          parsedBorder[side] = {
            ...parsedBorder[side],
            color: borderColor,
          };
        }
      }
      set(edits, propertyName, parsedBorder);

      break;
    }
    case "PADDING_CONTROL":
    case "MARGIN_CONTROL": {
      if (typeof value !== "object" || value === null || Array.isArray(value)) {
        discardedEdits.push({
          propertyName,
          reason: "Invalid padding value",
          propertyValue: value,
        });
        unset(edits, propertyName);
        break;
      }
      const themeValue = getWidgetPropertyThemeValue({
        props: config,
        propertyName,
        itemProperties: previousWidget,
        flags: featureFlags,
        theme,
        widgets: {},
      });
      const parsedPadding: Padding = {};
      (["left", "right", "top", "bottom"] as (keyof Padding)[]).forEach(
        (side) => {
          if (!value[side]) {
            parsedPadding[side] = get(
              previousWidget,
              `${propertyName}.${side}`,
              get(themeValue, side),
            ) as Dimension<"px"> | undefined;
          } else {
            parsedPadding[side] = parseDimension(value[side]);
          }
        },
      );
      set(edits, propertyName, parsedPadding);
      break;
    }
    case "BORDER_RADIUS_CONTROL": {
      if (typeof value !== "object" || value === null) {
        discardedEdits.push({
          propertyName,
          reason: "Invalid border radius value",
          propertyValue: value,
        });
        unset(edits, propertyName);
        break;
      }
      const themeValue = getWidgetPropertyThemeValue({
        props: config,
        propertyName,
        itemProperties: previousWidget,
        flags: featureFlags,
        theme,
        widgets: {},
      });
      const parsedBorderRadius: Record<string, Dimension<"px">> = {};
      (
        [
          "topLeft",
          "topRight",
          "bottomLeft",
          "bottomRight",
        ] as (keyof PerCornerBorderRadius)[]
      ).forEach((corner: keyof PerCornerBorderRadius) => {
        if (!value[corner]) {
          parsedBorderRadius[corner] = get(
            previousWidget,
            `${propertyName}.${corner}`,
            get(themeValue, corner),
          ) as Dimension<"px">;
        } else {
          parsedBorderRadius[corner] =
            parseDimension(value[corner]) ?? Dimension.px(0);
        }
      });
      set(edits, propertyName, parsedBorderRadius);
      break;
    }
    case "SWITCH": {
      if (value === "true") {
        set(edits, propertyName, true);
        removeDynamicProperty(propertyName);
      } else if (value === "false") {
        set(edits, propertyName, false);
        removeDynamicProperty(propertyName);
      } else if (typeof value !== "boolean") {
        if (config.isJSConvertible) {
          addDynamicProperty(propertyName);
        } else {
          discardedEdits.push({
            propertyName,
            reason: "Invalid boolean value",
          });
          unset(edits, propertyName);
        }
      } else {
        removeDynamicProperty(propertyName);
      }
      break;
    }
    case "DROP_DOWN":
    case "RADIO_BUTTON_GROUP":
    case "RADIO_BUTTON": {
      if (config.options) {
        // check if the value is in the options array
        const isInOptions = config.options.some(
          (opt) => opt.value === value || opt === value,
        );
        const isNullOption = config.options.some(
          (opt) => opt.value === undefined,
        );
        if (isInOptions) {
          removeDynamicProperty(propertyName);
        } else if (config.isJSConvertible) {
          addDynamicProperty(propertyName);
        } else if (isNullOption) {
          // use the default option
          set(edits, propertyName, undefined);
        } else {
          // its invalid
          discardedEdits.push({
            propertyName,
            reason: "Invalid dropdown value",
            propertyValue: value,
          });
          // clear the change
          unset(edits, propertyName);
        }
      }
      // otherwise, it's using options func or selector so just leave as is
      break;
    }
    case "ICON_SELECTOR": {
      if (typeof value === "string") {
        if (!iconDataCache) {
          const { iconData } = await getMaterialIcons();
          iconDataCache = iconData;
        }
        if (iconDataCache[value]) {
          removeDynamicProperty(propertyName);
        } else {
          addDynamicProperty(propertyName);
        }
      } else {
        addDynamicProperty(propertyName);
      }
      break;
    }
    case "COMPUTE_TABLE_VALUE": {
      if (typeof value === "string" && !value.includes("tableData")) {
        const computedValue = getOutputComputedValue(
          previousWidget?.widgetName ?? "",
          value,
        );
        set(edits, propertyName, computedValue);
        copyDerivedColumnChange(propertyName, computedValue);
      }
      break;
    }
    case "INPUT_NUMBER": {
      if (typeof value === "number") {
        const unit = config.defaultUnit;
        set(edits, propertyName, `${value}${unit ?? ""}`);
      } else if (typeof value !== "string") {
        discardedEdits.push({
          propertyName,
          reason: "Invalid value",
          propertyValue: value,
        });
        unset(edits, propertyName);
      } else {
        // make sure that the string includes the unit
        const possibleUnits = config.unitOptions ?? [];
        if (possibleUnits.length > 0) {
          const isValid = possibleUnits.some(
            (unit) => unit.value?.length === 0 || value.endsWith(unit.value),
          );
          if (isValid) {
            set(edits, propertyName, value);
          } else {
            set(edits, propertyName, `${value}${config.defaultUnit ?? ""}`);
          }
        }
      }
      break;
    }
    default:
      // special case for custom validation rule, which always needs to be dynamic
      if (config.propertyName === "customValidationRule") {
        if (typeof value === "string" && !value.trim().startsWith("{{")) {
          // wrap value in bindings if needed
          set(edits, propertyName, `{{ ${value} }}`);
        }
      }
      if (
        config.isJSConvertible &&
        edits.dynamicBindingPathList?.some(
          (prop: DynamicPath) => prop.key === propertyName,
        )
      ) {
        addDynamicProperty(propertyName);
      }
      break;
  }

  switch (config.customJSControl) {
    case "COMPUTE_TABLE_VALUE": {
      const value = get(edits, propertyName);
      if (
        typeof value === "string" &&
        containsBindingsAnywhere(value) &&
        !value.includes("tableData")
      ) {
        const computedValue = getOutputComputedValue(
          previousWidget?.widgetName ?? "",
          value,
        );
        set(edits, propertyName, computedValue);
        copyDerivedColumnChange(propertyName, computedValue);
      }
      break;
    }
  }

  // special case for when the property should actually be applied on a different widget (i.e. on the canvas child of container)
  if (config.getTargetWidgetId) {
    const targetWidgetId = config?.getTargetWidgetId(
      previousWidget as any as WidgetProps,
    );
    if (targetWidgetId) {
      const value = get(edits, propertyName);
      unset(edits, propertyName);
      set(
        dependentChangesByWidgetId,
        `${targetWidgetId}.${propertyName}`,
        value,
      );
    }
  }
};

export const isSubProperty = (propertyName: string, key: string) => {
  const propertyNamePieces: string[] = propertyName.split(".");
  const keyPieces: string[] = key.split(".");
  return (
    keyPieces.length > propertyNamePieces.length &&
    keyPieces.slice(0, propertyNamePieces.length).join(".") === propertyName
  );
};

const colorPickerContainsColor = (
  value: unknown,
): {
  isValid: boolean;
  isDynamic: boolean;
  value: string | undefined;
} => {
  let valToTest = value;
  if (typeof value === "object" && value !== null) {
    const colorValue = Object.values(value)[0];
    if (typeof colorValue === "string") {
      valToTest = colorValue;
    } else {
      return {
        isValid: false,
        value: undefined,
        isDynamic: false,
      };
    }
  }
  if (typeof valToTest !== "string") {
    return {
      isValid: false,
      value: undefined,
      isDynamic: false,
    };
  }
  const builtIns = [...THEME_COLOR_KEYS, ...NEUTRAL_COLOR_KEYS];
  for (let i = 0; i < builtIns.length; i++) {
    const colorKey = builtIns[i];
    if (valToTest === `{{ theme.colors.${colorKey} }}`) {
      return {
        isValid: true,
        value: valToTest,
        isDynamic: false,
      };
    }
  }
  if (/^#[0-9A-F]{6}[0-9a-f]{0,2}$/i.test(valToTest)) {
    return {
      isValid: true,
      value: valToTest,
      isDynamic: false,
    };
  }
  // try converting to a hex color
  const hexColor = tinycolor(valToTest);
  if (hexColor.isValid()) {
    return {
      isValid: true,
      value:
        hexColor.getAlpha() === 1
          ? hexColor.toHexString()
          : hexColor.toHex8String(),
      isDynamic: false,
    };
  }

  return {
    isValid: true,
    value: valToTest,
    isDynamic: true,
  };
};

// TODO: add all of the widget types and use this in the property pane config
const getDefaultVariantForWidgetType = (
  widgetType: WidgetTypes,
  pathName: string,
): keyof Typographies => {
  const path = pathName.split(".")[0];
  switch (widgetType) {
    case WidgetTypes.BUTTON_WIDGET:
      if (path === "textProps") {
        return DEFAULT_BUTTON_WIDGET_TEXT_STYLE_VARIANT;
      }
      break;
    case WidgetTypes.INPUT_WIDGET:
      if (path === "labelProps") {
        return DEFAULT_INPUT_WIDGET_LABEL_STYLE_VARIANT;
      } else if (path === "inputProps") {
        return DEFAULT_INPUT_WIDGET_INPUT_STYLE_VARIANT;
      }
      break;
    case WidgetTypes.TABLE_WIDGET:
      if (path === "headerProps") {
        return DEFAULT_HEADING_TEXT_STYLE_VARIANT;
      }
      if (path === "columnHeaderProps") {
        return TABLE_COLUMN_HEADER_DEFAULT_TYPOGRAPHY;
      }
      if (path === "cellProps") {
        return DEFAULT_CELL_TEXT_STYLE_VARIANT;
      }
      if (path === "searchProps") {
        return DEFAULT_INPUT_TEXT_STYLE_VARIANT;
      }
      break;
  }
  return "label";
};

const sanitizeTextStyle = ({
  previousWidget,
  theme,
  value,
  propertyName,
  setTopLevelProperty,
  discardedEdits,
  dynamicProperties,
  featureFlags,
  changedKeys,
  dependentChangesByWidgetId,
}: {
  previousWidget: Partial<WidgetProps>;
  theme: GeneratedTheme;
  value: Record<string, any>;
  propertyName: string;
  setTopLevelProperty: (path: string, value: any) => void;
  discardedEdits: DiscardedEdit[];
  dynamicProperties: Array<DynamicPath>;
  featureFlags: Partial<AllFlags>;
  changedKeys: string[];
  dependentChangesByWidgetId: Record<string, unknown>;
}) => {
  if (previousWidget.type === WidgetTypes.BUTTON_WIDGET && value.textColor) {
    const { value: color, isValid } = colorPickerContainsColor(value.textColor);
    if (isValid) {
      setTopLevelProperty("textColor", color);
    } else {
      discardedEdits.push({
        propertyName: `${propertyName}.textColor`,
        reason: "Invalid text color",
        propertyValue: value.textColor,
      });
    }
    // if this was the only key, we can bail early
    if (Object.keys(value).length === 1) {
      setTopLevelProperty(propertyName.split(".")[0], undefined);
      return;
    }
  }

  const prevVariant =
    get(previousWidget, `${propertyName}.variant`) ??
    (getDefaultVariantForWidgetType(
      previousWidget.type as WidgetTypes,
      propertyName,
    ) as string);

  const customStyleProperties = customStylesProperties({
    textStyleParentDottedPath: "",
    forceFullPropertyNamePath: false,
    isPopover: false,
  });

  Object.entries(value).forEach(([nestedPropertyName, nestedValue]) => {
    // if something other than variant or color is set, we need to set variant to custom
    if (
      nestedPropertyName !== "variant" &&
      nestedPropertyName !== "textColor"
    ) {
      value.variant = SB_CUSTOM_TEXT_STYLE;
    }
    // parse the color. for buttons, we need to hoist the color to the top level
    if (nestedPropertyName === "textColor") {
      const { value: color, isValid } = colorPickerContainsColor(nestedValue);
      if (isValid && previousWidget.type !== WidgetTypes.BUTTON_WIDGET) {
        set(value, `textColor.default`, color);
      }
      return;
    }

    // ensure that the variant is valid
    if (
      nestedPropertyName === "variant" &&
      nestedValue !== SB_CUSTOM_TEXT_STYLE
    ) {
      // check if its valid
      const validTypographies = [
        ...Object.keys(theme.typographies),
        ...Object.keys(theme.typographies.custom ?? {}),
      ];
      if (!validTypographies.includes(nestedValue as string)) {
        discardedEdits.push({
          propertyName: `${propertyName}.${nestedPropertyName}`,
          reason: "Invalid typogpraphy",
          propertyValue: nestedValue,
        });
        setTopLevelProperty(propertyName.split(".")[0], undefined);
        return;
      }
    }
    const propertyConfig = customStyleProperties.find(
      (property) => property.propertyName === nestedPropertyName,
    );
    if (propertyConfig) {
      sanitizeEdit({
        propertyName: nestedPropertyName,
        config: propertyConfig,
        edits: value,
        discardedEdits,
        dynamicProperties,
        previousWidget,
        theme,
        featureFlags,
        changedKeys,
        dependentChangesByWidgetId,
      });
    }
  });

  const newVariant = value.variant;

  // If we switched to a custom variant, fill in all of the text styles based on the theme
  if (
    newVariant === SB_CUSTOM_TEXT_STYLE &&
    prevVariant !== SB_CUSTOM_TEXT_STYLE
  ) {
    const themeValues = get(theme, `typographies.${prevVariant}`);
    Object.entries(themeValues).forEach(([themeKey, themeValue]) => {
      if (!value[themeKey]) {
        value[themeKey] = themeValue;
      }
    });
  }
};

export const sanitizeEdits = async ({
  edits,
  changedKeys,
  properties,
  discardedEdits,
  dynamicProperties,
  parentPath = "",
  previousWidget,
  theme,
  featureFlags,
  dependentChangesByWidgetId,
}: {
  edits: Record<string, any>;
  changedKeys: string[];
  properties: PropertyPaneConfig[];
  discardedEdits: DiscardedEdit[];
  dynamicProperties: Array<DynamicPath>;
  previousWidget: Partial<WidgetProps>;
  parentPath?: string;
  theme: GeneratedTheme;
  featureFlags: Partial<AllFlags>;
  dependentChangesByWidgetId: Record<string, unknown>;
}) => {
  for (const property of properties) {
    let propertyName = (property as PropertyPaneControlConfig).propertyName;
    if (typeof propertyName === "string") {
      if (parentPath) {
        propertyName = `${parentPath}.${propertyName}`;
      }
    }
    // case 1: this property was changed by the AI and we have a config for it
    if (
      typeof propertyName === "string" &&
      changedKeys.some((key) => key.startsWith(propertyName as string)) &&
      get(edits, propertyName) !== undefined
    ) {
      await sanitizeEdit({
        propertyName,
        config: property as PropertyPaneControlConfig,
        edits,
        discardedEdits,
        dynamicProperties,
        previousWidget,
        theme,
        featureFlags,
        changedKeys,
        dependentChangesByWidgetId,
      });
    }
    // case 2: this property was changed by the user and we don't have a config for it
    else if (
      typeof propertyName === "string" &&
      !propertyName.startsWith("primaryColumns")
    ) {
      const typedPropertyName = propertyName as string;
      // look for any properties that start with the propertyName
      // if they are found, combine them into a single property + sanitize it
      const subProperties = Object.keys(edits).filter((key) =>
        isSubProperty(typedPropertyName, key),
      );
      if (subProperties.length > 0) {
        const keysToRemove: string[] = [];
        const combinedProperty: Record<string, any> = {};
        for (const key of subProperties) {
          const value = get(edits, key);
          const keyWithoutPropertyName = key.slice(
            typedPropertyName.length + 1,
          );
          if (value == null) {
            unsetPropertyPath(combinedProperty, keyWithoutPropertyName);
          } else {
            set(combinedProperty, keyWithoutPropertyName, value);
          }
          keysToRemove.push(key);
        }
        if (keysToRemove.length > 0) {
          // remove the old propertie and pull the combined property to the top level
          keysToRemove.forEach((key) => unset(edits, key));
          set(edits, propertyName, combinedProperty);
          await sanitizeEdit({
            propertyName,
            config: property as PropertyPaneControlConfig,
            edits,
            discardedEdits,
            dynamicProperties,
            previousWidget,
            theme,
            featureFlags,
            changedKeys,
            dependentChangesByWidgetId,
          });
        }
      }
    }

    // case 3: this property has children, so we sanitize those
    if (property.children) {
      await sanitizeEdits({
        edits,
        changedKeys,
        properties: property.children,
        discardedEdits,
        dynamicProperties,
        parentPath: typeof propertyName === "string" ? propertyName : undefined,
        previousWidget,
        theme,
        featureFlags,
        dependentChangesByWidgetId,
      });
    }
    // case 4: sanitize primary columns of a table, which is a special case since we need to determine all column ids
    if (propertyName === "primaryColumns") {
      const columnConfigs = flatMap(
        (property as any).panelConfig?.children,
        (section) => (section as any)?.children ?? [],
      );
      const allColumnIds = new Set<string>();
      Object.keys((previousWidget as any)?.primaryColumns ?? {})?.forEach(
        (column) => {
          allColumnIds.add(column);
        },
      );
      Object.keys((edits as any)?.primaryColumns ?? {})?.forEach((column) => {
        allColumnIds.add(column);
      });

      for (const columnId of Array.from(allColumnIds)) {
        await sanitizeEdits({
          edits,
          changedKeys,
          properties: columnConfigs,
          discardedEdits,
          dynamicProperties,
          parentPath: `${propertyName}.${columnId}`,
          previousWidget,
          theme,
          featureFlags,
          dependentChangesByWidgetId,
        });
      }
    }
    // case 5: the property has a popover associated with it, so we sanitize the children of that popover
    else if ((property as PropertyPaneControlConfig).panelConfig != null) {
      if (
        (property as PropertyPaneControlConfig).controlType === "EVENT_TRIGGER"
      )
        continue;

      const { children: panelChildren, panelIdPropertyName } = (
        property as PropertyPaneControlConfig
      ).panelConfig!;
      const subPath =
        get(edits, `${propertyName}.${panelIdPropertyName}`) ??
        get(previousWidget, `${propertyName}.${panelIdPropertyName}`);
      const parentPath = `${propertyName}.${subPath ?? panelIdPropertyName}`;
      await sanitizeEdits({
        edits,
        changedKeys,
        properties: panelChildren,
        discardedEdits,
        dynamicProperties,
        parentPath,
        previousWidget,
        theme,
        featureFlags,
        dependentChangesByWidgetId,
      });
    }
  }
};
