import equal from "@superblocksteam/fast-deep-equal/es6";
import { ApplicationScope, getNextEntityName } from "@superblocksteam/shared";
import { set } from "lodash";
import { call, all, takeEvery } from "redux-saga/effects";
import { put, select } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";
import { stopEvaluation } from "legacy/actions/evaluationActions";
import { pageLoadSuccess } from "legacy/actions/pageActions";
import { deleteEntityFromWidgets } from "legacy/actions/widgetActions";
import {
  ReduxAction,
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import { PAGE_WIDGET_ID } from "legacy/constants/WidgetConstants";
import { getWidgets, getAllEntityNames } from "legacy/selectors/sagaSelectors";
import { deleteReferencesFromTimerTriggers } from "legacy/utils/TimerUtils";
import {
  deleteV1ApiSaga,
  selectV1ApiById,
  ApiV1,
} from "store/slices/apisShared";
import { deleteV2ApiSaga, selectV2ApiById } from "store/slices/apisV2";
import { getV2ApiName } from "store/slices/apisV2/utils/getApiIdAndName";
import {
  requestApplicationSave,
  fetchApplicationSuccess,
} from "store/slices/application/applicationActions";
import {
  AppTimer,
  DEFAULT_TIMER_INTERVAL,
  TIMER_MIN_INTERVAL,
  ActiveTimerStatus,
  AppTimerWithMetaType,
  AppTimerScoped,
} from "store/slices/application/timers/TimerConstants";
import {
  getAllTimers,
  getTimerByName,
  getTimerById,
  getScopedTimers,
  getTimerScope,
} from "store/slices/application/timers/selectors";
import { overwriteScopedTimers } from "store/slices/application/timers/slice";
import {
  deleteTimer,
  restartTimers,
} from "store/slices/application/timers/timerActions";
import { createTimer } from "store/slices/application/timers/timerActions";
import {
  startTimer,
  stopTimer,
  updateTimers,
  restartTimer,
  deleteEntityFromTimers,
  editTimerPropertyPane,
  duplicateTimer,
  toggleTimer,
  pauseAllTimers,
  restartAllPausedTimers,
  setCreatingTimer,
} from "store/slices/application/timers/timerActions";
import {
  getTimersWithMeta,
  getTimerByNameWithMeta,
  getTimersMeta,
} from "store/slices/application/timersMeta/selectors";
import {
  resetTimerMetaProperties,
  updateTimerMetaProperties,
} from "store/slices/application/timersMeta/slice";
import {
  selectFlagById,
  Flag,
  getFeatureFlagSaga,
} from "store/slices/featureFlags";
import { getScopedEntityPrefix } from "store/utils/scope";
import { fastClone } from "utils/clone";
import {
  createTimerInterval,
  stopTimerInterval,
  stopAllTimerIntervals,
} from "./TimerIntervals";

// Timers are paused when the tab loses focus, which is handled by a hook inside the
// editor and the deployed app
const pausedTimerIds: Set<AppTimer["id"]> = new Set();

function* createTimerSaga(
  action: ReturnType<typeof createTimer>,
): Generator<any, any, any> {
  try {
    yield put(setCreatingTimer(true));

    // Get all the widgets from the canvasWidgetsReducer
    const stateWidgets: ReturnType<typeof getWidgets> =
      yield select(getWidgets);

    // Parent widgetId of the timer is always the main container right now
    const mainContainerWidget = { ...stateWidgets[PAGE_WIDGET_ID] };

    if (!mainContainerWidget.timers)
      mainContainerWidget.timers = {
        timerMap: {},
      };

    // Use name from action payload if supplied, otherwise get the next entity name
    let newName = action.payload.name;
    if (!newName) {
      const entityNames = yield select(getAllEntityNames);
      newName = getNextEntityName(
        getScopedEntityPrefix(action.payload.scope, "Timer"),
        [...entityNames],
      );
    }

    const newTimer = {
      id: action.payload.id ?? uuidv4(),
      name: newName,
      startOnPageLoad: true,
      intervalMs: DEFAULT_TIMER_INTERVAL,
      steps: [],
      dynamicTriggerPathList: [{ key: "steps" }],
      createdAt: new Date().getTime(),
      scope: action.payload.scope,
    } satisfies AppTimerScoped;

    const scopedTimers = yield select(getScopedTimers, action.payload.scope);
    const newTimers = {
      ...fastClone(scopedTimers),
      [newTimer.id]: newTimer,
    };

    yield put(
      overwriteScopedTimers({ scope: action.payload.scope, timers: newTimers }),
    );

    if (action.payload.scope === ApplicationScope.APP) {
      yield put(requestApplicationSave());
    }

    // At the new timer id to the timer meta props
    yield put(
      resetTimerMetaProperties({
        scope: action.payload.scope,
        id: newTimer.id,
      }),
    );

    // Start the timer
    yield put(startTimer(action.payload.scope, newName));

    if (action.payload.openInPropertyPane) {
      yield put(editTimerPropertyPane(newTimer.id, action.payload.scope));
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.TIMER_OPERATION_ERROR,
      payload: {
        action: action.type,
        error,
      },
    });
  } finally {
    yield put(setCreatingTimer(false));
  }
}

function* duplicateTimerSaga(
  action: ReturnType<typeof duplicateTimer>,
): Generator<any, any, any> {
  try {
    const existingTimer: AppTimer = yield select(
      getTimerById,
      action.payload.timerId,
    );
    if (!existingTimer) {
      throw new Error("Timer not found.");
    }
    const existingTimers = yield select(
      getScopedTimers,
      action.payload.toScope,
    );
    const entityNames = yield select(getAllEntityNames);
    const newName = getNextEntityName(`${existingTimer.name}_copy`, [
      ...entityNames,
    ]);
    const newTimer = {
      ...existingTimer,
      id: uuidv4(),
      name: newName,
      createdAt: new Date().getTime(),
    };
    const newTimerMap = {
      ...(existingTimers ?? {}),
      [newTimer.id]: newTimer,
    };

    yield put(
      overwriteScopedTimers({
        scope: action.payload.toScope,
        timers: newTimerMap,
      }),
    );

    if (action.payload.toScope === ApplicationScope.APP) {
      yield put(requestApplicationSave());
    }

    yield put(
      resetTimerMetaProperties({
        scope: action.payload.toScope,
        id: newTimer.id,
      }),
    );
    yield put(startTimer(action.payload.toScope, newName));
    yield put(editTimerPropertyPane(newTimer.id, action.payload.toScope));
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.TIMER_OPERATION_ERROR,
      payload: {
        action: action.type,
        error,
      },
    });
  }
}

function* deleteTimerSaga(
  action: ReturnType<typeof deleteTimer>,
): Generator<any, any, any> {
  try {
    const { id, scope } = action.payload;

    const timers = yield select(getScopedTimers, action.payload.scope);

    if (!timers) return;

    const newTimerMap = fastClone(timers);

    const timer = newTimerMap[id];
    const timerName = timer.name;

    // Stop any existing interval if exists
    stopTimerInterval(timer.id);

    delete newTimerMap[id];

    yield put(
      overwriteScopedTimers({
        scope,
        timers: newTimerMap,
      }),
    );

    if (action.payload.scope === ApplicationScope.APP) {
      yield put(requestApplicationSave());
    }

    yield put(deleteEntityFromWidgets(timerName));
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.TIMER_OPERATION_ERROR,
      payload: {
        action: action.type,
        error,
      },
    });
  }
}

function* updateTimersSaga(
  action: ReturnType<typeof updateTimers>,
): Generator<any, any, any> {
  try {
    const timerUpdates = action.payload;

    // TODO: Need to set scope on the action, since this is called by the property panel (usually)
    // where we currently do not have scope
    const allPageTimers: ReturnType<typeof getScopedTimers> = yield select(
      getScopedTimers,
      ApplicationScope.PAGE,
    );
    const allAppTimers: ReturnType<typeof getScopedTimers> = yield select(
      getScopedTimers,
      ApplicationScope.APP,
    );

    const newPageTimerMap = fastClone(allPageTimers);
    const newAppTimersMap = fastClone(allAppTimers);

    Object.entries(timerUpdates).forEach(
      ([updatedTimerId, { updates, scope }]) => {
        const timerMap =
          scope === ApplicationScope.PAGE ? newPageTimerMap : newAppTimersMap;

        Object.entries(updates).forEach(([propertyPath, propertyValue]) => {
          // since property paths could be nested, we use lodash set method
          set(timerMap[updatedTimerId], propertyPath, propertyValue);
        });
      },
    );

    const timersToRestart: AppTimer["id"][] = [];
    const existingTimers: ReturnType<typeof getAllTimers> =
      yield select(getAllTimers);
    const timersMeta: ReturnType<typeof getTimersMeta> =
      yield select(getTimersMeta);

    const didPageTimersChange = !equal(allPageTimers, newPageTimerMap);
    const didAppTimersChange = !equal(allAppTimers, newAppTimersMap);

    yield all([
      didPageTimersChange
        ? put(
            overwriteScopedTimers({
              scope: ApplicationScope.PAGE,
              timers: newPageTimerMap,
            }),
          )
        : undefined,
      didAppTimersChange
        ? put(
            overwriteScopedTimers({
              scope: ApplicationScope.APP,
              timers: newAppTimersMap,
            }),
          )
        : null,
    ]);

    if (didAppTimersChange) {
      yield put(requestApplicationSave());
    }

    // Only restart is the timer was already active and the interval or steps have changed
    const newTimers = { ...newPageTimerMap, ...newAppTimersMap };
    for (const timerId of Object.keys(timerUpdates)) {
      const timerScope = timerUpdates[timerId].scope;

      const existingTimer = existingTimers[timerId];
      const newTimer = newTimers[timerId];
      const timerMeta = timersMeta[timerScope][timerId];

      if (
        timerMeta?.isActive &&
        (newTimer.intervalMs !== existingTimer.intervalMs ||
          !equal(newTimer.steps, existingTimer.steps))
      ) {
        timersToRestart.push(timerId);
      }
    }

    yield all(timersToRestart.map((id) => put(restartTimer(id))));
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.TIMER_OPERATION_ERROR,
      payload: {
        action: action.type,
        error,
      },
    });
  }
}

function* startTimerSaga(
  action: ReturnType<typeof startTimer>,
): Generator<any, any, any> {
  try {
    const timer = yield select(
      getTimerByName,
      action.payload.name,
      action.payload.scope,
    );
    // If the timer is active, don't try to start it again
    if (!timer) {
      return;
    }

    const timersMinInterval = yield select(
      selectFlagById,
      Flag.TIMERS_MIN_INTERVAL,
    );
    const nextInvocation = createTimerInterval({
      id: timer.id,
      intervalMs: timer.intervalMs,
      minIntervalMs: timersMinInterval ?? TIMER_MIN_INTERVAL,
      steps: fastClone(timer.steps),
      name: timer.name,
      scope: action.payload.scope,
    });

    yield put(
      updateTimerMetaProperties({
        id: timer.id,
        updates: {
          isActive: true,
          state: { status: ActiveTimerStatus.IDLE, nextInvocation },
        },
        scope: action.payload.scope,
      }),
    );
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.TIMER_OPERATION_ERROR,
      payload: {
        action: action.type,
        error,
      },
    });
  }
}

function* stopTimerSaga(
  action: ReturnType<typeof stopTimer>,
): Generator<any, any, any> {
  try {
    const { name, isPause, scope } = action.payload;
    const timer = yield select(getTimerByName, name, scope);
    if (!timer) {
      return;
    }

    // Stop any existing interval
    stopTimerInterval(timer.id);
    if (isPause) pausedTimerIds.add(timer.id);

    yield put(
      updateTimerMetaProperties({
        id: timer.id,
        updates: {
          isActive: false,
        },
        scope,
      }),
    );
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.TIMER_OPERATION_ERROR,
      payload: {
        action: action.type,
        error,
      },
    });
  }
}

function* restartTimerSaga(
  action: ReturnType<typeof restartTimer>,
): Generator<any, any, any> {
  try {
    const timer: ReturnType<typeof getTimerById> = yield select(
      getTimerById,
      action.payload.id,
    );
    if (!timer) return;

    yield put(stopTimer(timer.scope, timer.name));
    yield put(startTimer(timer.scope, timer.name));
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.TIMER_OPERATION_ERROR,
      payload: {
        action: action.type,
        error,
      },
    });
  }
}

function* restartTimersSaga(
  action: ReturnType<typeof restartTimers>,
): Generator<any, any, any> {
  yield all(
    action.payload.timerIds.map((id) =>
      call(restartTimerSaga, restartTimer(id)),
    ),
  );
}

function* toggleTimerSaga(
  action: ReturnType<typeof toggleTimer>,
): Generator<any, any, any> {
  try {
    const timer: ReturnType<typeof getTimerByNameWithMeta> = yield select(
      getTimerByNameWithMeta,
      action.payload.name,
      action.payload.scope,
    );
    if (!timer) {
      return;
    }

    if (timer.isActive) {
      yield put(stopTimer(action.payload.scope, timer.name, undefined));
    } else {
      yield put(startTimer(action.payload.scope, timer.name));
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.TIMER_OPERATION_ERROR,
      payload: {
        action: action.type,
        error,
        spanId: undefined,
      },
    });
  }
}

function* pauseAllTimersSaga(
  action: ReturnType<typeof pauseAllTimers>,
): Generator<any, any, any> {
  try {
    const timers: Record<AppTimer["id"], AppTimerWithMetaType> =
      yield select(getTimersWithMeta);
    const timersList = Object.values(timers);

    for (let i = 0; i < timersList.length; i++) {
      if (timersList[i].isActive) {
        const scope = yield select(getTimerScope, timersList[i].id);
        yield put(stopTimer(scope, timersList[i].name, true));
      }
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.TIMER_OPERATION_ERROR,
      payload: {
        action: action.type,
        error,
      },
    });
  }
}

function* restartAllPausedTimersSaga(
  action: ReturnType<typeof restartAllPausedTimers>,
): Generator<any, any, any> {
  try {
    const timers: Record<AppTimer["id"], AppTimerWithMetaType> =
      yield select(getTimersWithMeta);

    const pausedTimerIdsArray = [...pausedTimerIds];

    for (let i = 0; i < pausedTimerIdsArray.length; i++) {
      const timer = timers[pausedTimerIdsArray[i]];
      const scope = yield select(getTimerScope, timer.id);
      yield put(startTimer(scope, timer.name));
      pausedTimerIds.delete(timer.id);
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.TIMER_OPERATION_ERROR,
      payload: {
        action: action.type,
        error,
      },
    });
  }
}

// Start any timers that should start on page load
function* initializePageTimersSaga(
  action: ReturnType<typeof pageLoadSuccess>,
): Generator<any, any, any> {
  try {
    const timers: Record<AppTimer["id"], AppTimer> | undefined = yield select(
      getScopedTimers,
      ApplicationScope.PAGE,
    );
    if (!timers) return;

    for (const timer of Object.values(timers)) {
      if (timer.startOnPageLoad) {
        yield put(startTimer(ApplicationScope.PAGE, timer.name));
      } else {
        yield put(stopTimer(ApplicationScope.PAGE, timer.name));
      }
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.TIMER_OPERATION_ERROR,
      payload: {
        action: action.type,
        error,
      },
    });
  }
}

function* initializeAppTimersSaga(
  action: ReturnType<typeof pageLoadSuccess>,
): Generator<any, any, any> {
  try {
    const timers: Record<AppTimer["id"], AppTimer> | undefined = yield select(
      getScopedTimers,
      ApplicationScope.APP,
    );
    if (!timers) return;

    for (const timer of Object.values(timers)) {
      if (timer.startOnPageLoad) {
        yield put(startTimer(ApplicationScope.APP, timer.name));
      } else {
        yield put(stopTimer(ApplicationScope.APP, timer.name));
      }
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.TIMER_OPERATION_ERROR,
      payload: {
        action: action.type,
        error,
      },
    });
  }
}

// Stop all timers - but don't bother setting their meta values
// This is for when a user navigates away from the APP and
// all widgets are being cleared and so wob't be available
function* stopAllTimersSaga(): Generator<any, any, any> {
  try {
    stopAllTimerIntervals();
    pausedTimerIds.clear();
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.TIMER_OPERATION_ERROR,
      payload: {
        action: ReduxActionTypes.RESET_WIDGETS,
        error,
      },
    });
  }
}

function* stopPageTimersSaga(): Generator<any, any, any> {
  try {
    const pageScopedTimers: ReturnType<typeof getScopedTimers> = yield select(
      getScopedTimers,
      ApplicationScope.PAGE,
    );
    Object.keys(pageScopedTimers).forEach((id) => {
      stopTimerInterval(id);
      pausedTimerIds.delete(id);
    });
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.TIMER_OPERATION_ERROR,
      payload: {
        action: ReduxActionTypes.RESET_WIDGETS,
        error,
      },
    });
  }
}

function* deleteEntityFromTimersSaga(
  action: ReturnType<typeof deleteEntityFromTimers>,
): Generator<any, any, any> {
  try {
    const { entityName, isWidget } = action.payload;

    const timers: Record<AppTimer["id"], AppTimer> = fastClone(
      yield select(getAllTimers),
    );

    const newTimers = fastClone(timers);

    const updates = deleteReferencesFromTimerTriggers(
      newTimers,
      entityName,
      isWidget,
    );
    if (updates) {
      updates.forEach((update) => {
        set(newTimers[update.id], update.propertyName, update.propertyValue);
      });
    }

    // TODO(@omar): is this the only way to update timers when deleting something else? probably not, i should rework this.
    const timerUpdates: ReturnType<typeof updateTimers>["payload"] = {};
    for (const [id, timer] of Object.entries(newTimers)) {
      const scope = yield select(getTimerScope, id);
      timerUpdates[id] = {
        updates: timer,
        scope,
      };
    }

    yield put(updateTimers(timerUpdates));
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.TIMER_OPERATION_ERROR,
      payload: {
        action: action.type,
        error,
      },
    });
  }
}

function* deleteV1ApiFromTimersSaga(
  deleteAction: ReduxAction<{
    id: string;
  }>,
): Generator<any, any, any> {
  try {
    const { id } = deleteAction.payload;

    const api: ApiV1 | undefined = yield select(selectV1ApiById, id);
    if (!api) return;

    yield put(deleteEntityFromTimers(api?.actions?.name ?? ""));
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.TIMER_OPERATION_ERROR,
      payload: {
        action: deleteAction.type,
        error,
      },
    });
  }
}

function* deleteV2ApiFromTimersSaga(
  deleteAction: ReduxAction<{
    id: string;
  }>,
): Generator<any, any, any> {
  try {
    const { id } = deleteAction.payload;

    const api: ReturnType<typeof selectV2ApiById> = yield select(
      selectV2ApiById,
      id,
    );
    if (!api) return;

    yield put(deleteEntityFromTimers(getV2ApiName(api)));
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.TIMER_OPERATION_ERROR,
      payload: {
        action: deleteAction.type,
        error,
      },
    });
  }
}

export default function* timerSagas() {
  yield all([
    takeEvery(createTimer.type, createTimerSaga),
    takeEvery(deleteTimer.type, deleteTimerSaga),
    takeEvery(updateTimers.type, updateTimersSaga),
    takeEvery(duplicateTimer.type, duplicateTimerSaga),
    takeEvery(startTimer.type, startTimerSaga),
    takeEvery(stopTimer.type, stopTimerSaga),
    takeEvery(deleteV1ApiSaga.start.type, deleteV1ApiFromTimersSaga),
    takeEvery(deleteV2ApiSaga.start.type, deleteV2ApiFromTimersSaga),
    takeEvery(deleteEntityFromTimers.type, deleteEntityFromTimersSaga),
    takeEvery(toggleTimer.type, toggleTimerSaga),
    takeEvery(restartTimer.type, restartTimerSaga),
    takeEvery(restartTimers.type, restartTimersSaga),
    takeEvery(
      [pageLoadSuccess.type, getFeatureFlagSaga.success.type],
      initializePageTimersSaga,
    ),
    takeEvery(fetchApplicationSuccess.type, initializeAppTimersSaga),
    takeEvery(pauseAllTimers.type, pauseAllTimersSaga),
    takeEvery(restartAllPausedTimers.type, restartAllPausedTimersSaga),
    takeEvery(ReduxActionTypes.RESET_WIDGETS, stopPageTimersSaga),
    takeEvery(stopEvaluation.type, stopAllTimersSaga),
  ]);
}
