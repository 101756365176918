import { User } from "@auth0/auth0-react";
import {
  ApplicationUserStatus,
  AssignmentDto,
  PrincipalTypeEnum,
  RoleDto,
} from "@superblocksteam/shared";
import { Tooltip } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { ReactComponent as TrashIcon } from "assets/icons/common/trash.svg";
import { ReactComponent as GroupIcon } from "assets/icons/common/users.svg";
import IconButton from "components/ui/IconButtons/IconButton";
import {
  DropdownOption,
  RecommendedSingleDropdown,
} from "components/ui/RecommendedSingleDropdown";
import { usePopconfirm } from "hooks/ui/usePopconfirm";
import { SHARE_MODAL_ROLE_SELECT_WIDTH } from "pages/Permissions/constants";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import ColoredAvatar from "./ColoredAvatar";

const RoleSelectWrapper = styleAsClass`
  display: flex;
  align-items: center;
  input {
    border: 1px solid transparent !important;
    font-weight: 500;
  }
  input:focus, input:hover{
    border: 1px solid ${colors.ACCENT_BLUE_500} !important;
  }
  .bp5-input-group::before {
    svg {
      fill: ${colors.GREY_300};
    }
  }
`;
export const ItemRow = styleAsClass`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 12px;
  height: 48px;
  align-items: center;
  &:hover, &[data-active=true] {
    background-color: ${colors.GREY_25};
  }
  padding: 4px;
  border-radius: 4px;
`;

const StyledEditorList = styleAsClass`
  margin-top: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .ant-list-item {
    padding: 6px 0px 6px 0px;
  }
  .ant-list-item-action > li {
    padding-right: 0px;
  }
  .ant-list-item-action-split {
    width: 0px;
  }
`;

export const StyledNameDescription = styleAsClass`
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
  flex: 1 0;
  div {
    font-size: 12px;
    line-height: 16px;
  }
  & > div:first-child {
    font-weight: 500;
    color: ${colors.GREY_900};
  }
  & > div:nth-child(2) {
    font-weight: 400;
    color: ${colors.GREY_500};
  }
  height: 34px;
`;

const StyledStatus = styleAsClass`
  font-size: 10px;
  border: 1px solid;
  border-radius: 4px;
  padding: 0 6px;
  color: #666666;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 12px;
`;

const ElipsisText = styleAsClass`
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
`;

interface Props {
  currentUser: User | undefined;
  assignments: AssignmentDto[];
  roles?: RoleDto[];
  updateRole?: (assignment: AssignmentDto, roleName: string) => void;
  onRemoveEntry?: (assignment: AssignmentDto) => void;
  hideAction?: boolean;
  parentRef?: React.RefObject<HTMLDivElement>;
  readOnlyMode?: boolean;
}

const PermissionEntryItem = ({
  assignment,
  currentUser,
  roles,
  parentRef,
  onRemoveEntry,
  updateRole,
  readOnlyMode,
}: {
  currentUser: User | undefined;
  assignment: AssignmentDto;
  roles?: RoleDto[];
  parentRef?: React.RefObject<HTMLDivElement>;
  onRemoveEntry?: (assignment: AssignmentDto) => void;
  updateRole?: (assignment: AssignmentDto, roleName: string) => void;
  readOnlyMode?: boolean;
}) => {
  const principalName =
    assignment.principalType === PrincipalTypeEnum.USER
      ? assignment.user?.name
      : assignment.group?.name;

  const roleOptions = useMemo(
    () =>
      roles
        ? [
            ...roles.map((role, index) => ({
              key: role.name,
              value: role.name,
              displayName: role.name,
              dataTest: `assignment-${principalName}-role-option-${role.name}`,
              hasDivider: index === roles!.length - 1,
            })),
            {
              key: "remove-access",
              value: "remove",
              displayName: "Remove",
              groupId: "remove",
              dataTest: `assignment-${principalName}-role-option-remove`,
              textColor: colors.DANGER,
            },
          ]
        : [],
    [principalName, roles],
  );

  const [dropdownPopConfirmOpen, setDropdownPopConfirmOpen] = useState(false);

  const onRoleChange = useCallback(
    (option: DropdownOption) => {
      if (option.value === "remove") {
        setDropdownPopConfirmOpen(true);
        return;
      }
      updateRole?.(assignment, option.value);
    },
    [assignment, updateRole],
  );

  const { popover: popConfirmContent, confirmOpen: isPopConfirmOpen } =
    usePopconfirm({
      onConfirm: () => onRemoveEntry?.(assignment),
      title: `Remove Access`,
      confirmType: "danger",
      icon: <></>,
      children: (
        <IconButton
          icon={<TrashIcon />}
          data-test={`share-entry-${principalName}-remove`}
        />
      ),
    });

  const onDropdownInputFocus = useCallback(() => {
    setDropdownPopConfirmOpen(false);
  }, []);

  const { popover: popConfirmFromDropdown } = usePopconfirm({
    onConfirm: () => onRemoveEntry?.(assignment),
    title: `Remove Access`,
    confirmType: "danger",
    icon: <></>,
    children: (
      <div className={RoleSelectWrapper} style={{ opacity: 1 }}>
        <RecommendedSingleDropdown
          value={assignment.role?.name ?? "Developer"}
          onChange={onRoleChange}
          options={roleOptions}
          parentRef={parentRef}
          width={SHARE_MODAL_ROLE_SELECT_WIDTH}
          disableSearch={true}
          onInputFocus={onDropdownInputFocus}
          data-test={`assignment-${principalName}-role-select`}
          disabled={readOnlyMode}
        />
      </div>
    ),
    confirmOpen: dropdownPopConfirmOpen,
    setConfirmOpen: setDropdownPopConfirmOpen,
  });

  return (
    <div
      key={assignment.id}
      className={ItemRow}
      data-active={isPopConfirmOpen || dropdownPopConfirmOpen}
    >
      <div
        className={ItemRow}
        style={{
          padding: 0,
          opacity:
            assignment.user?.status ===
            ApplicationUserStatus.EDITOR_PENDING_JOIN
              ? "0.4"
              : "1",
          flex: 1,
        }}
      >
        {assignment.principalType === PrincipalTypeEnum.USER ? (
          <ColoredAvatar name={principalName}>
            {principalName?.charAt(0).toUpperCase()}
          </ColoredAvatar>
        ) : (
          <ColoredAvatar>
            <GroupIcon width="16" />
          </ColoredAvatar>
        )}
        <div className={StyledNameDescription}>
          <div style={{ display: "flex", maxWidth: 320, flexWrap: "nowrap" }}>
            <div className={ElipsisText} style={{}}>
              {`${principalName} ${
                currentUser?.email === assignment.user?.email ? "(You)" : ""
              }`}
            </div>
            {assignment.user?.status ===
              ApplicationUserStatus.EDITOR_PENDING_JOIN && (
              <div style={{ display: "flex" }}>
                <Tooltip
                  title={`User has not joined your organization.`}
                  overlayStyle={{ minWidth: 260 }}
                >
                  <div className={StyledStatus}>Pending</div>
                </Tooltip>
              </div>
            )}
          </div>
          <div className={ElipsisText} style={{ maxWidth: 320 }}>
            {assignment.principalType === PrincipalTypeEnum.GROUP
              ? `${assignment.group?.size} members`
              : assignment.user?.email}
          </div>
        </div>
      </div>
      {roleOptions.length < 1 ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          {popConfirmContent}
        </div>
      ) : (
        popConfirmFromDropdown
      )}
    </div>
  );
};

const PermissionEntriesList = (props: Props) => {
  return (
    <div className={StyledEditorList}>
      {props.assignments.map((assignment) => (
        <PermissionEntryItem
          key={assignment.id}
          assignment={assignment}
          currentUser={props.currentUser}
          roles={props.roles}
          parentRef={props.parentRef}
          onRemoveEntry={props.onRemoveEntry}
          updateRole={props.updateRole}
          readOnlyMode={props.readOnlyMode}
        />
      ))}
    </div>
  );
};

export default PermissionEntriesList;
