import { Dimension } from "@superblocksteam/shared";
import { isNull } from "lodash";
import { XYCoord } from "react-dnd";
import {
  GridDefaults,
  WidgetWidthModes,
  WidgetHeightModes,
} from "legacy/constants/WidgetConstants";
import { OccupiedSpace } from "legacy/constants/editorConstants";
import { type WidgetConfigProps } from "legacy/mockResponses/WidgetConfigResponse";
import {
  StackDragPositions,
  getOverridePos,
} from "legacy/widgets/StackLayout/utils";
import getAvailableRectInDropZone, {
  CantFit,
  getNearAvailableRectInDropZone,
} from "./getAvailableRectInDropZone";
import type { WidgetPropsRuntime } from "../../BaseWidget";
import type { WidgetProps } from "legacy/widgets";

export type Rect = {
  top: number;
  left: number;
  right: number;
  bottom: number;
};

export type UIElementSize = { height: number; width: number };

type XYNudgeAmount = { colShift: number; rowShift: number };

interface SizeProps {
  left: number;
  top: number;
  width: number;
  height: number;
  parentColumnSpace: number;
  parentRowSpace: number;
}

export const computeRowCols = (
  delta: UIElementSize,
  position: XYCoord,
  props: SizeProps & { roundHeight?: boolean },
): SizeProps => {
  // don't round height if meta key is pressed OR height has not changed
  const heightRounding =
    props.roundHeight && delta.height ? Math.round : (a: number) => a;
  return {
    left: Math.round(props.left + position.x / props.parentColumnSpace),
    top: Math.round(props.top + position.y / props.parentRowSpace),
    width: Math.round(props.width + delta.width / props.parentColumnSpace),
    height: heightRounding(props.height + delta.height / props.parentRowSpace),
    parentColumnSpace: props.parentColumnSpace,
    parentRowSpace: props.parentRowSpace,
  };
};

const computeBoundedRowCols = (rowCols: SizeProps): SizeProps => {
  const left = Math.max(rowCols.left, 0);
  const right = Math.min(
    rowCols.left + rowCols.width,
    GridDefaults.DEFAULT_GRID_COLUMNS,
  );
  return {
    left: left,
    top: rowCols.top,
    width: right - rowCols.left,
    height: rowCols.height,
    parentColumnSpace: rowCols.parentColumnSpace,
    parentRowSpace: rowCols.parentRowSpace,
  };
};

export const computeFinalRowCols = (
  delta: UIElementSize,
  position: XYCoord,
  props: SizeProps & { roundHeight?: boolean },
): SizeProps | false => {
  const newRowCols = computeBoundedRowCols(
    computeRowCols(delta, position, props),
  );
  return hasRowColsChanged(newRowCols, props) ? newRowCols : false;
};

const hasRowColsChanged = (newRowCols: SizeProps, props: SizeProps) => {
  return (
    props.left !== newRowCols.left ||
    props.top !== newRowCols.top ||
    props.width !== newRowCols.width ||
    props.height !== newRowCols.height
  );
};

export const snapToGrid = (
  columnWidth: number,
  rowHeight: number,
  x: number,
  y: number,
) => {
  const snappedX = Math.round(x / columnWidth);
  const snappedY = Math.round(y / rowHeight);
  return [snappedX, snappedY];
};

export const getDropZoneOffsets = (
  colWidth: number,
  rowHeight: number,
  dragOffset: XYCoord,
  parentOffset: XYCoord,
) => {
  // Calculate actual drop position by snapping based on x, y and grid cell size
  return snapToGrid(
    colWidth,
    rowHeight,
    dragOffset.x - parentOffset.x,
    dragOffset.y - parentOffset.y,
  );
};

interface DiffFromProps {
  left?: Dimension<"gridUnit" | "px">;
  top?: Dimension<"gridUnit" | "px">;
  width: Dimension<WidgetWidthModes>;
  height: Dimension<WidgetHeightModes>;
  widgetId?: string;
}

export const diffFromDraggedWidget = (
  draggedWidget: DiffFromProps,
  otherWidget: DiffFromProps,
  parentColumnWidth: number,
  parentRowHeight: number,
): XYCoord => {
  const draggedTopLeftPt = {
    x: Dimension.toPx(draggedWidget.left ?? Dimension.px(0), parentColumnWidth),
    y: Dimension.toPx(draggedWidget.top ?? Dimension.px(0), parentRowHeight),
  };
  const selectedTopLeftPt = {
    x: Dimension.toPx(otherWidget.left ?? Dimension.px(0), parentColumnWidth),
    y: Dimension.toPx(otherWidget.top ?? Dimension.px(0), parentRowHeight),
  };
  return {
    x: Dimension.minus(draggedTopLeftPt.x, selectedTopLeftPt.x).value,
    y: Dimension.minus(draggedTopLeftPt.y, selectedTopLeftPt.y).value,
  };
};

export const isPositionFree = (obj: Parameters<typeof findFreePosition>[0]) => {
  return Boolean(findFreePosition(obj));
};

export function findFreePosition({
  clientOffset,
  colWidth,
  rowHeight,
  widgets,
  dropTargetOffset,
  occupiedSpaces,
  parentRows,
  parentCols,
  cannotMoveToOriginalPosition,
  disableResize,
  mouseOffset,
  stackDragPositions,
}: {
  clientOffset: XYCoord | XYNudgeAmount; // The change in position from the last event
  colWidth: number;
  rowHeight: number;
  widgets: (
    | WidgetConfigProps
    | WidgetProps
    | (WidgetPropsRuntime & Partial<WidgetConfigProps>)
  )[];
  dropTargetOffset: XYCoord;
  occupiedSpaces?: OccupiedSpace[];
  parentRows?: number;
  parentCols?: number;
  cannotMoveToOriginalPosition?: boolean; //if set to true, we cannot move/paste into original position
  disableResize?: boolean; // if set to true, we cannot resize
  mouseOffset?: XYCoord; // used to calculate the offset of the mouse from the top left corner of the widget
  stackDragPositions?: StackDragPositions;
}): Rect | true | false {
  // Sometimes the draggedWidget can be undefined or null when the drag
  // of a new widget card has just started and the pointer is not yet
  // over top of the canvas. It's fine to return false in that case
  if (
    !widgets ||
    widgets.length === 0 ||
    widgets.some(isNull) ||
    !clientOffset ||
    !dropTargetOffset
  ) {
    return false;
  }

  const widgetIds = new Set(
    widgets
      .filter((w) => w && "widgetId" in w && w?.widgetId)
      .map((w) => (w as WidgetProps).widgetId),
  );

  // Filter out all spaces occupied by widgets that are currently
  // being moved, as their spaces are allowed to be occupied
  const filteredOccupiedSpaces = cannotMoveToOriginalPosition
    ? occupiedSpaces
    : occupiedSpaces?.filter(
        (widgetDetails) => !widgetIds.has(widgetDetails.id),
      );

  // Check if any of the widgets collide with an occupied space
  for (let i = 0; i < widgets.length; i++) {
    const widget = widgets[i];

    let left: number, top: number;

    const widgetOneSize = {
      left: "left" in widgets[0] ? widgets[0].left : undefined,
      top: "top" in widgets[0] ? widgets[0].top : undefined,
      width: widgets[0].width,
      height: widgets[0].height,
      widgetId: "widgetId" in widgets[0] ? widgets[0].widgetId : undefined,
    } satisfies DiffFromProps;
    const otherWidgetSize = {
      left: "left" in widget ? widget.left : undefined,
      top: "top" in widget ? widget.top : undefined,
      width: widget.width,
      height: widget.height,
      widgetId: "widgetId" in widget ? widget.widgetId : undefined,
    } satisfies DiffFromProps;
    const overridenWidgetOne = {
      ...widgetOneSize,
      ...getOverridePos(widgetOneSize, stackDragPositions),
    };
    const overridenOtherWidget = {
      ...otherWidgetSize,
      ...getOverridePos(otherWidgetSize, stackDragPositions),
    };

    if ((clientOffset as XYCoord).x && (clientOffset as XYCoord).y) {
      const diffFromDragged = diffFromDraggedWidget(
        overridenWidgetOne,
        overridenOtherWidget,
        colWidth,
        rowHeight,
      );
      const adjustedClientOffset = {
        x: (clientOffset as XYCoord).x - diffFromDragged.x,
        y: (clientOffset as XYCoord).y - diffFromDragged.y,
      };
      const offsets = getDropZoneOffsets(
        colWidth,
        rowHeight,
        adjustedClientOffset,
        dropTargetOffset,
      );
      left = offsets[0];
      top = offsets[1];
    } else if ("left" in widget && "top" in widget) {
      // when nudging widgets using keyboard, no need to use x/y pixel values
      // we can directly use widget's leftCol/topRow to check for collisions
      // TODO(Layout) - Use mode
      left =
        (widget?.left?.value || 0) + (clientOffset as XYNudgeAmount).colShift;
      top =
        (widget?.top?.value || 0) + (clientOffset as XYNudgeAmount).rowShift;
    } else {
      // No client offset, so we can't calculate the drop zone
      // So there are no free positions

      return false;
    }

    const otherWidgetWidth = Dimension.toGridUnit(
      overridenOtherWidget.width,
      colWidth,
    ).roundUp().value;

    const otherWidgetHeight = Dimension.toGridUnit(
      overridenOtherWidget.height,
      rowHeight,
    ).roundUp().value;

    const desiredRect = {
      left,
      right: left + otherWidgetWidth,
      top,
      bottom: top + otherWidgetHeight,
    };

    const parentRect = {
      left: 0,
      top: 0,
      right: parentCols || GridDefaults.DEFAULT_GRID_COLUMNS,
      bottom: parentRows || 0,
    };

    const mouseXY = mouseOffset
      ? getDropZoneOffsets(colWidth, rowHeight, mouseOffset, dropTargetOffset)
      : [
          desiredRect.left + desiredRect.right / 2,
          desiredRect.top + desiredRect.bottom / 2,
        ];

    const validSpace = getAvailableRectInDropZone(
      parentRect,
      desiredRect,
      (widget as any).widgetId,
      (widget as any).type,
      filteredOccupiedSpaces,
      cannotMoveToOriginalPosition,
      widgets.length === 1 && disableResize !== true, // Only allow shrinking if there is only one widget
      { x: mouseXY[0], y: mouseXY[1] },
    );

    if (validSpace === CantFit) {
      return false;
    }
    if (widgets.length === 1) {
      return validSpace;
    }
  }

  return true;
}

// A version of isPositionFree that works only for pasting (not dragging)
export function findExactFreePosition({
  clientOffset,
  colWidth,
  rowHeight,
  paddingX,
  widget,
  dropTargetOffset,
  occupiedSpaces,
  parentRows,
  parentCols,
  parentTop,
  parentLeft,
  mouseOffset,
}: {
  clientOffset: XYCoord | XYNudgeAmount; // The change in position from the last event
  colWidth: number;
  rowHeight: number;
  paddingX: number;
  widget:
    | WidgetConfigProps
    | WidgetProps
    | (WidgetPropsRuntime & Partial<WidgetConfigProps>);
  dropTargetOffset: XYCoord;
  occupiedSpaces?: OccupiedSpace[];
  parentRows?: number;
  parentCols?: number;
  parentLeft?: number;
  parentTop?: number;
  mouseOffset?: XYCoord; // used to calculate the offset of the mouse from the top left corner of the widget
}): Rect | undefined {
  const [left, top] = getDropZoneOffsets(
    colWidth,
    rowHeight,
    clientOffset as XYCoord,
    dropTargetOffset,
  );

  const desiredRect = {
    left,
    top,
    bottom: top + widget.height.value,
    right: left + widget.width.value,
  };

  const parentRect: Rect = {
    top: parentTop ?? 0,
    left: parentLeft ?? 0, // padding x already accounted from before
    bottom: (parentTop ?? 0) + (parentRows ?? 0),
    right: (parentLeft ?? 0) + (parentCols ?? 0) - paddingX,
  };

  const mouseXY = mouseOffset
    ? getDropZoneOffsets(colWidth, rowHeight, mouseOffset, dropTargetOffset)
    : [
        desiredRect.left + desiredRect.right / 2,
        desiredRect.top + desiredRect.bottom / 2,
      ];

  return getAvailableRectInDropZone(
    parentRect,
    desiredRect,
    (widget as any).widgetId,
    (widget as any).type,
    occupiedSpaces,
    true,
    false,
    { x: mouseXY[0], y: mouseXY[1] },
  );
}

export function findNearbyFreePosition({
  clientOffset,
  colWidth,
  rowHeight,
  paddingX,
  widget,
  dropTargetOffset,
  occupiedSpaces,
  parentRows,
  parentCols,
  parentLeft,
  parentTop,
}: {
  clientOffset: XYCoord | XYNudgeAmount; // The change in position from the last event
  colWidth: number;
  rowHeight: number;
  paddingX: number;
  widget:
    | WidgetConfigProps
    | WidgetProps
    | (WidgetPropsRuntime & Partial<WidgetConfigProps>);
  dropTargetOffset: XYCoord;
  occupiedSpaces?: OccupiedSpace[];
  parentRows?: number;
  parentCols?: number;
  parentLeft?: number;
  parentTop?: number;
}): Rect | undefined {
  const [left, top] = getDropZoneOffsets(
    colWidth,
    rowHeight,
    clientOffset as XYCoord,
    dropTargetOffset,
  );

  const desiredRect = {
    left,
    top,
    bottom: top + widget.height.value,
    right: left + widget.width.value,
  };

  const parentRect: Rect = {
    top: parentTop ?? 0,
    left: parentLeft ?? 0, // padding X already accounted for before
    bottom: (parentTop ?? 0) + (parentRows ?? 0),
    right: (parentLeft ?? 0) + (parentCols ?? 0) - paddingX,
  };

  return getNearAvailableRectInDropZone(
    parentRect,
    desiredRect,
    (widget as any).type,
    occupiedSpaces,
  );
}

export const currentDropRow = (
  dropTargetRowSpace: number,
  dropTargetVerticalOffset: number,
  draggableItemVerticalOffset: number,
  widget: WidgetProps & Partial<WidgetConfigProps>,
) => {
  let widgetHeight = widget.height.value;
  if (widget.height.mode === "px") {
    widgetHeight = Dimension.toGridUnit(
      widget.height,
      GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
    ).raw().value;
  }
  const top = Math.round(
    (draggableItemVerticalOffset - dropTargetVerticalOffset) /
      dropTargetRowSpace,
  );
  const currentBottomOffset = top + widgetHeight;
  return currentBottomOffset;
};

export const adjustColumnWidths = (
  currentColumnWidths: Array<number>,
  targetSectionWidth: number,
) => {
  // calculate the total width of the columns
  let totalWidth = currentColumnWidths.reduce((acc, width) => acc + width, 0);
  // if totalWidth != targetSectionWidth, adjust the column widths until the width is targetSectionWidth
  let i = 0;
  const adjustedColumnWidths = [...currentColumnWidths];
  while (totalWidth !== targetSectionWidth) {
    const columnWidth = adjustedColumnWidths[i];
    if (totalWidth < targetSectionWidth) {
      adjustedColumnWidths[i] = columnWidth + 1;
      totalWidth += 1;
    } else if (totalWidth > targetSectionWidth) {
      if (columnWidth > 1) {
        adjustedColumnWidths[i] = columnWidth - 1;
        totalWidth -= 1;
      } else {
        // if all of the columns are 1, we can't reduce any further
        if (adjustedColumnWidths.every((width) => width === 1)) {
          throw new Error("Cannot reduce column widths any further");
        }
      }
    }
    i = (i + 1) % adjustedColumnWidths.length;
  }

  return adjustedColumnWidths;
};
