import { ApiResponseType, ApplicationScope } from "@superblocksteam/shared";
import _, { isArray } from "lodash";
import { createCachedSelector } from "re-reselect";
import { createSelector } from "reselect";
import { ApiInfo } from "legacy/constants/ApiConstants";
import {
  EditorOpenTabWithAPI,
  EditorOpenTabWithWidget,
  EditorOpenTabWithEntity,
  EditorOpenTab,
  EditorAppPreferences,
  EditorOpenTabType,
  EditorOpenTabWithStateVar,
  EditorOpenTabWithTimer,
  EditorOpenTabWithEvent,
  EditorOpenTabWithAPIInfo,
} from "legacy/constants/EditorPreferencesConstants";
import {
  EmbedProperty,
  EmbedPropertyAndMetaMap,
} from "legacy/constants/EmbeddingConstants";
import {
  WidgetType,
  WidgetTypes,
  PAGE_WIDGET_ID,
} from "legacy/constants/WidgetConstants";
import { EditorRouteBottomPanelParams } from "legacy/constants/routes";
import { DataTreeEntity } from "legacy/entities/DataTree/dataTreeFactory";
import { getParentToOpenIfAny } from "legacy/hooks/useClickOpenPropPane";
import { getWidgetDisplayName } from "legacy/pages/Editor/Explorer/helpers";
import { CanvasWidgetsReduxState } from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import { formatPropertyPathWithIndices } from "legacy/utils/BottomPanelTabUtils";
import { isTabPropertyValid } from "legacy/utils/EditorPreferencesUtils";
import { getWidgetChildrenIds } from "legacy/utils/WidgetPropsUtils";
import { selectAllApiNames, selectAllApis } from "store/slices/apis/selectors";
import {
  selectAllV2ApiNames,
  selectAllV2Apis,
} from "store/slices/apisV2/selectors";
import { getV2ApiId } from "store/slices/apisV2/utils/getApiIdAndName";
import {
  getAllEventNames,
  getAllEvents,
} from "store/slices/application/events/selectors";
import { getCurrentApplication } from "store/slices/application/selectors";
import {
  getAllStateVarNames,
  getAllStateVars,
} from "store/slices/application/stateVars/selectors";
import {
  getAllTimerNames,
  getAllTimers,
} from "store/slices/application/timers/selectors";
import { isOnEmbedRoute } from "utils/embed/messages";
import { getMainContainer } from "./entitiesSelector";
import { createMarkedSelector } from "./markedSelector";
import { getCurrentRoute } from "./routeSelectors";
import type { WidgetProps, WidgetMap } from "legacy/widgets";
import type { AppState } from "store/types";

export const getWidgets = (state: AppState): WidgetMap => {
  return state.legacy.entities.canvasWidgets;
};

export const getWidget = (state: AppState, widgetId: string): WidgetProps => {
  return state.legacy.entities.canvasWidgets[widgetId];
};

export const getSectionsOfParent = (state: AppState, widgetId: string) => {
  const widget = getWidget(state, widgetId);
  const parent = getWidget(state, widget.parentId);
  if (!parent) return [];

  return (parent?.children || [])
    .filter((id) => {
      return (
        state.legacy.entities.canvasWidgets[id]?.type ===
        WidgetTypes.SECTION_WIDGET
      );
    })
    .map((id) => state.legacy.entities.canvasWidgets[id]);
};

export const widgetIsSectionColumnAtIndex = (
  state: AppState,
  widgetId: string,
): number | undefined => {
  const widget = getWidget(state, widgetId);
  const parentWidget = widget && getWidget(state, widget.parentId || "");
  const parentIsSection = parentWidget?.type === WidgetTypes.SECTION_WIDGET;
  if (!parentIsSection || !parentWidget.children) return undefined;
  return parentWidget.children.indexOf(widget?.widgetId);
};

export const getWidgetsMeta = (state: AppState) => state.legacy.entities.meta;
export const getWidgetMetaProps = (state: AppState, widgetId: string) =>
  state.legacy.entities.meta[widgetId];

export const getWidgetIdsByType = (state: AppState, type: WidgetType) => {
  return Object.values(state.legacy.entities.canvasWidgets)
    .filter((widget) => widget.type === type)
    .map((widget) => widget.widgetId);
};

// embed prop
export const getEmbedProperties = createMarkedSelector("getEmbedProperties")(
  getMainContainer,
  (main) => {
    return main?.embedding?.propertyMap || {};
  },
);

export const getAllEmbedPropNames = createSelector(
  [getEmbedProperties],
  (propertyMap) => {
    return Object.values(propertyMap).map((embedProp) => embedProp.name);
  },
);

export const getEvalEmbedMap = createMarkedSelector("getEvalEmbedMap")(
  getAllEmbedPropNames,
  (state: AppState) => state.legacy.evaluations.tree.PAGE.Embed,
  (names, embed?: DataTreeEntity) => {
    return names.reduce(
      (acc, name) => {
        const evalNode = (embed as any)[name];
        return {
          ...acc,
          [name]: evalNode?.value,
        };
      },
      {} as Record<string, any>,
    );
  },
);

export const getInitialEmbedValues = (state: AppState) => {
  return state?.legacy?.embedding?.initialValues;
};

export const getEmbedPropsAndMeta = createMarkedSelector(
  "getEmbedPropertyAndMeta",
)(
  [
    getEmbedProperties,
    (state: AppState) => state.legacy.entities.embedPropsMeta,
  ],
  (embedPropMap, metaMap) => {
    const allEmbedPropIds = new Set(
      Object.keys(embedPropMap).concat(Object.keys(metaMap)),
    );
    const embedPropAndMeta = [...allEmbedPropIds].reduce((acc, id) => {
      const embedProp = embedPropMap[id] ?? {};
      // if not actually in embedding mode, use the defaultValue as the metaMap.value
      const isEmbedded = isOnEmbedRoute();
      const embedPropMeta = isEmbedded
        ? metaMap[id]
        : { value: embedProp.defaultValue };
      return {
        ...acc,
        [id]: {
          embedProp,
          embedPropMeta,
        },
      };
    }, {} as EmbedPropertyAndMetaMap);

    return embedPropAndMeta;
  },
);

export const getAllEmbedPropertiesAsArray = createMarkedSelector(
  "getAllEmbedPropertiesAsArray",
)(getMainContainer, (mainContainer) => {
  if (!mainContainer) return undefined;
  return Object.values(mainContainer?.embedding?.propertyMap ?? {});
});

export const getEmbedPropMetaIdValueMap = (state: AppState) => {
  const main = state.legacy.entities.canvasWidgets[PAGE_WIDGET_ID];
  const embedPropMap = main?.embedding?.propertyMap || {};
  const embedPropIds = Object.keys(embedPropMap);
  return embedPropIds.reduce((acc: Record<string, unknown>, id) => {
    const meta = state.legacy.entities.embedPropsMeta[id];
    acc[id] = meta?.value ?? undefined;
    return acc;
  }, {});
};

export const getEmbedPropById = createMarkedSelector("getEmbedPropById")(
  getMainContainer,
  (_: AppState, id: EmbedProperty["id"]) => id,
  (mainContainer, id) => mainContainer?.embedding?.propertyMap[id],
);

// embed events
export const getTriggerableEmbedEvents = createMarkedSelector(
  "getTriggerableEmbedEvents",
)(getMainContainer, (main) => {
  return main?.embedding?.triggerableEvents;
});

export const getEmittedEmbedEvents = createMarkedSelector(
  "getEmittedEmbedEvents",
)(getMainContainer, (main) => {
  return main?.embedding?.emittedEvents;
});

export const getPageDSLVersion = (state: AppState): number | undefined =>
  state.legacy.entities.canvasWidgets[PAGE_WIDGET_ID]?.version as
    | number
    | undefined;

export const getApiAppInfo = createMarkedSelector("getApiAppInfo")(
  getMainContainer,
  selectAllApis,
  (mainContainer, apis) => {
    const apiMap = mainContainer?.apis?.apiMap ?? {};
    return Object.values(apis).reduce(
      (acc, api) => {
        const apiInfo: ApiInfo | undefined = apiMap[api.id];
        acc[api.id] = apiInfo ?? { id: api.id };
        return acc;
      },
      {} as Record<string, ApiInfo>,
    );
  },
);

export const getApiInfoById = createMarkedSelector("getApiInfoById")(
  getMainContainer,
  (_: AppState, id: ApiInfo["id"]) => id,
  (mainContainer, id: ApiInfo["id"]): ApiInfo =>
    mainContainer?.apis?.apiMap[id] ?? {
      id: id,
    },
);

export const getApiByName = createSelector(
  selectAllApis,
  selectAllV2Apis,
  (_: AppState, name: string) => name,
  (apis, apisV2, name) => {
    return [...Object.values(apis), ...Object.values(apisV2)].find(
      (api) => api.name === name,
    );
  },
);

// TODO there are a few v1 usages that require v2 versions

export const getV2ApiAppInfo = createMarkedSelector("getV2ApiAppInfo")(
  getMainContainer,
  selectAllV2Apis,
  (mainContainer, apis) => {
    const apiMap = mainContainer?.apis?.apiMap ?? {};
    return Object.values(apis).reduce(
      (acc, api) => {
        const apiId = getV2ApiId(api);
        const apiInfo: ApiInfo | undefined = apiMap[apiId];
        acc[apiId] = apiInfo ?? { id: apiId };
        return acc;
      },
      {} as Record<string, ApiInfo>,
    );
  },
);

export const getV2ApiAppInfoById = createMarkedSelector("getV2ApiAppInfoById")(
  getV2ApiAppInfo,
  (_: AppState, id: ApiInfo["id"]) => id,
  (appInfo, id: ApiInfo["id"]) => {
    return (
      appInfo[id] ?? {
        id: id,
      }
    );
  },
);

export const selectAllV2NonStreamingApiNames = createSelector(
  selectAllV2Apis,
  getV2ApiAppInfo,
  (apis, appInfoById) => {
    return Object.keys(apis)
      .filter(
        (apiId) => appInfoById[apiId].responseType !== ApiResponseType.STREAM,
      )
      .map((apiId) => apis[apiId].apiPb.metadata.name);
  },
);

export const getEvaluationTree = (state: AppState) =>
  state.legacy.evaluations.tree;

export const getEvaluatedEmbed = (state: AppState) =>
  state.legacy.evaluations.tree.PAGE.Embed;

export const getPageCachedData = (state: AppState) => {
  const main = state.legacy.entities.canvasWidgets[PAGE_WIDGET_ID];
  return main?.cachedData;
};

export const getCurrentlyEditedPageId = (state: AppState) =>
  state.legacy.ui.editor.currentlyEditedPageId;

export const getEditorConfigs = createSelector(
  getCurrentlyEditedPageId,
  (state: AppState) => state.legacy.ui.editor.currentLayoutId,
  (pageId, layoutId) => {
    if (!pageId || !layoutId) return undefined;
    return { pageId, layoutId };
  },
);

export const getExistingWidgetNames = createMarkedSelector(
  "getExistingWidgetNames",
)(getWidgets, (widgets: WidgetMap) => {
  return Object.values(widgets)
    .map((widget) => widget.widgetName)
    .sort((a, b) => a.localeCompare(b));
});

export const getWidgetTypeByName = createMarkedSelector("getWidgetsByName")(
  getWidgets,
  (widgets: WidgetMap) => {
    return Object.fromEntries(
      Object.values(widgets).map((widget) => [widget.widgetName, widget.type]),
    );
  },
);

export const getDataTreeEntityScopeByName = createMarkedSelector(
  "getDataTreeEntityScopeByName",
)(getAllStateVars, getAllTimers, getAllEvents, (stateVars, timers, events) => {
  const allEntities = { ...stateVars, ...timers, ...events };
  const entityScopeByName: Record<string, ApplicationScope> = {};

  for (const entity of Object.values(allEntities)) {
    entityScopeByName[entity.name] = entity.scope;
  }

  return entityScopeByName;
});

/**
 * returns a objects of existing page name in data tree
 *
 * @param state
 */
export const getExistingPageNames = (state: AppState) =>
  state.legacy.entities.pageList.pages.map((page) => page.pageName);

export const getWidgetByName = (state: AppState, widgetName: string) => {
  const widgets = state.legacy.entities.canvasWidgets;
  return _.find(
    Object.values(widgets),
    (widget) => widget.widgetName === widgetName,
  );
};

export const getAllEntityNames = createSelector(
  getExistingWidgetNames,
  getExistingPageNames,
  getAllTimerNames,
  getAllStateVarNames,
  selectAllV2ApiNames,
  selectAllApiNames,
  getAllEventNames,
  (
    widgetNames,
    pageNames,
    timerNames,
    stateVarNames,
    apiV2Names,
    apiNames,
    eventNames,
  ) => {
    return [
      ...widgetNames,
      ...pageNames,
      ...timerNames,
      ...stateVarNames,
      ...apiNames,
      ...apiV2Names,
      ...eventNames,
    ];
  },
);

export const getSelectedWidget = (state: AppState) => {
  if (state.legacy.ui.widgetDragResize.selectedWidgets.length !== 1)
    return undefined;

  const selectedWidgetId = state.legacy.ui.widgetDragResize.selectedWidgets[0];
  if (!selectedWidgetId) return;

  return state.legacy.entities.canvasWidgets[selectedWidgetId];
};

export const getFocusedWidget = (state: AppState) => {
  const focusedWidgetId = state.legacy.ui.widgetDragResize.focusedWidgetId;
  if (!focusedWidgetId) return;

  return state.legacy.entities.canvasWidgets[focusedWidgetId];
};

export const isGridWidgetCellContainer = (
  state: AppState,
  widgetId: string,
) => {
  const widget = state.legacy.entities.canvasWidgets[widgetId];
  const parent = state.legacy.entities.canvasWidgets[widget?.parentId];
  const grandParent = state.legacy.entities.canvasWidgets[parent?.parentId];

  return (
    widget?.type === WidgetTypes.CONTAINER_WIDGET &&
    parent?.type === WidgetTypes.CANVAS_WIDGET &&
    grandParent?.type === WidgetTypes.GRID_WIDGET
  );
};

export const getSelectedWidgetsIds = (state: AppState) => {
  return state.legacy.ui.widgetDragResize.selectedWidgets;
};

export const getWidgetsAreSelected = createMarkedSelector(
  "getWidgetsAreSelected",
)(getSelectedWidgetsIds, (ids) => ids.length > 0);

export const getIsMultipleWidgetsSelected = createMarkedSelector(
  "getIsMultipleWidgetsSelected",
)(getSelectedWidgetsIds, (ids) => ids.length > 1);

export const getSelectedWidgetsParentId = (state: AppState) => {
  const widgetIds = state.legacy.ui.widgetDragResize.selectedWidgets;
  if (!widgetIds.length) return undefined;
  return state.legacy.entities.canvasWidgets[
    state.legacy.ui.widgetDragResize.selectedWidgets[0]
  ]?.parentId;
};

export const getSelectedWidgets = createMarkedSelector("getSelectedWidgets")(
  (state: AppState) => state.legacy.ui.widgetDragResize.selectedWidgets,
  (state: AppState) => state.legacy.entities.canvasWidgets,
  (selectedWidgets: string[], widgets: CanvasWidgetsReduxState) => {
    return selectedWidgets
      .map((widgetId) => {
        return widgets[widgetId];
      })
      .filter((w) => w !== undefined);
  },
);

export const getSingleSelectedWidgetId = (state: AppState) => {
  if (state.legacy.ui.widgetDragResize.selectedWidgets.length !== 1) {
    return undefined;
  }

  return state.legacy.ui.widgetDragResize.selectedWidgets[0];
};

export const getSingleSelectedWidget = (state: AppState) => {
  if (state.legacy.ui.widgetDragResize.selectedWidgets.length !== 1) {
    return undefined;
  }

  return state.legacy.entities.canvasWidgets[
    state.legacy.ui.widgetDragResize.selectedWidgets[0]
  ];
};

export const getIsWidgetSelected = (state: AppState, widgetId: string) => {
  // Note that widget could be undefined due to usage of this function by GridWidget
  const widget = state.legacy.entities.canvasWidgets[widgetId];
  const parent = state.legacy.entities.canvasWidgets[widget?.parentId];

  const isCanvas = widget?.type === WidgetTypes.CANVAS_WIDGET;
  const isColumn = parent?.type === WidgetTypes.SECTION_WIDGET;
  const isATab = parent?.type === WidgetTypes.TABS_WIDGET;

  let widgetIdToCheck = widgetId;

  if (isCanvas && parent && !isColumn && !isATab) {
    // ex: containers and forms are not directly selectable canvases inside another component
    // so we need to check the parent
    widgetIdToCheck = parent.widgetId;
  }

  return state.legacy.ui.widgetDragResize.selectedWidgets.includes(
    widgetIdToCheck,
  );
};

export const getChildrenIds = createCachedSelector(
  getWidgets,
  (_: AppState, widgetId: string) => widgetId,
  (widgets, widgetId) => {
    if (!widgets[widgetId] || widgets[widgetId].children?.length === 0) {
      return [];
    }
    return getWidgetChildrenIds(widgetId, widgets, {
      includeOrphanedWidgets: false,
    });
  },
)((_: AppState, widgetId: string) => widgetId);

export const isWidgetOrChildSelected = createCachedSelector(
  getSelectedWidgetsIds,
  getWidgets,
  (_: AppState, widgetId: string) => widgetId,
  (selectedWidgets, widgets, widgetId) => {
    if (selectedWidgets.length === 0) return false;
    if (selectedWidgets.includes(widgetId)) return true;
    if (!widgets[widgetId] || widgets[widgetId].children?.length === 0)
      return false;

    const childrenIds = getWidgetChildrenIds(widgetId, widgets, {
      includeOrphanedWidgets: false,
    });
    return childrenIds.some((id) => selectedWidgets.includes(id));
  },
)((_: AppState, widgetId: string) => widgetId);

export const isNonCanvasChildSelected = createCachedSelector(
  getSelectedWidgetsIds,
  getWidgets,
  (_: AppState, widgetId: string) => widgetId,
  (selectedWidgets, widgets, widgetId) => {
    if (selectedWidgets.length === 0) return false;
    if (selectedWidgets.includes(widgetId)) return false;
    if (!widgets[widgetId] || widgets[widgetId].children?.length === 0)
      return false;

    const childSelected = widgets[widgetId].children?.some((id) =>
      selectedWidgets.includes(id),
    );
    if (childSelected) return true;

    // We don't WANT to check nested children because both the parent and the child are selectable
    if (widgets[widgetId]?.type === WidgetTypes.SECTION_WIDGET) return false;

    // check children of canvas children
    return widgets[widgetId].children?.some((id) => {
      const child = widgets[id];
      if (child && child.type === WidgetTypes.CANVAS_WIDGET) {
        const childCanvasChildren = child.children || [];
        if (childCanvasChildren.some((id) => selectedWidgets.includes(id)))
          return true;
      }
      return false;
    });
  },
)((_: AppState, widgetId: string) => widgetId);

export const getIsWidgetFocused = (state: AppState, widgetId?: string) => {
  if (!widgetId)
    return state.legacy.ui.widgetDragResize.focusedWidgetId !== undefined;
  return state.legacy.ui.widgetDragResize.focusedWidgetId === widgetId;
};

export const getParentIsModalOrSlideout = (
  state: AppState,
  widgetId: string,
) => {
  const widget = state.legacy.entities.canvasWidgets[widgetId];
  if (!widget) return false;

  const parent = getParentToOpenIfAny(
    widgetId,
    state.legacy.entities.canvasWidgets,
  );
  if (!parent) return false;

  return (
    parent.type === WidgetTypes.MODAL_WIDGET ||
    parent.type === WidgetTypes.SLIDEOUT_WIDGET
  );
};

export const modalOrSlideoutIsOpen = (state: AppState) => {
  const firstSelectedWidgetId =
    state.legacy.ui.widgetDragResize.selectedWidgets[0];

  if (!firstSelectedWidgetId) return false;

  let widget = state.legacy.entities.canvasWidgets[firstSelectedWidgetId];

  // Now traverse the widget tree upward using parentId to see if any parent is a modal or slide
  while (widget !== undefined) {
    if (
      widget.type === WidgetTypes.MODAL_WIDGET ||
      widget.type === WidgetTypes.SLIDEOUT_WIDGET
    ) {
      return true;
    }
    widget = state.legacy.entities.canvasWidgets[widget.parentId];
  }
  return false;
};

export const selectPageWidgetIsEmpty = (state: AppState) => {
  const pageWidget = state.legacy.entities.canvasWidgets[PAGE_WIDGET_ID];
  if (!pageWidget) return false;
  if ((pageWidget.children?.length || 0) > 1) return false;

  for (const sectionWidgetId of pageWidget.children || []) {
    const sectionWidget = state.legacy.entities.canvasWidgets[sectionWidgetId];
    const childCanvases = sectionWidget.children || [];
    if (childCanvases.length > 1) return false;

    const onlyCanvas = state.legacy.entities.canvasWidgets[childCanvases[0]];
    const onlyCanvasChildren = onlyCanvas.children || [];
    if (!onlyCanvas || onlyCanvasChildren.length > 0) return false;
  }

  return true;
};

export const getIsDraggingForSelection = (state: AppState) => {
  return state.legacy.ui.canvasSelection.isDraggingForSelection;
};

const getSelectedWidgetsAncestry = (state: AppState) =>
  state.legacy.ui.widgetDragResize.selectedWidgetsAncestory;

export const getSelectedWidgetsAncestryWithNames = (
  state: AppState,
  widgetId: string,
) => {
  const widgets = state.legacy.entities.canvasWidgets;
  const selectedWidgetsAncestry =
    state.legacy.ui.widgetDragResize.selectedWidgetsAncestory;

  return (selectedWidgetsAncestry[widgetId] ?? [])
    .map((id) => {
      const ancestor = widgets[id];
      const ancestorParent = widgets[ancestor.parentId];

      // Don't return canvases unless they're columns
      const isCanvas = ancestor.type === WidgetTypes.CANVAS_WIDGET;
      const isColumn = ancestorParent?.type === WidgetTypes.SECTION_WIDGET;
      if (isCanvas && !isColumn) {
        return false;
      }

      return {
        widgetId: ancestor.widgetId,
        type: ancestor.type,
        widgetName: getWidgetDisplayName(ancestor, ancestorParent),
      };
    })
    .filter((w) => w !== false)
    .reverse() as Array<{
    widgetId: string;
    type: WidgetType;
    widgetName: string;
  }>;
};

export const getFirstSectionFirstColumn = (state: AppState) => {
  const canvasWidgets = state.legacy.entities.canvasWidgets;
  const pageWidget = canvasWidgets[PAGE_WIDGET_ID];
  const sectionWidgetId = pageWidget?.children?.[0] || "";
  const sectionWidget = canvasWidgets[sectionWidgetId];
  return canvasWidgets[sectionWidget?.children?.[0] || ""];
};

export const getWidgetIsInSelectedAncestry = createCachedSelector(
  getSelectedWidgetsAncestry,
  (state: AppState, widgetId: string) => widgetId,
  (selectedWidgetsAncestry, widgetId) => {
    if (!widgetId) return false;
    return Object.values(selectedWidgetsAncestry).flat().includes(widgetId);
  },
)((state, widgetId) => widgetId);

export const getAllEditorPreferences = (state: AppState) => {
  return state.legacy.ui.editorPreferences.preferences;
};

export const getPageEditorPreferences = createSelector(
  getAllEditorPreferences,
  (_: AppState, applicationId: string, pageId: string) => ({
    applicationId,
    pageId,
  }),
  (
    editorPreferences: Record<string, EditorAppPreferences>,
    { applicationId, pageId },
  ) => {
    return editorPreferences?.[applicationId]?.[pageId];
  },
);

export const getDeveloperPreferences = (state: AppState) => {
  return state.legacy.ui.editorPreferences.developer;
};

export const getSharedDeveloperPreferences = (state: AppState) => {
  return state.legacy.ui.editorPreferences.developer.shared;
};

export const selectWidgetDisplayName = (
  state: AppState,
  widgetId: string,
  widgetName?: string, // This is used for displaying names for grid cells
  useAiName?: boolean,
): string => {
  const widget = state.legacy.entities.canvasWidgets[widgetId];
  if (!widget) return "";

  if (
    useAiName &&
    state.ai.selectedWidgetId === widgetId &&
    state.ai.widgetRename
  ) {
    return state.ai.widgetRename;
  }

  const parent = state.legacy.entities.canvasWidgets[widget.parentId];
  return getWidgetDisplayName(widget, parent, widgetName);
};

export const canOpenTabForParams = createSelector(
  selectAllApis,
  selectAllV2Apis,
  getWidgets,
  getAllStateVars,
  getAllTimers,
  getAllEvents,
  getV2ApiAppInfo,
  getCurrentApplication,
  (
    apis,
    v2Apis,
    widgets,
    stateVars,
    timers,
    events,
    apiInfo,
    application: ReturnType<typeof getCurrentApplication>,
  ): ((params: EditorRouteBottomPanelParams, strict?: boolean) => boolean) => {
    return (params: EditorRouteBottomPanelParams, strict = false) => {
      if (!application) return false;

      if (params.apiId && (apis[params.apiId] || v2Apis[params.apiId])) {
        return true;
      } else if (params.entityId) {
        const entity =
          widgets[params.entityId] ||
          stateVars[params.entityId] ||
          timers[params.entityId] ||
          events[params.entityId] ||
          apiInfo[params.entityId];
        if (!entity) return false;
        const formattedProperty = formatPropertyPathWithIndices(
          entity,
          params.property || "",
        );
        return (
          !!formattedProperty &&
          isTabPropertyValid(entity, formattedProperty, strict)
        );
      }

      return false;
    };
  },
);

export const getOpenBottomPanelTabs = createSelector(
  (state: AppState) => state.legacy.ui.editorPreferences,
  selectAllApis,
  selectAllV2Apis,
  getWidgets,
  getCurrentApplication,
  (state: AppState) => state.legacy.entities.pageList.currentPageId,
  getCurrentRoute,
  getAllStateVars,
  getAllTimers,
  getAllEvents,
  getV2ApiAppInfo,
  (
    editorPreferences: AppState["legacy"]["ui"]["editorPreferences"],
    apis,
    v2Apis,
    widgets,
    application: ReturnType<typeof getCurrentApplication>,
    currentPageId: string | undefined,
    currentRoute: ReturnType<typeof getCurrentRoute>,
    stateVars: ReturnType<typeof getAllStateVars>,
    timers: ReturnType<typeof getAllTimers>,
    events: ReturnType<typeof getAllEvents>,
    apiInfo: ReturnType<typeof getV2ApiAppInfo>,
  ): EditorOpenTabWithEntity[] => {
    if (!application) return [];

    const pageId =
      currentRoute && "pageId" in currentRoute.routeDef
        ? (currentRoute.routeDef.pageId ?? currentPageId)
        : currentPageId;

    const applicationPreferences =
      editorPreferences.preferences[application.id] ?? {};

    let tabs: EditorOpenTab[] = [];
    if (pageId) {
      tabs = applicationPreferences[pageId]?.openBottomPanelTabs || [];
    }

    return tabs
      .map((tab) => {
        if (tab.entityType === EditorOpenTabType.API) {
          return {
            ...tab,
            name:
              apis[tab.entityId]?.name ?? v2Apis[tab.entityId]?.name ?? "API",
            entity: apis[tab.entityId] ?? v2Apis[tab.entityId],
            entityType: EditorOpenTabType.API,
          } satisfies EditorOpenTabWithAPI;
        } else if (tab.entityType === EditorOpenTabType.WIDGET) {
          const entity = widgets[tab.entityId];
          if (!entity) return null;

          const formattedProperty = formatPropertyPathWithIndices(
            entity,
            tab.widgetProperty || "",
          );

          const isValidProperty =
            !!entity &&
            !!formattedProperty &&
            isTabPropertyValid(entity, formattedProperty);

          if (!isValidProperty) return null;

          // If the formattedProperty is undefined, it's because we can't find the trigger
          // with that ID anymore and we should not show this tab
          return {
            ...tab,
            entity,
            name: entity.widgetName,
            entityType: EditorOpenTabType.WIDGET,
          } satisfies EditorOpenTabWithWidget;
        } else if (tab.entityType === EditorOpenTabType.STATE_VAR) {
          const entity = stateVars[tab.entityId];
          if (!entity) return null;
          return {
            ...tab,
            entity,
            name: entity.name,
            entityType: EditorOpenTabType.STATE_VAR,
          } satisfies EditorOpenTabWithStateVar;
        } else if (tab.entityType === EditorOpenTabType.TIMER) {
          const entity = timers[tab.entityId];
          if (!entity) return null;
          return {
            ...tab,
            entity: timers[tab.entityId],
            name: entity.name,
            entityType: EditorOpenTabType.TIMER,
          } satisfies EditorOpenTabWithTimer;
        } else if (tab.entityType === EditorOpenTabType.CUSTOM_EVENT) {
          const entity = events[tab.entityId];
          if (!entity) return null;
          return {
            ...tab,
            entity: events[tab.entityId],
            name: entity.name,
            entityType: EditorOpenTabType.CUSTOM_EVENT,
          } satisfies EditorOpenTabWithEvent;
        } else if (tab.entityType === EditorOpenTabType.API_INFO) {
          return {
            ...tab,
            name: v2Apis[tab.entityId]?.name ?? "API",
            entity: apiInfo[tab.entityId],
            entityType: EditorOpenTabType.API_INFO,
          } satisfies EditorOpenTabWithAPIInfo;
        } else {
          console.warn(
            `Unhandled opened tab type for: ${JSON.stringify(
              tab.entityType,
              null,
              2,
            )}`,
          );
          return null;
        }
      })
      .filter((tab) => !!tab && !!tab.entity) as EditorOpenTabWithEntity[];
  },
);

export const getSectionColumnChildIds = createSelector(
  [getWidgets, getWidget],
  (widgets, sectionWidget) => {
    if (!sectionWidget) {
      return [];
    }

    // get all the child ids from each section column
    const childIds: string[] = [];
    sectionWidget.children?.forEach((childId) => {
      const canvas = widgets[childId];
      canvas.children?.forEach((childId) => {
        childIds.push(childId);
      });
    });

    return childIds;
  },
);

const findLowestAncestortWithType = (
  widget: WidgetProps,
  widgets: CanvasWidgetsReduxState,
  type: WidgetType | WidgetTypes[],
): WidgetProps | undefined => {
  if (
    isArray(type)
      ? (type as WidgetTypes[]).includes(widget.type as WidgetTypes)
      : widget.type === type
  ) {
    return widget;
  }
  if (!widget.parentId || !widgets[widget.parentId]) return undefined;
  return findLowestAncestortWithType(widgets[widget.parentId], widgets, type);
};

export const getLowestAncestorWithType = createCachedSelector(
  getWidgets,
  (_: AppState, widgetId: string) => widgetId,
  (_: AppState, __: string, type: WidgetType) => type,
  (widgets, widgetId, type) => {
    if (!widgets[widgetId]) return undefined;
    const parentWidget = findLowestAncestortWithType(
      widgets[widgetId],
      widgets,
      type,
    );
    return parentWidget;
  },
)(
  (_: AppState, widgetId: string, type: WidgetType | WidgetType[]) =>
    `${widgetId}-${type}`,
);

const findLowestAncestortWithScrollContainter = (
  widget: WidgetProps,
  widgets: CanvasWidgetsReduxState,
): WidgetProps | undefined => {
  if (
    widget.type === WidgetTypes.CONTAINER_WIDGET ||
    widget.type === WidgetTypes.FORM_WIDGET ||
    widget.type === WidgetTypes.TABS_WIDGET
  ) {
    return widget;
  }

  if (!widget.parentId || !widgets[widget.parentId]) return undefined;
  // if this is column
  if (widgets[widget.parentId].type === WidgetTypes.SECTION_WIDGET) {
    return widget;
  }
  return findLowestAncestortWithScrollContainter(
    widgets[widget.parentId],
    widgets,
  );
};

export const getLowestAncestorWithScrollContainer = createCachedSelector(
  getWidgets,
  (_: AppState, widgetId: string) => widgetId,
  (_: AppState, __: string, type: WidgetType) => type,
  (widgets, widgetId) => {
    if (!widgets[widgetId]) return undefined;
    let widget = widgets[widgetId];
    if (
      widget.type === WidgetTypes.CONTAINER_WIDGET ||
      widget.type === WidgetTypes.FORM_WIDGET ||
      widget.type === WidgetTypes.TABS_WIDGET
    ) {
      widget = widgets[widget.parentId];
    }
    if (!widget) return undefined;
    const widgetWithScrollContainer = findLowestAncestortWithScrollContainter(
      widget,
      widgets,
    );
    return widgetWithScrollContainer;
  },
)(
  (_: AppState, widgetId: string, type: WidgetType | WidgetType[]) =>
    `${widgetId}`,
);
