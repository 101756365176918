import Icon from "@ant-design/icons";
import { Menu, Dropdown, Tooltip, Alert } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useTheme } from "styled-components";
import { ReactComponent as ChevronDown } from "assets/icons/common/chevron-down-dropdown.svg";
import { StyledReactMarkdown } from "components/app/SBDynamicForm/DynamicFormItem";
import { useSaga } from "hooks/store";
import { stopEvaluation } from "legacy/actions/evaluationActions";
import { DropdownMenuItem } from "legacy/pages/common/CustomizedDropdown/StyledComponents";
import { useAppDispatch } from "store/helpers";
import { getAgentsSaga } from "store/slices/agents";
import { ReduxActionTypes } from "../../legacy/constants/ReduxActionConstants";
import { getEnvironment } from "../../store/slices/application/selectors";
import { selectOrganizations } from "../../store/slices/organizations";
import { prettyEnvironment } from "../../store/utils/environment";
import { updatedSearchParams } from "../../utils/environment";
import { cleanNotification } from "../../utils/notification";
import { HeaderDropdownSelector } from "./Header";

export enum DeploymentMessage {
  WORKFLOW = "Use the query parameter ?environment to specify environments. Otherwise, the workflow will execute against the production environment.",
  APPLICATION = "Use the query parameter ?environment to specify environments. Otherwise, the application will execute against the production environment.",
  SCHEDULED_JOB = "All scheduled jobs will use the production environment in deployed mode.",
}

interface DeploymentAlertProps {
  message: DeploymentMessage;
}

export const DeploymentAlert = ({ message }: DeploymentAlertProps) => {
  return <Alert message={message} type="info" showIcon />;
};

export const DeploymentAlertWithInstruction = ({
  message,
}: {
  message?: string | React.ReactNode;
}) => (
  <Alert
    message={
      message || (
        <StyledReactMarkdown linkTarget="_blank">{`Workflow can also be run for particular profiles, branches, and with different authentication methods. [Learn more about running Workflows](https://docs.superblocks.com/workflows/overview)`}</StyledReactMarkdown>
      )
    }
    type="info"
    showIcon
  ></Alert>
);

export const EnvironmentDropdown = ({
  dropdownColor,
  backgroundColor,
}: {
  dropdownColor?: string;
  backgroundColor?: string;
}) => {
  const organizations = useSelector(selectOrganizations);
  const organization = Object.values(organizations)[0];
  const [getAgents] = useSaga(getAgentsSaga);
  const environment = useSelector(getEnvironment);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const [dropdownVisible, setDropdownVisible] = useState(false);

  // TODO(EG-2376): This should be a Select. A Dropdown is a naviagation
  // featuers.
  return (
    <Dropdown
      onOpenChange={(visible) => setDropdownVisible(visible)}
      className="environment-dropdown"
      // TODO(antd upgrade): Deprecated use of overlay
      overlay={
        <Menu>
          {organization.profiles?.map((p) => {
            return (
              <DropdownMenuItem
                key={p.key}
                data-test={"environment-dropdown-" + p.key + "-option"}
                onClick={(_) => {
                  setDropdownVisible(false);
                  if (environment === p.key) {
                    // Don't refire if the event hasn't changed. We specifically
                    // don't want to RESET_WIDGETS.
                    return;
                  }
                  cleanNotification();
                  const params = updatedSearchParams("environment", p.key);
                  navigate({ search: params.toString() });
                  getAgents({ organization });
                  dispatch({
                    type: ReduxActionTypes.UPDATE_ENVIRONMENT,
                    payload: { environment: p.key },
                  });
                  dispatch(stopEvaluation());
                  dispatch({ type: ReduxActionTypes.RESET_WIDGETS });
                }}
              >
                {p.displayName}
              </DropdownMenuItem>
            );
          })}
        </Menu>
      }
      trigger={["click"]}
      placement="bottomLeft"
    >
      <Tooltip
        title={`This application is running against your ${environment} Integrations in Edit and Preview mode.

        Note that an Integration can be configured with the same staging and production configuration when first added. You can access the configurations from the “Setup Integrations” tab on the home page.`}
        overlayInnerStyle={{ fontSize: "smaller" }}
        overlayStyle={{ whiteSpace: "pre-line" }}
        placement="leftBottom"
      >
        <HeaderDropdownSelector
          onClick={(e) => e.preventDefault()}
          isSelected={dropdownVisible}
          backgroundColor={backgroundColor}
        >
          {prettyEnvironment(environment)}
          <Icon
            component={ChevronDown}
            style={{
              fontSize: 20,
              color: dropdownColor ?? theme.colors.GREY_300,
              paddingLeft: 2,
            }}
          />
        </HeaderDropdownSelector>
      </Tooltip>
    </Dropdown>
  );
};
