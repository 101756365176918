import {
  iframeMessageHandler,
  GetDropTargetMessageTypes,
  sendMessage,
  IframeEventHandler,
  MessageTypeToPayload,
} from "./iframe";

/**
 * getDropTarget retrieves drop target properties in a saga.
 *
 * # Purpose
 * The drop target helps us calculate the scroll offset of the canvas (Element.getBoundingClientRect()).
 * Combined with the current mouse position (X,Y), it let us determine where to paste a widget.
 *
 * # How it works
 * To obtain drop target properties, we send a message (postMessage) to the iFrame and wait for the result (via another postMessage).
 * - All communication with the iFrame is managed by `iframeMessageHandler`.
 *
 * @param parentId - The ID of the parent widget where the item will be dropped.
 * @returns object with target properties { offset: XYCoord; size: { height: number; width: number }}
 */

export type getDropTargetResultType =
  MessageTypeToPayload[GetDropTargetMessageTypes.GET_RESULT];

export async function getDropTarget({
  parentId,
}: {
  parentId: string;
}): Promise<MessageTypeToPayload[GetDropTargetMessageTypes.GET_RESULT]> {
  return new Promise((resolve, reject) => {
    const handleMessage: IframeEventHandler<
      GetDropTargetMessageTypes.GET_RESULT
    > = ({ data }) => {
      if (data) {
        iframeMessageHandler.removeEventListener(
          GetDropTargetMessageTypes.GET_RESULT,
          handleMessage,
        );
        resolve(data.payload);
      }
    };

    iframeMessageHandler.addEventListener(
      GetDropTargetMessageTypes.GET_RESULT,
      handleMessage,
    );

    sendMessage({ type: GetDropTargetMessageTypes.GET, payload: { parentId } });

    setTimeout(() => {
      iframeMessageHandler.removeEventListener(
        GetDropTargetMessageTypes.GET_RESULT,
        handleMessage,
      );

      reject(new Error("Timeout waiting for iframe response."));
    }, 5000);
  });
}
