import { ApplicationScope, getNextEntityName } from "@superblocksteam/shared";
import { all, put, select, takeEvery } from "redux-saga/effects";

import { v4 as uuidv4 } from "uuid";
import { updatePartialLayout } from "legacy/actions/pageActions";
import {
  deleteEntityFromWidgets,
  selectWidgets,
} from "legacy/actions/widgetActions";
import {
  ReduxAction,
  ReduxActionErrorTypes,
} from "legacy/constants/ReduxActionConstants";
import { PAGE_WIDGET_ID } from "legacy/constants/WidgetConstants";
import { getMainContainer } from "legacy/selectors/entitiesSelector";
import {
  getAllEntityNames,
  getEmittedEmbedEvents,
  getTriggerableEmbedEvents,
} from "legacy/selectors/sagaSelectors";
import { requestApplicationSave } from "store/slices/application/applicationActions";
import {
  createEvent,
  deleteCustomEvent,
  duplicateCustomEvent,
  editEventPropertyPane,
  setCreatingCustomEvent,
  updateCustomEvent,
} from "store/slices/application/events/eventActions";
import {
  EventDefinition,
  EventDefinitionScoped,
} from "store/slices/application/events/eventConstants";
import {
  getScopedEvents,
  getEventById,
} from "store/slices/application/events/selectors";
import { overwriteScopedEvents } from "store/slices/application/events/slice";
import { deleteEntityFromTimers } from "store/slices/application/timers/timerActions";
import { getScopedEntityPrefix } from "store/utils/scope";
import { fastClone } from "utils/clone";
import logger from "utils/logger";

function* handleEventError<T>(
  actionType: string,
  action: ReduxAction<T>,
  error: any,
) {
  logger.error(
    `CUSTOM_EVENT_OPERATION_ERROR action type: ${actionType} error: ${
      (error as any)?.message
    }, payload: ${JSON.stringify(action.payload)}`,
  );
  yield put({
    type: ReduxActionErrorTypes.CUSTOM_EVENT_OPERATION_ERROR,
    payload: {
      action: actionType,
      error,
    },
  });
}

function* createEventSaga(
  action: ReturnType<typeof createEvent>,
): Generator<any, any, any> {
  try {
    yield put(setCreatingCustomEvent(true));

    const id = action.payload.id ?? uuidv4();
    const scope = action.payload.scope;

    let name = action.payload.name;
    if (!name) {
      const namePrefix = getScopedEntityPrefix(scope, "Event");
      const entityNames = yield select(getAllEntityNames);
      name = getNextEntityName(namePrefix, [...entityNames]);
    }
    if (!name) {
      // Ensure that names are unique.
      throw new Error("Failed to generate name");
    }

    const newEvent = {
      id: id,
      name: name,
      arguments: [],
      onTrigger: [],
      scope,
    } satisfies EventDefinitionScoped;

    const scopedEvents = yield select(getScopedEvents, action.payload.scope);
    const newEvents = {
      ...fastClone(scopedEvents),
      [newEvent.id]: newEvent,
    };

    yield put(
      overwriteScopedEvents({ scope: action.payload.scope, events: newEvents }),
    );

    if (action.payload.scope === ApplicationScope.APP) {
      yield put(requestApplicationSave());
    }

    if (action.payload.open) {
      yield put(editEventPropertyPane(newEvent.id, action.payload.scope));
      yield put(selectWidgets([]));
    }
  } catch (error) {
    handleEventError(action.type, action, error);
  } finally {
    yield put(setCreatingCustomEvent(false));
  }
}

function* duplicateEventSaga(
  action: ReturnType<typeof duplicateCustomEvent>,
): Generator<any, any, any> {
  try {
    const { eventId, toScope } = action.payload;
    const event: EventDefinition = yield select(getEventById, eventId);
    if (!event) {
      throw new Error("Event not found");
    }

    const entityNames = yield select(getAllEntityNames);
    const name = getNextEntityName(`${event.name}_copy`, [...entityNames]);

    const newEvent = {
      ...event,
      id: uuidv4(),
      name,
      scope: toScope,
    } satisfies EventDefinitionScoped;

    const scopedEvents = yield select(getScopedEvents, toScope);
    const newEvents = {
      ...fastClone(scopedEvents),
      [newEvent.id]: newEvent,
    };

    yield put(overwriteScopedEvents({ scope: toScope, events: newEvents }));
    if (toScope === ApplicationScope.APP) {
      yield put(requestApplicationSave());
    }

    yield put(editEventPropertyPane(newEvent.id, ApplicationScope.PAGE));
  } catch (error) {
    handleEventError(action.type, action, error);
  }
}

function* updateEventSaga(
  action: ReturnType<typeof updateCustomEvent>,
): Generator<any, any, any> {
  try {
    const { updates, scope } = action.payload;

    const events: ReturnType<typeof getScopedEvents> = yield select(
      getScopedEvents,
      scope,
    );
    const newEventMap = fastClone(events);

    Object.entries(updates).forEach(([eventId, update]) => {
      newEventMap[eventId] = {
        ...newEventMap[eventId],
        ...update,
      };
    });

    yield put(overwriteScopedEvents({ scope, events: newEventMap }));

    if (scope === ApplicationScope.APP) {
      yield put(requestApplicationSave());
    }
  } catch (error) {
    handleEventError(action.type, action, error);
  }
}

function* deleteEventSaga(
  action: ReturnType<typeof deleteCustomEvent>,
): Generator<any, any, any> {
  try {
    const { id, scope } = action.payload;
    const events: ReturnType<typeof getScopedEvents> = yield select(
      getScopedEvents,
      scope,
    );
    const newEventMap = fastClone(events);

    const eventToDelete = newEventMap[id];
    const eventName = eventToDelete.name;

    delete newEventMap[id];

    // delete the event from the emitted and triggerable embed events
    const mainContainerWidget = yield select(getMainContainer);
    const existingEmittedEvents = yield select(getEmittedEmbedEvents);
    const existedTriggerableEvents = yield select(getTriggerableEmbedEvents);
    const newEmittedEvents = fastClone(existingEmittedEvents ?? {});
    const newTriggerableEvents = fastClone(existedTriggerableEvents ?? {});
    delete newEmittedEvents[id];
    delete newTriggerableEvents[id];
    const updatedWidgetsPartial = {
      [PAGE_WIDGET_ID]: {
        ...mainContainerWidget,
        events: {
          ...mainContainerWidget.events,
          eventMap: newEventMap,
        },
        embedding: {
          ...mainContainerWidget.embedding,
          emittedEvents: newEmittedEvents,
          triggerableEvents: newTriggerableEvents,
        },
      },
    };
    // we can have overwriteScopedEvents do the save for us
    yield put(updatePartialLayout(updatedWidgetsPartial, false));

    // TODO(pbardea): These 2 should always be called together - they should be
    // combined.
    yield put(deleteEntityFromWidgets(eventName));
    yield put(deleteEntityFromTimers(eventName));

    yield put(overwriteScopedEvents({ scope, events: newEventMap }));

    if (scope === ApplicationScope.APP) {
      yield put(requestApplicationSave());
    }
  } catch (error) {
    handleEventError(deleteCustomEvent.type, action, error);
  }
}

export default function* EventSagas() {
  yield all([takeEvery(createEvent.type, createEventSaga)]);
  yield all([takeEvery(updateCustomEvent.type, updateEventSaga)]);
  yield all([takeEvery(deleteCustomEvent.type, deleteEventSaga)]);
  yield all([takeEvery(duplicateCustomEvent.type, duplicateEventSaga)]);
}
