import {
  ApplicationScope,
  Dimension,
  PropertyUpdate,
  WidgetProps,
  getNextEntityName,
  getUpdatesInWidgets,
} from "@superblocksteam/shared";
import { isString } from "lodash";
import { set } from "lodash";
import { call, put, select } from "redux-saga/effects";
import { updateLayout } from "legacy/actions/pageActions";
import {
  resizeSectionWidgetColumns,
  selectWidgets,
  showModal,
} from "legacy/actions/widgetActions";
import {
  PAGE_WIDGET_ID,
  WidgetType,
  WidgetTypes,
  CanvasLayout,
  CanvasDefaults,
  WIDGET_CAN_HAVE_CHILDREN,
  ATTACHED_WIDGET_AND_CAN_HAVE_CHILDREN,
  WidgetWidthModes,
  SectionDefaults,
} from "legacy/constants/WidgetConstants";
import { OccupiedSpace } from "legacy/constants/editorConstants";
import { emptyDataTree } from "legacy/entities/DataTree/DataTreeHelpers";
import { type ScopedDataTree } from "legacy/entities/DataTree/dataTreeFactory";
import { type WidgetConfigProps } from "legacy/mockResponses/WidgetConfigResponse";
import { getWidgetBlueprint } from "legacy/mockResponses/selectors";
import { flashElementById } from "legacy/pages/Editor/visibilityUtil";
import {
  CanvasWidgetsReduxState,
  FlattenedWidgetProps,
} from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import { MetaState } from "legacy/reducers/entityReducers/metaReducer";
import { APP_MODE } from "legacy/reducers/types";
import {
  calculateNewWidgetPosition,
  calculateNewWidgetPositionOnMousePosition,
  updateChildrenSizeModesForParentLayout,
  updateSectionWidgetCanvasHeights,
  updateWidgetWidths,
} from "legacy/sagas/WidgetOperationsSagasUtils";
import {
  getAppMode,
  getResponsiveCanvasScaleFactor,
} from "legacy/selectors/applicationSelectors";
import {
  getFlattenedCanvasWidgets,
  getMainContainerWidgetId,
  getOccupiedSpacesSelectorForContainer,
} from "legacy/selectors/editorSelectors";
import { getDynamicLayoutWidgets } from "legacy/selectors/layoutSelectors";
import { getAllEntityNames, getWidgets } from "legacy/selectors/sagaSelectors";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { getHstackCanvasRemainingWidthPx } from "legacy/utils/StackWidgetUtils";
import {
  dimensionToGridRows,
  getSectionColsForParentType,
} from "legacy/utils/WidgetPropsUtils";
import { WidgetPropsRuntime } from "legacy/widgets/BaseWidget";
import { ColumnProperties } from "legacy/widgets/TableWidget/TableComponent/Constants";
import { adjustColumnWidths } from "legacy/widgets/base/ResizableUtils";
import { isDynamicSize } from "legacy/widgets/base/sizing/dynamicLayoutUtils";
import {
  FlattenedWidgetLayoutProps,
  FlattenedWidgetLayoutMap,
} from "legacy/widgets/shared";
import { extractJsEvaluationPairs } from "legacy/workers/evaluationUtils";
import { Flag, Flags, selectFlagById } from "store/slices/featureFlags";
import { GeneratorReturnType } from "store/utils/types";
import { fastClone } from "utils/clone";
import { getDropTarget, getDropTargetResultType } from "utils/drop";
import { findFirstParent } from "utils/findFirstParent";
import { sendMessage } from "utils/iframe";
import log from "utils/logger";
import { isWidgetEmpty, isInDefaultState } from "utils/widgets";
import AnalyticsUtil from "./AnalyticsUtil";
import { generateReactKey } from "./generators";
import { setLastPastedSingleWidgetId } from "./lastPastedSingleWidgetId";
import type {
  CopiedWidgets,
  WidgetMap,
  WidgetTypeToPropType,
} from "legacy/widgets";

export function getPasteParentDetails({
  pasteTargetId,
  openModalOrSlideout,
  widgets,
  widgetMeta,
  copiedWidgets,
  copiedWidgetsType,
  lastPastedSingleWidgetId,
  forcePasteIntoContainer = false,
  pageDataTree,
}: {
  pasteTargetId: string;
  openModalOrSlideout?: CanvasWidgetsReduxState[string];
  widgets: CanvasWidgetsReduxState;
  widgetMeta: MetaState;
  copiedWidgets?: CopiedWidgets;
  copiedWidgetsType: WidgetType;
  lastPastedSingleWidgetId?: string;
  forcePasteIntoContainer?: boolean;
  pageDataTree: ScopedDataTree;
}): CanvasWidgetsReduxState[string] | undefined {
  const pasteTargetWidget = widgets[pasteTargetId];
  if (!pasteTargetWidget) return undefined;

  // Differences are for Page, Section, or anything else.
  // For Page, we only want to paste into the page or the open modal or slideout if it's a Section that's copied.
  // For copied modals and slideous, the page is always the target
  // For columns, we find the closest section
  // For everything else we find the closest canvas
  switch (copiedWidgetsType) {
    case WidgetTypes.SLIDEOUT_WIDGET:
    case WidgetTypes.MODAL_WIDGET: {
      return widgets[PAGE_WIDGET_ID];
    }
    case WidgetTypes.SECTION_WIDGET: {
      return openModalOrSlideout || widgets[PAGE_WIDGET_ID];
    }
    case WidgetTypes.CANVAS_WIDGET: {
      // this must be a column, as it's the only canvas widget that can be copied directly

      // Find the closest parent that is a section
      return findFirstParent(
        pasteTargetId,
        widgets,
        (widget) => widget.type === WidgetTypes.SECTION_WIDGET,
      );
    }
    default: {
      switch (pasteTargetWidget.type) {
        case WidgetTypes.PAGE_WIDGET: {
          const page = widgets[PAGE_WIDGET_ID];
          const firstSectionId = page?.children?.[0];
          const firstColumnId = widgets[firstSectionId || ""]?.children?.[0];
          const firstColumnWidget = widgets[firstColumnId || ""];
          return firstColumnWidget;
        }
        case WidgetTypes.MODAL_WIDGET:
        case WidgetTypes.SLIDEOUT_WIDGET: {
          // find the first section first canvas of the modal or slideout
          const firstSectionId = pasteTargetWidget.children?.[0];
          const firstCanvasId = widgets[firstSectionId || ""]?.children?.[0];
          return widgets[firstCanvasId || ""];
        }
        case WidgetTypes.CANVAS_WIDGET:
          return pasteTargetWidget;
        case WidgetTypes.CONTAINER_WIDGET:
        case WidgetTypes.FORM_WIDGET:
        case WidgetTypes.SECTION_WIDGET:
        case WidgetTypes.GRID_WIDGET: {
          const singleCopiedContainerWidget =
            copiedWidgets &&
            copiedWidgets.length === 1 &&
            copiedWidgets[0].list[0].type === WidgetTypes.CONTAINER_WIDGET
              ? copiedWidgets[0].list[0]
              : undefined;

          const lastPastedSingleWidget = lastPastedSingleWidgetId
            ? widgets[lastPastedSingleWidgetId]
            : undefined;

          const lastPastedWidgetIsContainer = lastPastedSingleWidget
            ? ATTACHED_WIDGET_AND_CAN_HAVE_CHILDREN.includes(
                lastPastedSingleWidget.type as WidgetTypes,
              )
            : false;

          /**
           * Two rules for pasting into a container target:
           * 1. we don't paste a single copied container into the same container it was copied from, instead we paste as a sibling of the copied container as that's usually what people want.
           * 2. If the last pasted widget was a single container, and we're pasting a single container now, we paste as a sibling of the last pasted container rather than inside it. This allows a user to cmd+c a container, then cmd+v 5 times in a row and ensure they're ALL pasted as siblings, not just the first paste
           */

          const singleCopiedWidgetIsSameAsPasteTarget =
            singleCopiedContainerWidget?.widgetId ===
            pasteTargetWidget.widgetId;
          if (
            singleCopiedContainerWidget &&
            pasteTargetWidget.children &&
            pasteTargetWidget.type !== WidgetTypes.GRID_WIDGET &&
            !forcePasteIntoContainer &&
            (singleCopiedWidgetIsSameAsPasteTarget ||
              (lastPastedWidgetIsContainer &&
                lastPastedSingleWidgetId ===
                  singleCopiedContainerWidget.widgetId))
          ) {
            return widgets[pasteTargetWidget.parentId];
          }

          return widgets[pasteTargetWidget.children?.[0] || ""];
        }
        case WidgetTypes.TABS_WIDGET: {
          const defaultTabWidgetId: string | undefined = (
            pageDataTree?.[pasteTargetWidget.widgetName] as any
          )?.defaultTabWidgetId;
          const selectedTabWidgetId =
            (widgetMeta?.[pasteTargetWidget.widgetId]?.selectedTabWidgetId as
              | string
              | undefined) ?? defaultTabWidgetId;
          return widgets[selectedTabWidgetId || ""];
        }
        default:
          // just find the first parent canvas
          return findFirstParent(
            pasteTargetId,
            widgets,
            (widget) => widget.type === WidgetTypes.CANVAS_WIDGET,
          );
      }
    }
  }
}

export const updatePastedTabsWidget = (
  widget: Omit<WidgetProps, "children">,
  widgetIdMap: Record<string, string>,
) => {
  if (widget.type === WidgetTypes.TABS_WIDGET) {
    try {
      const tabWidget =
        widget as unknown as WidgetTypeToPropType[WidgetTypes.TABS_WIDGET];
      const tabs = tabWidget.tabs;
      if (tabs && Array.isArray(tabs)) {
        tabWidget.tabs = tabs.map((tab) => {
          tab.widgetId = widgetIdMap[tab.widgetId];
          return tab;
        });
      }
    } catch (error) {
      log.debug("Error updating tabs");
      log.debug(error);
    }
  }
};

export const updatePastedTableWidget = (
  widget: Omit<WidgetProps, "children">,
  isRename: boolean,
  newWidgetName: string,
) => {
  if (widget.type === WidgetTypes.TABLE_WIDGET && isRename) {
    const tableWidget =
      widget as unknown as WidgetTypeToPropType[WidgetTypes.TABLE_WIDGET];
    try {
      const oldWidgetName = widget.widgetName;

      // If the primaryColumns of the table exist
      if (tableWidget.primaryColumns) {
        // For each column
        for (const [columnId, column] of Object.entries(
          tableWidget.primaryColumns,
        )) {
          // For each property in the column
          for (const [rawKey, value] of Object.entries(column)) {
            // Replace reference of previous widget with the new widgetName
            // This handles binding scenarios like `{{Table2.tableData.map((currentRow) => (currentRow.id))}}`
            const key = rawKey as keyof ColumnProperties;
            tableWidget.primaryColumns[columnId][key] = (
              isString(value)
                ? value.replace(`${oldWidgetName}.`, `${newWidgetName}.`)
                : value
            ) as never;
          }
        }
      }
      // also update any CACHED settings, in case they are used
      if (tableWidget.cachedColumnSettings) {
        for (const [columnId, column] of Object.entries(
          tableWidget.cachedColumnSettings,
        )) {
          for (const [rawKey, value] of Object.entries(column)) {
            const key = rawKey as keyof ColumnProperties;
            tableWidget.cachedColumnSettings[columnId][key] = (
              isString(value)
                ? value.replace(`${oldWidgetName}.`, `${newWidgetName}.`)
                : value
            ) as never;
          }
        }
      }
    } catch (error) {
      log.debug("Error updating table component properties");
      log.debug(error);
    }
  }
};

function* flashPastedWidget(newWidgetsIds: string[]) {
  const newWidgetId = newWidgetsIds[newWidgetsIds.length - 1];
  const isIframeEnabled: boolean = yield select(
    selectFlagById,
    Flag.ENABLE_IFRAME,
  );
  if (!isIframeEnabled) {
    // Flash the newly pasted widget once the DSL is re-rendered
    // if timeout is smaller than 200 and copying > 1 widgets including container flash will not work
    setTimeout(() => flashElementById(newWidgetId), 200);
  } else {
    sendMessage({
      type: "flash-element",
      payload: { elementId: newWidgetId },
    });
  }

  yield put(selectWidgets(newWidgetsIds));
}

/*
  This function is used to generate a the state of the canvas widgets after a widget or widgets have been pasted.
*/
function* pasteWidgets({
  copiedWidgets,
  parentWidget,
  pasteAtCursor,
  mousePosition,
  insertionIndex,
  recalculateWidgetPositions,
}: {
  copiedWidgets: CopiedWidgets;
  parentWidget: CanvasWidgetsReduxState[string];
  pasteAtCursor?: boolean;
  mousePosition?: { x: number; y: number };
  insertionIndex?: number;
  recalculateWidgetPositions: boolean;
}) {
  const stateWidgets: ReturnType<typeof getWidgets> = yield select(getWidgets);
  // some props like parentRowSpace or parentColSpace is calculated in render time
  let widgets: WidgetMap = { ...stateWidgets };
  const widgetIdMap: Record<string, string> = {};

  const appMode: APP_MODE = yield select(getAppMode) ?? APP_MODE.PUBLISHED;
  const theme: ReturnType<typeof selectGeneratedTheme> =
    yield select(selectGeneratedTheme);
  const canvasScaleFactor: ReturnType<typeof getResponsiveCanvasScaleFactor> =
    yield select(getResponsiveCanvasScaleFactor);
  const widgetsRuntime: FlattenedWidgetLayoutMap = yield select(
    getFlattenedCanvasWidgets,
  );

  const flattenedWidgets: FlattenedWidgetLayoutMap = yield select(
    getFlattenedCanvasWidgets,
  );

  const parentWidgetRunTime = widgetsRuntime[parentWidget.widgetId];
  const parentCanvasRunTime =
    parentWidget.type === WidgetTypes.CANVAS_WIDGET
      ? // if you select one widget in container, parentWidget is container's inner canvas
        parentWidgetRunTime
      : // if you select nothing in the container, parentWidget is container, first children is canvas
        parentWidgetRunTime.children?.[0]
        ? widgetsRuntime[parentWidgetRunTime.children[0]]
        : parentWidgetRunTime;

  // We only try to cop
  const copyToMousePositionOnGrid =
    pasteAtCursor &&
    copiedWidgets.length === 1 &&
    mousePosition &&
    parentCanvasRunTime.type === WidgetTypes.CANVAS_WIDGET;

  let numInserted = 0; // only for pasting in stack

  // For tracking the new ids of the widgets that are pasted
  // at the top level
  const newRootWidgetIds: string[] = [];

  // For tracking new widget names, we need to keep track of names used across all copied widgets
  const entityNames: string[] = yield select(getAllEntityNames);
  const mutableEntityNames = [...entityNames];

  // loop to copy the copiedWidgets & descendants
  for (let i = 0; i < copiedWidgets.length; i++) {
    const copiedWidgetId = copiedWidgets[i].widgetId;
    // list is all descendants of current copied widget
    const copiedWidget = copiedWidgets[i].list.find(
      (widget) => widget.widgetId === copiedWidgetId,
    );
    if (copiedWidget) {
      // Log the paste event
      AnalyticsUtil.logEvent("WIDGET_PASTE", {
        widgetName: copiedWidget.widgetName,
        widgetType: copiedWidget.type,
      });
      let position: ReturnType<typeof calculateNewWidgetPosition> | undefined;
      if (recalculateWidgetPositions) {
        const occupiedSpaces: OccupiedSpace[] | undefined = yield select(
          getOccupiedSpacesSelectorForContainer(parentWidget.widgetId),
        );
        const dropTarget: getDropTargetResultType = yield call(getDropTarget, {
          parentId: parentWidget.widgetId,
        });
        // Compute the new widget's positional properties
        position = copyToMousePositionOnGrid
          ? calculateNewWidgetPositionOnMousePosition({
              widget: copiedWidget as WidgetPropsRuntime &
                Partial<WidgetConfigProps>,
              dropTarget,
              canvasWidgets:
                widgetsRuntime as unknown as CanvasWidgetsReduxState, // todo (layouts): fix types
              canvasScaleFactor,
              mousePosition: mousePosition,
              parent: parentCanvasRunTime,
              occupiedSpaces,
            })
          : calculateNewWidgetPosition({
              widget: copiedWidget,
              parentId: parentWidget.widgetId,
              canvasWidgets: widgets,
            });
      }

      // Get a flat list of all the widgets to be updated
      const widgetList = copiedWidgets[i].list;
      const newWidgetList: FlattenedWidgetProps[] = [];
      // Generate new widgetIds for the flat list of all the widgets to be updated
      widgetList.forEach((widget, widgetListIndex) => {
        // Create a copy of the widget properties
        const newWidget = fastClone(widget);
        newWidget.widgetId = generateReactKey();
        // Add the new widget id so that it maps the previous widget id
        widgetIdMap[widget.widgetId] = newWidget.widgetId;
        // Add the new widget to the list
        newWidgetList.push(newWidget);

        if (widgetListIndex === 0) {
          newRootWidgetIds.push(newWidget.widgetId);
        }
      });

      // For each of the new widgets generated
      for (let j = 0; j < newWidgetList.length; j++) {
        const widget = newWidgetList[j];

        // If a widget by the same name exists in the canvas, or is part of the new widget names we've generated, give it a new name
        const isRenameWidget = mutableEntityNames.includes(widget.widgetName);
        const widgetConfig: ReturnType<typeof getWidgetBlueprint> =
          yield select(getWidgetBlueprint, widget.type);
        const newWidgetName = getNextEntityName(
          widgetConfig.widgetName,
          mutableEntityNames,
        );
        mutableEntityNames.push(
          isRenameWidget ? newWidgetName : widget.widgetName,
        );

        // Update the children widgetIds if it has children
        if (widget.children && widget.children.length > 0) {
          widget.children.forEach((childWidgetId: string, index: number) => {
            if (widget.children) {
              widget.children[index] = widgetIdMap[childWidgetId];
            }
          });
        }

        // Update the tabs for the tabs widget.
        updatePastedTabsWidget(widget, widgetIdMap);
        // Update the table widget column properties
        updatePastedTableWidget(widget, isRenameWidget, newWidgetName);

        // If it is the copied widget, update position properties
        if (widget.widgetId === widgetIdMap[copiedWidget.widgetId]) {
          if (recalculateWidgetPositions && position) {
            widget.left = position.left;
            widget.top = position.top;
            widget.width = position.width;
            widget.height = position.height;
          }

          // widget.parentId = newWidgetParentId;
          widget.parentId = parentWidget.widgetId;

          // Also, update the parent widget in the canvas widgets
          // to include this new copied widget's id in the parent's children

          // by default, solo child
          let parentChildren = [widget.widgetId];
          if (
            widgets[parentWidget.widgetId]?.children &&
            Array.isArray(widgets[parentWidget.widgetId].children)
          ) {
            if (insertionIndex == null) {
              // Add the new child to the end of existing children
              parentChildren = (
                widgets[parentWidget.widgetId].children ?? []
              ).concat([widget.widgetId]);
            } else {
              // then we are inserting into a vstack
              parentChildren = [
                ...(widgets[parentWidget.widgetId].children ?? []),
              ];
              parentChildren.splice(
                insertionIndex + numInserted,
                0,
                widget.widgetId,
              );
              numInserted++;
            }
          }
          widgets = {
            ...widgets,
            [parentWidget.widgetId]: {
              ...widgets[parentWidget.widgetId],
              children: parentChildren,
            },
          };
          if (recalculateWidgetPositions) {
            // If the copied widget's boundaries exceed the parent's
            // Make the parent scrollable
            const parent = widgets[parentWidget.widgetId];
            if (
              Dimension.add(parent.top, dimensionToGridRows(parent.height))
                .value <=
                Dimension.add(widget.top, dimensionToGridRows(widget.height))
                  .value &&
              widget.parentId !== PAGE_WIDGET_ID
            ) {
              parent.height = Dimension.gridUnit(
                Dimension.add(widget.top, dimensionToGridRows(widget.height))
                  .value,
              );
              parent.canExtend = true;
              widgets[parent.widgetId] = parent;

              const parentsParent = fastClone(widgets[parent.parentId]);
              parentsParent.shouldScrollContents = true;
              widgets[parentsParent.widgetId] = parentsParent;
            }
          }
        } else {
          // For all other widgets in the list
          // (These widgets will be descendants of the copied widget)
          // This means, that their parents will also be newly copied widgets
          // Update widget's parent widget ids with the new parent widget ids

          const newParentId = newWidgetList.find(
            (newWidget) => newWidget.widgetId === widgetIdMap[widget.parentId],
          )?.widgetId;
          if (newParentId) widget.parentId = newParentId;
        }

        if (isRenameWidget) {
          widget.widgetName = newWidgetName;
        }

        // Add the new widget to the canvas widgets
        widgets[widget.widgetId] = widget;
      }

      if (recalculateWidgetPositions && position) {
        const grandParent = widgets[parentWidget.parentId];
        if (
          grandParent &&
          parentWidget.type === WidgetTypes.CANVAS_WIDGET &&
          grandParent.type === WidgetTypes.SECTION_WIDGET
        ) {
          updateSectionWidgetCanvasHeights(
            widgets,
            theme,
            appMode,
            grandParent,
          );
        }

        // if parent is main canvas, we need to extend the bottom
        const topCanvasId: string = yield select(getMainContainerWidgetId);
        if (
          parentWidget.widgetId === topCanvasId &&
          widgets[topCanvasId] &&
          position.height.value + 1 > widgets[topCanvasId].height.value
        ) {
          widgets[topCanvasId].height = Dimension.add(
            dimensionToGridRows(position.height),
            Dimension.gridUnit(2),
          ).asFirst();
        }
      }
    }
  }

  // If the parent is an H-stack, we need to resize down the children to fit
  shrinkWidgetsInHstack({
    parentWidget,
    parentWidgetRunTime: parentCanvasRunTime,
    widgets,
    flattenedWidgets,
    childrenIds: newRootWidgetIds,
  });

  const newWidgetsIds = copiedWidgets.map(
    (copiedWidget) => widgetIdMap[copiedWidget.widgetId],
  );

  return {
    widgets,
    newWidgetsIds,
    parentCanvasRunTime,
  };
}

export function shrinkWidgetsInHstack({
  parentWidget,
  parentWidgetRunTime,
  widgets,
  flattenedWidgets,
  childrenIds,
  useFullWidth,
}: {
  parentWidget: CanvasWidgetsReduxState[string];
  parentWidgetRunTime: FlattenedWidgetLayoutProps;
  widgets: CanvasWidgetsReduxState;
  flattenedWidgets: FlattenedWidgetLayoutMap;
  childrenIds: string[];
  useFullWidth?: boolean;
}) {
  if (
    parentWidget.type === WidgetTypes.CANVAS_WIDGET &&
    parentWidget.layout === CanvasLayout.HSTACK
  ) {
    const parentColumnSpace = parentWidgetRunTime.parentColumnSpace || 1;

    // parentWidget.width is gridUnit but 0 due to some old canvases only relying on gridColumns
    // this discrepancy is resolved by recalculateWidgetsLayout in size.ts though, so we can
    // just use the flattenedWidgets width here
    const parentRemainingWidth = useFullWidth
      ? Dimension.toPx(
          flattenedWidgets[parentWidget.widgetId].width,
          parentColumnSpace,
        ).value
      : getHstackCanvasRemainingWidthPx(
          parentWidgetRunTime as unknown as FlattenedWidgetProps, // todo (layouts): fix types
          widgets,
        );

    let newWidgetMaxWidthPx = parentRemainingWidth / childrenIds.length;

    if (parentRemainingWidth <= 0) {
      newWidgetMaxWidthPx =
        CanvasDefaults.MIN_GRID_UNIT_WIDTH * parentColumnSpace;
    }
    childrenIds.forEach((widgetId) => {
      // Update the width
      if (
        WIDGET_CAN_HAVE_CHILDREN.includes(widgets[widgetId].type as WidgetTypes)
      ) {
        const widgetWidthPx = Dimension.toPx(
          widgets[widgetId].width,
          parentColumnSpace,
        );

        const widgetNewWidthPx = Math.min(
          widgetWidthPx.value,
          newWidgetMaxWidthPx,
        );

        const widthDiffGU = Dimension.toGridUnit(
          Dimension.px(widgetNewWidthPx - widgetWidthPx.value),
          parentColumnSpace,
        ).raw();
        if (widthDiffGU.value < 0) {
          updateWidgetWidths({
            widgets,
            flattenedWidgets,
            widget: widgets[widgetId],
            widthDiffGU: widthDiffGU.value,
          });
        }
      }
      // Don't update width if is dynamic
      else if (!isDynamicSize(widgets[widgetId].width.mode)) {
        let newWidth: Dimension<WidgetWidthModes>;
        const widgetWidthPx = Dimension.toPx(
          widgets[widgetId].width,
          parentColumnSpace,
        );

        const newWidgetWidthPx = Math.min(
          newWidgetMaxWidthPx,
          widgetWidthPx.value,
        );
        const currentWidthPx = Dimension.toPx(
          widgets[widgetId].width,
          parentColumnSpace,
        ).value;
        if (newWidgetWidthPx < currentWidthPx) {
          if (widgets[widgetId].width.mode === "px") {
            newWidth = Dimension.px(newWidgetWidthPx);
          } else {
            newWidth = Dimension.toGridUnit(
              Dimension.px(newWidgetWidthPx),
              parentColumnSpace,
            ).roundUp();
          }

          widgets[widgetId] = {
            ...widgets[widgetId],
            width: newWidth,
          };
        }
      }
    });
  }
}

export function* pasteWidgetRoot(params: {
  copiedWidgets: CopiedWidgets;
  pasteParentWidget: CanvasWidgetsReduxState[string];
  sectionInsertionPosition?: number;
  columnInsertionPosition?: number;
  stackInsertionPosition?: number;
  mousePosition?: {
    x: number;
    y: number;
  };
  pasteAtCursor?: boolean;
}): Generator<any, any, any> {
  const {
    copiedWidgets,
    pasteParentWidget,
    sectionInsertionPosition,
    columnInsertionPosition,
    stackInsertionPosition,
    mousePosition,
    pasteAtCursor,
  } = params;

  // Don't try to paste if there is no copied widget
  if (!copiedWidgets || !copiedWidgets.length) return;

  const copiedWidgetsType = copiedWidgets?.[0]?.type;

  let insertionIndexToUse: number | undefined = undefined;
  switch (copiedWidgetsType) {
    case WidgetTypes.MODAL_WIDGET:
    case WidgetTypes.SLIDEOUT_WIDGET:
      insertionIndexToUse = pasteParentWidget?.children?.length ?? 0;
      break;
    case WidgetTypes.SECTION_WIDGET:
      insertionIndexToUse = sectionInsertionPosition;
      break;
    case WidgetTypes.CANVAS_WIDGET:
      insertionIndexToUse = columnInsertionPosition;
      break;
    default:
      insertionIndexToUse = stackInsertionPosition;
  }

  // ---------------------
  // Perform the paste operation, then afterwards handle the
  // work required after paste depending on what was pasted
  // ---------------------

  // Only recalculate when pasting widgets onto a canvas
  const skipRecalculatePositionTypes = [
    WidgetTypes.MODAL_WIDGET,
    WidgetTypes.SLIDEOUT_WIDGET,
    WidgetTypes.SECTION_WIDGET,
    WidgetTypes.CANVAS_WIDGET,
  ];
  const skipPasteAtCursorTypes = [
    WidgetTypes.MODAL_WIDGET,
    WidgetTypes.SLIDEOUT_WIDGET,
  ];
  const {
    widgets,
    newWidgetsIds,
    parentCanvasRunTime,
  }: GeneratorReturnType<typeof pasteWidgets> = yield call(pasteWidgets, {
    copiedWidgets,
    parentWidget: pasteParentWidget,
    pasteAtCursor: skipPasteAtCursorTypes.includes(
      copiedWidgetsType as WidgetTypes,
    )
      ? false
      : pasteAtCursor,
    insertionIndex: insertionIndexToUse,
    mousePosition: mousePosition,
    recalculateWidgetPositions: !skipRecalculatePositionTypes.includes(
      copiedWidgetsType as WidgetTypes,
    ),
  });

  // ---------------------
  // Handle post paste required actions
  // ---------------------
  switch (copiedWidgetsType) {
    case WidgetTypes.MODAL_WIDGET:
    case WidgetTypes.SLIDEOUT_WIDGET: {
      yield put(updateLayout(widgets));
      yield call(flashPastedWidget, newWidgetsIds);
      yield put(showModal(newWidgetsIds[0]));
      break;
    }
    case WidgetTypes.SECTION_WIDGET: {
      yield put(updateLayout(widgets));
      yield call(flashPastedWidget, newWidgetsIds);
      break;
    }
    case WidgetTypes.CANVAS_WIDGET: {
      // update the section column widths
      const sectionWidget = widgets[pasteParentWidget.widgetId];
      const originalColumnWidths = (sectionWidget.children ?? []).map(
        (childId) => {
          const child = widgets[childId];
          return (
            (child.gridColumns || SectionDefaults.MIN_COLUMN_GRID_COLUMNS) /
            SectionDefaults.MIN_COLUMN_GRID_COLUMNS
          );
        },
      );
      // not enough room to paste the columns
      if (originalColumnWidths.every((width) => width === 1)) {
        throw new Error(
          "There is not enough space to paste a column in this section",
        );
      }
      const sectionParentWidget = widgets[sectionWidget.parentId];
      const maxSectionColumnCount = getSectionColsForParentType(
        sectionParentWidget.type,
      );
      const adjustedColumnWidths = adjustColumnWidths(
        originalColumnWidths,
        maxSectionColumnCount,
      );

      // adjust the widths of the columns and then save the DSL (saving is done by resize section columns action)
      yield put(
        resizeSectionWidgetColumns(
          pasteParentWidget.widgetId,
          adjustedColumnWidths,
          widgets, // pass in these widgets instead of using the state widgets
        ),
      );
      yield call(flashPastedWidget, newWidgetsIds);
      break;
    }
    default: {
      if (
        !pasteParentWidget ||
        pasteParentWidget.type !== WidgetTypes.CANVAS_WIDGET
      ) {
        throw new Error(
          "Parent widget not found or is not a canvas, cannot paste",
        );
      }
      // Update the width modes of the new children to ensure they're the right modes allowed
      // for the given parent canvas layout
      const dynamicWidgetLayout: ReturnType<typeof getDynamicLayoutWidgets> =
        yield select(getDynamicLayoutWidgets);

      const pasteParent = widgets[widgets[newWidgetsIds[0]].parentId];

      updateChildrenSizeModesForParentLayout({
        widgets,
        children: newWidgetsIds,
        parentLayout: pasteParent.layout || CanvasLayout.FIXED,
        dynamicWidgetLayout,
        parentColumnSpace: parentCanvasRunTime.parentColumnSpace,
      });

      const flattenedWidgets = yield select(getFlattenedCanvasWidgets);

      // Ensure we fix any wrong widths
      updateWidgetWidths({
        widgets,
        flattenedWidgets,
        widget: pasteParent,
        widthDiffGU: 0,
        rootCallOptions: {
          forceCheckChildren: true,
        },
      });

      yield put(updateLayout(widgets));
      yield call(flashPastedWidget, newWidgetsIds);

      if (
        copiedWidgets.length === 1 &&
        ATTACHED_WIDGET_AND_CAN_HAVE_CHILDREN.includes(
          copiedWidgetsType as WidgetTypes,
        )
      ) {
        // Track the last pasted container widget
        setLastPastedSingleWidgetId(copiedWidgets[0].widgetId);
      }
    }
  }

  return newWidgetsIds;
}

export async function applyRefactoredNamesToCopiedWidgets(params: {
  renames: Array<[string, string]>;
  sourceWidgets: CopiedWidgets;
}): Promise<CopiedWidgets> {
  const { renames } = params;
  const sourceWidgets = fastClone(params.sourceWidgets);

  const widgets: Record<string, CopiedWidgets[number]["list"][number]> = {};

  const fakeTree = emptyDataTree();
  sourceWidgets.forEach((copiedWidget) => {
    copiedWidget.list.forEach((widget) => {
      widgets[widget.widgetId] = widget;
      fakeTree[ApplicationScope.PAGE][widget.widgetName] = {} as any;
    });
  });

  for (const [oldName, newName] of renames) {
    const updates: Array<PropertyUpdate> = await getUpdatesInWidgets({
      widgets,
      oldName,
      newName,
      dataTree: fakeTree[ApplicationScope.PAGE],
      extractPairs: extractJsEvaluationPairs,
    });

    updates.forEach((update) => {
      const { entityId, propertyName, propertyValue } = update;
      const widget = widgets[entityId];
      if (widget) {
        set(widget, propertyName, propertyValue);
      }
    });
  }

  return sourceWidgets;
}

/**
 * Checks if all copied widgets are of the same type and optionally validates against a list of allowed types.
 *
 * @param widgets - The list of copied widgets to check.
 * @param validateTypes - Optional list of widget types to validate against.
 * @returns {boolean} `true` if all copied widgets are of the same type and optionally match the allowed types, `false` otherwise.
 */
function allCopiedWidgetsOfSameType(
  widgets: CopiedWidgets,
  validateTypes?: WidgetType[],
) {
  if (widgets.length === 0) {
    return false;
  }

  const widgetType = widgets[0].type;

  if (validateTypes && !validateTypes.includes(widgetType)) {
    return false;
  }

  return widgets.every((widget) => widget.type === widgetType);
}

/**
 * Finds the nearest parent widget of the same type as the copied widgets.
 * E.g: when copying a section, find the nearest parent section.
 *
 * @param params - The parameters for finding the parent widget.
 * @param params.pasteTargetId - The ID of the target widget where the paste action is initiated.
 * @param params.widgets - The current state of all widgets in the canvas.
 * @param params.copiedWidgets - The list of copied widgets.
 * @param params.validateTypes - Optional list of widget types to validate against.
 * @param params.validateEmpty - Optional flag to validate if the parent widget is empty.
 * @param params.validateCount - Optional count to validate the number of copied widgets.
 * @returns {FlattenedWidgetProps | null} The nearest parent widget of the same type, or null if not found.
 */
function getParentWidgetOfSameType(params: {
  pasteTargetId: string;
  widgets: CanvasWidgetsReduxState;
  copiedWidgets: CopiedWidgets;
  validateTypes?: WidgetType[];
  validateEmpty?: boolean;
  validateCount?: number;
}): FlattenedWidgetProps | null {
  const {
    pasteTargetId,
    widgets,
    copiedWidgets,
    validateTypes,
    validateEmpty,
    validateCount,
  } = params;

  if (
    !pasteTargetId ||
    !copiedWidgets.length ||
    (validateCount && copiedWidgets.length !== validateCount)
  )
    return null;

  // Only consider this validation when copying all items of the same type
  if (!allCopiedWidgetsOfSameType(copiedWidgets, validateTypes)) return null;

  const widgetType = copiedWidgets[0].type;

  const nearestParentOfWidgetType = findFirstParent(
    pasteTargetId,
    widgets,
    (widget) => widget.type === widgetType,
  );

  if (
    !nearestParentOfWidgetType ||
    !widgets[nearestParentOfWidgetType.widgetId]
  )
    return null;

  if (!nearestParentOfWidgetType) return null;

  const isNearestParentOfTypeEmpty = isWidgetEmpty(
    widgets[nearestParentOfWidgetType.widgetId],
    widgets,
  );

  if (validateEmpty && !isNearestParentOfTypeEmpty) return null;

  return nearestParentOfWidgetType;
}

/**
 * Checks if a widget is empty or equal to a blueprint configuration.
 *
 * @param flattenedWidget - The widget to check.
 * @param widgets - The state of all widgets in the canvas.
 * @param flags - Optional feature flags to customize the widget generation.
 * @returns {boolean} - Returns true if the widget is empty or equal to the blueprint configuration, false otherwise.
 */
export const isWidgetEmptyOrEqualToBlueprint = (
  flattenedWidget: FlattenedWidgetProps,
  widgets: CanvasWidgetsReduxState,
  flags: Flags = {},
) => {
  if (
    isWidgetEmpty(flattenedWidget, widgets) ||
    isInDefaultState(flattenedWidget, widgets)
  ) {
    return true;
  }
  return false;
};

/**
 * Determines the widgets to delete and replace from a paste action.
 *
 * @param params - The parameters for the paste action.
 * @param params.pasteTargetId - The ID of the target widget where the paste action is performed.
 * @param params.widgets - The current state of all widgets on the canvas.
 * @param params.copiedWidgets - The widgets that were copied and are being pasted.
 * @param params.newWidgetIds - The IDs of the new widgets created from the paste action.
 *
 * @returns A tuple containing two arrays:
 *  - The first array contains objects representing the widgets to replace. Each object contains an
 * `oldWidget` that will the replaced by a `newWidget`.
 *  - The second array contains the widgets to delete.
 */
export const getWidgetsToDeleteAndReplaceFromPasteAction = (params: {
  pasteTargetId: string;
  widgets: CanvasWidgetsReduxState;
  copiedWidgets: CopiedWidgets;
  newWidgetIds: string[];
}): [
  { oldWidget: FlattenedWidgetProps; newWidget: FlattenedWidgetProps }[],
  FlattenedWidgetProps[],
] => {
  const { pasteTargetId, copiedWidgets, widgets, newWidgetIds } = params;

  if (!pasteTargetId || !copiedWidgets.length) return [[], []];

  const itemsToReplace: {
    oldWidget: FlattenedWidgetProps;
    newWidget: FlattenedWidgetProps;
  }[] = [];
  const itemsToDelete: FlattenedWidgetProps[] = [];

  if (!allCopiedWidgetsOfSameType(copiedWidgets)) {
    return [itemsToReplace, itemsToDelete];
  }

  if (copiedWidgets[0].type === WidgetTypes.SECTION_WIDGET) {
    const parentSectionWidget = getParentWidgetOfSameType({
      ...params,
      validateTypes: [WidgetTypes.SECTION_WIDGET],
      validateEmpty: true, // we will only replace the parent section if it is empty
    });

    if (parentSectionWidget) {
      itemsToDelete.push(parentSectionWidget);
    }
  }

  if (
    (params.copiedWidgets[0].type === WidgetTypes.MODAL_WIDGET ||
      params.copiedWidgets[0].type === WidgetTypes.SLIDEOUT_WIDGET) &&
    copiedWidgets.length === 1
  ) {
    const parentWidget = getParentWidgetOfSameType({
      ...params,
      validateTypes: [WidgetTypes.MODAL_WIDGET, WidgetTypes.SLIDEOUT_WIDGET],
      validateEmpty: false,
      validateCount: 1,
    });

    const copiedWidget = copiedWidgets[0].list.find(
      (widget) => widget.widgetId === copiedWidgets[0].widgetId,
    ) as FlattenedWidgetProps;

    if (parentWidget) {
      itemsToReplace.push({
        oldWidget: parentWidget,
        newWidget:
          newWidgetIds && newWidgetIds.length
            ? widgets[newWidgetIds[0]]
            : copiedWidget,
      });
    }
  }

  return [itemsToReplace, itemsToDelete];
};
