import {
  ApiTriggerType,
  ApplicationUserStatus,
  DefaultGroupName,
  EntityPermissionsDto,
  PermissionEntity,
  PermissionEntityType,
  PermissionedEntities,
  RbacRole,
  ResourceTypeEnum,
  RoleTypeEnum,
  ShareEntryDto,
  isGroupShareEntry,
  isUserShareEntry,
  snakeCaseToDisplay,
} from "@superblocksteam/shared";
import { GroupEntityToRender } from "pages/Groups/constants";

type PageType = "entities" | "integrations";

export const SHARE_MODAL_ROLE_SELECT_WIDTH = 110;

export const getResourceType = (entityType: PermissionEntityType) => {
  switch (entityType) {
    case "application":
      return PermissionedEntities.APPLICATION_V2;
    default:
      return PermissionedEntities.API_V3;
  }
};

export const isValidPageType = (
  pageType: string | undefined,
): pageType is PageType => {
  if (!pageType) return false;
  return ["entities", "integrations"].includes(pageType);
};

export enum RoleAndPermissionsPages {
  ORGANIZATION_ROLES = "organization-roles",
  RESOURCE_ROLES = "resource-roles",
  SETTINGS = "settings",
}

export const isValidRolesPageType = (
  pageType: string | undefined,
): pageType is PageType => {
  if (!pageType) return false;
  return Object.values(RoleAndPermissionsPages).includes(pageType as any);
};

export type EntityToRender = {
  id: string;
  name: string;
  type: PermissionEntityType;
  owner: string;
  ownerId: string;
  builders: string[];
  viewers?: string[];
  configurators?: string[];
};

const priority = (entry: ShareEntryDto) => {
  if (isGroupShareEntry(entry) && entry.name === DefaultGroupName.EVERYONE) {
    return 0;
  }
  if (
    isUserShareEntry(entry) &&
    entry.status === ApplicationUserStatus.EDITOR_PENDING_JOIN
  ) {
    return 2;
  }
  return 1;
};

const sortEntityPermissionEntries = (a: ShareEntryDto, b: ShareEntryDto) => {
  if (priority(a) === priority(b)) {
    return (a.name ?? "").localeCompare(b.name ?? "");
  }
  return priority(a) - priority(b);
};

export const convertToEntityToRender = (
  entity: EntityPermissionsDto,
): EntityToRender => {
  const owner = entity.permissions.find(
    (permission) => permission.role === "owner",
  );
  return {
    id: entity.entity.id,
    name: entity.entity.name,
    type: entity.entity.type,
    owner: owner?.name ?? "",
    ownerId: owner?.actorId ?? "",
    builders: entity.permissions
      .filter((permission) => permission.role === "builder")
      .sort(sortEntityPermissionEntries)
      .map((permission) => permission.name),
    viewers: entity.permissions
      .filter((permission) => permission.role === "viewer")
      .sort(sortEntityPermissionEntries)
      .map((permission) => permission.name),
    configurators: entity.permissions
      .filter((permission) => permission.role === "configurator")
      .sort(sortEntityPermissionEntries)
      .map((permission) => permission.name),
  };
};

// used by components outside permission page
export const getVersionedPermissionedEntityType = (
  resourceType: PermissionedEntities,
) => {
  switch (resourceType) {
    case PermissionedEntities.APPLICATION_V2:
    case PermissionedEntities.APPLICATION:
      return PermissionedEntities.APPLICATION_V2;
    case PermissionedEntities.DATASOURCE:
      return PermissionedEntities.DATASOURCE;
    case PermissionedEntities.INTEGRATION:
      return PermissionedEntities.INTEGRATION;
    default:
      return PermissionedEntities.API_V3;
  }
};

export const getPermissionedEntitiesType = (type: PermissionEntityType) => {
  switch (type) {
    case PermissionEntityType.APPLICATION:
      return PermissionedEntities.APPLICATION_V2;
    case PermissionEntityType.DATASOURCE:
      return PermissionedEntities.DATASOURCE;
    case PermissionEntityType.INTEGRATION:
      return PermissionedEntities.INTEGRATION;
    default:
      return PermissionedEntities.API_V3;
  }
};

export const getApiTriggerTypeFromPermissionEntity = (
  permissionEntity: PermissionEntity,
): ApiTriggerType | null => {
  switch (permissionEntity.type) {
    case PermissionEntityType.APPLICATION:
    case PermissionEntityType.APPLICATION_V2:
      return ApiTriggerType.UI;
    case PermissionEntityType.SCHEDULED_JOB:
      return ApiTriggerType.SCHEDULE;
    case PermissionEntityType.WORKFLOW:
      return ApiTriggerType.WORKFLOW;
    default:
      return null;
  }
};

type TypeLabel = "Application" | "Scheduled Job" | "Integration" | "Workflow";

export const getTypeLabel = (entityType: PermissionEntityType): TypeLabel => {
  switch (entityType) {
    case PermissionEntityType.APPLICATION:
    case PermissionEntityType.APPLICATION_V2:
      return "Application";
    case PermissionEntityType.SCHEDULED_JOB:
      return "Scheduled Job";
    case PermissionEntityType.WORKFLOW:
      return "Workflow";
    case PermissionEntityType.INTEGRATION:
    case PermissionEntityType.DATASOURCE:
      return "Integration";
  }
};

export const getAccessText = (role: RbacRole) => {
  let accessText = "can access";
  if (role === RbacRole.BUILDER) {
    accessText = "can build";
  } else if (role === RbacRole.VIEWER) {
    accessText = "can view";
  }
  return accessText;
};

export function entityCompareFn(
  entity1: EntityToRender | GroupEntityToRender,
  entity2: EntityToRender | GroupEntityToRender,
): number {
  const sortOrder = {
    [PermissionEntityType.APPLICATION]: 0,
    [PermissionEntityType.APPLICATION_V2]: 0,
    [PermissionEntityType.WORKFLOW]: 2,
    [PermissionEntityType.SCHEDULED_JOB]: 3,
    [PermissionEntityType.DATASOURCE]: 4,
    [PermissionEntityType.INTEGRATION]: 5,
  };

  const entity1Order = sortOrder[entity1.type] ?? Object.keys(sortOrder).length;
  const entity2Order = sortOrder[entity2.type] ?? Object.keys(sortOrder).length;
  const typeComp = entity1Order - entity2Order;
  if (typeComp !== 0) {
    return typeComp;
  }
  // TODO: we can remove this fallback check once we assure no entity without name will be returned
  return (entity1.name ?? "").localeCompare(entity2.name ?? "");
}

// by default, we just take the snake_case name and make it Display case,
// but sometimes we want a nicer name
export const USER_FRIENDLY_RESOURCE_NAMES: Record<string, string> = {
  [ResourceTypeEnum.APPLICATIONS]: "Applications",
  [ResourceTypeEnum.SCHEDULED_JOBS]: "Scheduled jobs",
  [ResourceTypeEnum.WORKFLOWS]: "Workflows",
  [ResourceTypeEnum.INTEGRATIONS]: "Integrations",
  [ResourceTypeEnum.ORGANIZATION]: "Organization",
  [ResourceTypeEnum.REPOSITORIES]: "Repositories",
  [ResourceTypeEnum.ORGANIZATION_USERS]: "Users",
  [ResourceTypeEnum.GROUPS_MEMBERS]: "Group Members",
  [ResourceTypeEnum.GROUPS]: "Groups",
  [ResourceTypeEnum.APPLICATIONS_APIS]: "Application APIs",
  [ResourceTypeEnum.APPLICATIONS_PAGES]: "Application pages",
  [ResourceTypeEnum.LOGS_STREAMS]: "Log Streams",
  [ResourceTypeEnum.LOGS]: "Logs",
  [ResourceTypeEnum.AGENTS]: "Agents",
  [ResourceTypeEnum.PROFILES]: "Profiles",
  [ResourceTypeEnum.ROLES]: "Roles",
  [ResourceTypeEnum.SECRETS_STORES]: "Secrets Stores",
  [ResourceTypeEnum.ACCESS_TOKENS]: "Access Tokens",
  [ResourceTypeEnum.FOLDERS]: "Folders",
};

export const USER_FRIENDLY_ROLE_NAMES: Record<RoleTypeEnum, string> = {
  [RoleTypeEnum.ORGANIZATION]: "Organization",
  [RoleTypeEnum.APPLICATIONS]: "Application",
  [RoleTypeEnum.WORKFLOWS]: "Workflow",
  [RoleTypeEnum.SCHEDULED_JOBS]: "Scheduled Job",
  [RoleTypeEnum.INTEGRATIONS]: "Integration",
  // [RoleTypeEnum.GROUPS]: "Group",
  // [RoleTypeEnum.SECRETS_STORES]: "Secret Store",
  // [RoleTypeEnum.PROFILES]: "Profile",
};

export enum ROLES_SECTION_HEADER {
  DEVELOPMENT = "Development",
  GOVERNANCE = "Governance",
}
// in
export const ROLE_NAME_TO_SECTION_HEADER: Record<string, string> = {
  // development
  [USER_FRIENDLY_RESOURCE_NAMES[ResourceTypeEnum.APPLICATIONS]]:
    ROLES_SECTION_HEADER.DEVELOPMENT,
  [USER_FRIENDLY_RESOURCE_NAMES[ResourceTypeEnum.FOLDERS]]:
    ROLES_SECTION_HEADER.DEVELOPMENT,
  [USER_FRIENDLY_RESOURCE_NAMES[ResourceTypeEnum.INTEGRATIONS]]:
    ROLES_SECTION_HEADER.DEVELOPMENT,
  [USER_FRIENDLY_RESOURCE_NAMES[ResourceTypeEnum.SCHEDULED_JOBS]]:
    ROLES_SECTION_HEADER.DEVELOPMENT,
  [USER_FRIENDLY_RESOURCE_NAMES[ResourceTypeEnum.WORKFLOWS]]:
    ROLES_SECTION_HEADER.DEVELOPMENT,
  [USER_FRIENDLY_RESOURCE_NAMES[ResourceTypeEnum.FOLDERS]]:
    ROLES_SECTION_HEADER.DEVELOPMENT,
  // governance
  [USER_FRIENDLY_RESOURCE_NAMES[ResourceTypeEnum.ACCESS_TOKENS]]:
    ROLES_SECTION_HEADER.GOVERNANCE,
  [USER_FRIENDLY_RESOURCE_NAMES[ResourceTypeEnum.AGENTS]]:
    ROLES_SECTION_HEADER.GOVERNANCE,
  [USER_FRIENDLY_RESOURCE_NAMES[ResourceTypeEnum.GROUPS]]:
    ROLES_SECTION_HEADER.GOVERNANCE,
  [USER_FRIENDLY_RESOURCE_NAMES[ResourceTypeEnum.LOGS]]:
    ROLES_SECTION_HEADER.GOVERNANCE,
  [USER_FRIENDLY_RESOURCE_NAMES[ResourceTypeEnum.ORGANIZATION]]:
    ROLES_SECTION_HEADER.GOVERNANCE,
  [USER_FRIENDLY_RESOURCE_NAMES[ResourceTypeEnum.PROFILES]]:
    ROLES_SECTION_HEADER.GOVERNANCE,
  [USER_FRIENDLY_RESOURCE_NAMES[ResourceTypeEnum.REPOSITORIES]]:
    ROLES_SECTION_HEADER.GOVERNANCE,
  [USER_FRIENDLY_RESOURCE_NAMES[ResourceTypeEnum.ROLES]]:
    ROLES_SECTION_HEADER.GOVERNANCE,
  [USER_FRIENDLY_RESOURCE_NAMES[ResourceTypeEnum.SECRETS_STORES]]:
    ROLES_SECTION_HEADER.GOVERNANCE,
  [USER_FRIENDLY_RESOURCE_NAMES[ResourceTypeEnum.ACCESS_TOKENS]]:
    ROLES_SECTION_HEADER.GOVERNANCE,
};

export type UserInvitee = {
  name: string;
  email: string;
  type: "user";
  id: string;
};

type GroupInvitee = {
  name: string;
  type: "group";
  id: string;
  size?: number;
};

export type Invitee = UserInvitee | GroupInvitee;

export type InviteeToSend = {
  type: "user" | "group";
  id: string;
  name: string;
};

export type ResourceToRender = {
  id: string;
  name: string;
  type: ResourceTypeEnum;
  roles: string[];
};

export const convertResourceTypeToRoleType = (
  resourceType: ResourceTypeEnum,
): RoleTypeEnum | null => {
  const mapping: Record<ResourceTypeEnum, RoleTypeEnum | null> = {
    [ResourceTypeEnum.ACCESS_TOKENS]: null,
    [ResourceTypeEnum.AGENTS]: null,
    [ResourceTypeEnum.APPLICATIONS]: RoleTypeEnum.APPLICATIONS,
    [ResourceTypeEnum.APPLICATIONS_APIS]: RoleTypeEnum.APPLICATIONS,
    [ResourceTypeEnum.APPLICATIONS_PAGES]: RoleTypeEnum.APPLICATIONS,
    [ResourceTypeEnum.FOLDERS]: null,
    [ResourceTypeEnum.GROUPS]: null,
    [ResourceTypeEnum.GROUPS_MEMBERS]: null,
    [ResourceTypeEnum.INTEGRATIONS]: RoleTypeEnum.INTEGRATIONS,
    [ResourceTypeEnum.LOGS]: null,
    [ResourceTypeEnum.LOGS_STREAMS]: null,
    [ResourceTypeEnum.ORGANIZATION]: RoleTypeEnum.ORGANIZATION,
    [ResourceTypeEnum.ORGANIZATION_USERS]: RoleTypeEnum.ORGANIZATION,
    [ResourceTypeEnum.PROFILES]: null,
    [ResourceTypeEnum.REPOSITORIES]: null,
    [ResourceTypeEnum.ROLES]: null,
    [ResourceTypeEnum.SCHEDULED_JOBS]: RoleTypeEnum.SCHEDULED_JOBS,
    [ResourceTypeEnum.SECRETS_STORES]: null,
    [ResourceTypeEnum.WORKFLOWS]: RoleTypeEnum.WORKFLOWS,
  };

  return mapping[resourceType] ?? null;
};

export const getResourceTypeDisplayName = (
  type: ResourceTypeEnum,
  single = true,
) => {
  const name =
    USER_FRIENDLY_RESOURCE_NAMES[type] ??
    snakeCaseToDisplay(type.replaceAll(".", "_"));

  if (name[name.length - 1] === "s" && single) {
    return name.slice(0, -1);
  }
};

export const sortRoleOrder: Record<string, number> = {
  Owner: 0,
  Admin: 1,
  Developer: 2,
  "End-User": 3,
  Max: 4,
};
