import { isGroupDeletable } from "@superblocksteam/shared";
import { Tooltip } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import TruncatedTextTooltip from "components/TruncatedTextTooltip";
import { UnderlinedLinkClass } from "components/ui/Link";
import { DeleteModal } from "components/ui/Modal";
import RecommendedTable, {
  ACTION_MENU_WIDTH,
  RecColumn,
  DEFAULT_TABLE_PAGE_SIZE,
} from "components/ui/RecommendedTable";
import { MANAGE_GROUPS, VIEW_GROUP_MEMBERS } from "constants/rbac";
import { useFeatureFlag } from "hooks/ui";
import { useAuthorizationCheck } from "hooks/ui/rbac/useAuthorizationCheck";
import { OrganizationRoleDropdown } from "pages/Permissions/RoleDropdown";
import { GROUP_PAGE_URL } from "pages/routes";
import { Flag } from "store/slices/featureFlags";
import { selectOnlyOrganizationId } from "store/slices/organizations";
import { styleAsClass } from "styles/styleAsClass";
import { sendSuccessUINotification } from "utils/notification";
import { deleteGroup } from "./client";
import { GroupToRender } from "./constants";

const NameStyle = styleAsClass`
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type ColType = RecColumn<GroupToRender>;

const GroupList = ({
  groups,
  setGroups,
  loading,
  totalCount,
  updateGroup,
}: {
  groups: GroupToRender[];
  setGroups: React.Dispatch<React.SetStateAction<GroupToRender[]>>;
  loading: boolean;
  totalCount: number;
  updateGroup: (groupId: string, updates: Partial<GroupToRender>) => void;
}) => {
  const navigate = useNavigate();

  const [groupToModify, setGroupToModify] = useState<GroupToRender | undefined>(
    undefined,
  );

  const rbacV2Enabled = useFeatureFlag(Flag.ENABLE_RBAC_V2_ENFORCEMENT);

  const [canManageGroups, canViewMembers] = useAuthorizationCheck([
    MANAGE_GROUPS,
    VIEW_GROUP_MEMBERS,
  ]);

  const canCheckDetails = canViewMembers || canManageGroups;

  const removeTooltip = useCallback(
    (group: GroupToRender) => {
      const isDeletable = rbacV2Enabled ? true : isGroupDeletable(group.type);
      if (!isDeletable || !canManageGroups) {
        return `Cannot delete ${group.name} group`;
      }
    },
    [canManageGroups, rbacV2Enabled],
  );

  const roleAssignmentsEnabled = useFeatureFlag(
    Flag.ENABLE_RBAC_ROLE_ASSIGNMENTS,
  );
  const onRoleChanged = useCallback(
    (groupId: string, updates: Record<string, string | null>) => {
      updateGroup(groupId, updates);
    },
    [updateGroup],
  );

  const renderRole = useCallback(
    ({
      value,
      row,
    }: {
      value: string;
      row: { original: Record<string, any> };
    }) => {
      return (
        <div style={{ marginLeft: "-12px" }}>
          <Tooltip
            title={
              !canManageGroups
                ? "You do not have permission to assign roles"
                : ""
            }
          >
            <OrganizationRoleDropdown
              currentRole={value}
              currentRoleName={row.original.roleName}
              principalId={row.original.id}
              principalType="group"
              principalName={row.original.name}
              onRoleChanged={onRoleChanged}
              isClearable={true}
              isDisabled={!canManageGroups}
              currentRoleAssignmentId={row.original.roleAssignmentId}
            />
          </Tooltip>
        </div>
      );
    },
    [onRoleChanged, canManageGroups],
  );

  const onNameClick = useCallback(
    (group: GroupToRender) => {
      if (canViewMembers) {
        navigate({ pathname: GROUP_PAGE_URL(group.id, "users") });
      } else if (canManageGroups) {
        navigate({ pathname: GROUP_PAGE_URL(group.id, "permissions") });
      }
    },
    [canViewMembers, canManageGroups, navigate],
  );

  const columns: ColType[] = useMemo(() => {
    const numColumns = roleAssignmentsEnabled ? 3 : 2;
    const width = `calc(100% / ${numColumns} - ${
      ACTION_MENU_WIDTH / numColumns
    }px)`;
    return [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value, cell }) => {
          return (
            <TruncatedTextTooltip
              dataTest={`group-item-${value}`}
              text={value}
              onClick={
                canCheckDetails
                  ? () => onNameClick(cell.row.original)
                  : undefined
              }
              className={`${NameStyle} ${
                canCheckDetails ? UnderlinedLinkClass : ""
              }`}
            />
          );
        },
        width,
      },
      {
        Header: "Members",
        accessor: "memberCount",
        width,
      },
      ...(roleAssignmentsEnabled
        ? ([
            {
              Header: "Role",
              accessor: "roleId",
              Cell: renderRole,
              width,
            },
          ] as ColType[])
        : []),
    ];
  }, [roleAssignmentsEnabled, renderRole, canCheckDetails, onNameClick]);

  const menuItems = useCallback(
    (group: GroupToRender) => {
      const tooltip = removeTooltip(group);
      return [
        {
          key: "view-details",
          label: "View details",
          onClick: () => {
            navigate({ pathname: GROUP_PAGE_URL(group.id, "users") });
          },
          disabled: !canCheckDetails,
          tooltip: !canCheckDetails
            ? "You do not have permission to view details of this group"
            : undefined,
        },
        {
          key: "copy-group",
          label: "Copy group ID",
          onClick: () => {
            setGroupToModify(group);
            navigator.clipboard.writeText(group.id);
          },
          "data-test": "copy-group-id-button",
        },

        {
          key: "remove-group",
          label: (
            <Tooltip title={removeTooltip(group)} placement="left">
              <div>Remove group</div>
            </Tooltip>
          ),
          onClick: () => {
            setGroupToModify(group);
            setDeleteModalOpen(true);
          },
          "data-test": `delete-group-button-${group.name}`,
          disabled: Boolean(tooltip),
        },
      ];
    },
    [canCheckDetails, navigate, removeTooltip],
  );

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const orgId = useSelector(selectOnlyOrganizationId);

  const [deleteError, setDeleteError] = useState<string>();
  const [isDeleting, setIsDeleting] = useState(false);
  const onDelete = useCallback(async () => {
    if (!groupToModify || !orgId) {
      return;
    }
    setIsDeleting(true);
    try {
      const { error } = await deleteGroup(orgId, groupToModify.id);
      if (error) {
        setDeleteError(error ?? "Failed to delete group");
      } else {
        setGroups((groups) => groups.filter((g) => g.id !== groupToModify.id));
        setGroupToModify(undefined);
        setDeleteModalOpen(false);
        sendSuccessUINotification({
          message: `Deleted "${groupToModify.name}" group`,
        });
      }
    } catch (e: any) {
      setDeleteError(e?.message ?? "Failed to delete group");
    }
    setIsDeleting(false);
  }, [groupToModify, orgId, setGroups]);

  const onCancel = useCallback(() => {
    setDeleteModalOpen(false);
    setGroupToModify(undefined);
  }, []);

  return (
    <>
      <RecommendedTable<GroupToRender>
        data={groups}
        dataLabel="groups"
        uniqueKey="name"
        columns={columns}
        actionMenuItems={menuItems}
        // using totalCount to avoid frequent update when search which could cause crash
        paginationOptions={
          totalCount > DEFAULT_TABLE_PAGE_SIZE
            ? { pageSize: DEFAULT_TABLE_PAGE_SIZE }
            : undefined
        }
        loading={loading}
        useFixedColumnWidths={true}
      />
      <DeleteModal
        open={deleteModalOpen}
        title={"Remove group"}
        onCancel={onCancel}
        onDelete={onDelete}
        error={deleteError}
        isDeleting={isDeleting}
        dataTestName={`group-${groupToModify?.name}`}
        confirmText="Remove"
      >
        Group <b>{groupToModify?.name}</b> will be removed from your
        organization.
      </DeleteModal>
    </>
  );
};

export default GroupList;
