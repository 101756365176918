import {
  GroupDetail,
  GroupType,
  PrincipalTypeEnum,
  ResourceTypeEnum,
} from "@superblocksteam/shared";
import { Tabs, Tooltip } from "antd";
import React, { useCallback, useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { useNavigate, useParams } from "react-router";
import { Layout, MainWrapper } from "components/app";
import { HeaderWrapper, MainWrapperStyles } from "components/ui/Page";
import { MANAGE_GROUPS, VIEW_GROUP_MEMBERS } from "constants/rbac";
import { useFeatureFlag } from "hooks/ui";
import { useAuthorizationCheck } from "hooks/ui/rbac/useAuthorizationCheck";
import { getCurrentUser } from "legacy/selectors/usersSelectors";
import ResourceList from "pages/Permissions/ResourceList";
import Header from "pages/components/Header";
import { PageNav } from "pages/components/PageNav";
import { PageWrapper } from "pages/components/PageWrapper";
import { BreadCrumb } from "pages/components/navigation/BreadCrumb";
import { GROUP_PAGE_URL } from "pages/routes";
import { Flag } from "store/slices/featureFlags";
import { selectOnlyOrganizationId } from "store/slices/organizations";
import { useListUserOrGroupAssignmentsQuery } from "store/slices/reduxApi/rbac";
import { truncate } from "utils/string";
import GroupEntityList from "./GroupEntityList";
import MemberList from "./MemberList";
import { getGroup } from "./client";
import { isValidPageType, isValidPageTypeV2 } from "./constants";

const GroupDetailPage = () => {
  const params = useParams();
  const groupId = params.groupId;
  const pageType = isValidPageType(params.pageType) ? params.pageType : "users";
  const pageTypeV2 = isValidPageTypeV2(params.pageType)
    ? params.pageType
    : "users";

  const currentUser = useSelector(getCurrentUser);
  const rbacV2Enabled = useFeatureFlag(Flag.ENABLE_RBAC_V2_ENFORCEMENT);

  const navigate = useNavigate();

  const [canReadMembers, canManageGroups] = useAuthorizationCheck([
    VIEW_GROUP_MEMBERS,
    MANAGE_GROUPS,
  ]);

  useEffect(() => {
    if (rbacV2Enabled) {
      if (pageTypeV2 === "users" && !canReadMembers && canManageGroups) {
        navigate(GROUP_PAGE_URL(groupId, "permissions"));
      }

      if (pageTypeV2 === "permissions" && !canManageGroups && canReadMembers) {
        navigate(GROUP_PAGE_URL(groupId, "users"));
      }

      if (!canReadMembers && !canManageGroups) {
        navigate("/groups");
      }
    }
  }, [
    rbacV2Enabled,
    canManageGroups,
    navigate,
    groupId,
    pageTypeV2,
    canReadMembers,
  ]);

  const orgId = useSelector(selectOnlyOrganizationId);
  const [groupsLoading, setGroupsLoading] = useState(false);

  const [group, setGroup] = useState<GroupDetail>();

  useEffect(() => {
    async function loadGroup() {
      if (!orgId || !groupId) return;
      setGroupsLoading(true);
      const { group } = await getGroup(orgId, groupId);
      if (group) {
        setGroup(group);
      }
      setGroupsLoading(false);
    }

    loadGroup();
  }, [orgId, groupId]);

  const title = truncate(group?.name ?? "");

  const handleTabChange = useCallback(
    (key: string) => {
      navigate({ pathname: GROUP_PAGE_URL(groupId, key) });
    },
    [groupId, navigate],
  );

  const organizationId = useSelector(selectOnlyOrganizationId);

  const { data: assignments } = useListUserOrGroupAssignmentsQuery({
    isUser: true,
    principalId: groupId ?? "",
    organizationId: organizationId ?? "",
  });

  const groupOrgRole = assignments?.find(
    (assignment) =>
      assignment.resourceType === ResourceTypeEnum.ORGANIZATION &&
      assignment.principalType === PrincipalTypeEnum.GROUP,
  )?.role;

  return (
    <PageWrapper pageName="Groups">
      <Layout Header={<Header />} Sider={<PageNav />}>
        <MainWrapper className={MainWrapperStyles}>
          <BreadCrumb
            paths={[
              {
                title: "Groups",
                link: "/groups",
              },
              { title },
            ]}
          />

          <div className={HeaderWrapper}>
            <div className="page-header-title"> {group?.name ?? "Group"} </div>
          </div>
          {!rbacV2Enabled ? (
            currentUser?.isAdmin ? (
              <Tabs activeKey={pageType} onTabClick={handleTabChange}>
                <Tabs.TabPane
                  tab={
                    <span data-test="permissions-groups-users-tab">{`Members`}</span>
                  }
                  key="users"
                >
                  <MemberList groupLoading={groupsLoading} group={group} />
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={
                    <Tooltip
                      title={
                        group?.type === GroupType.ADMIN
                          ? "Admins have full permissions to all Apps, Workflows & Jobs"
                          : undefined
                      }
                    >
                      <span data-test="permissions-groups-entities-tab">{`Apps, Workflows & Jobs`}</span>
                    </Tooltip>
                  }
                  disabled={group?.type === GroupType.ADMIN}
                  key="entities"
                >
                  {groupId && group && (
                    <GroupEntityList
                      groupId={groupId}
                      groupName={group.name}
                      isIntegration={false}
                    />
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={
                    <Tooltip
                      title={
                        group?.type === GroupType.ADMIN
                          ? "Admins have full permissions to all Integrations"
                          : undefined
                      }
                    >
                      <span data-test="permissions-groups-integrations-tab">{`Integrations`}</span>
                    </Tooltip>
                  }
                  key="integrations"
                  disabled={group?.type === GroupType.ADMIN}
                >
                  {groupId && group && (
                    <GroupEntityList
                      groupId={groupId}
                      groupName={group.name}
                      isIntegration={true}
                    />
                  )}
                </Tabs.TabPane>
              </Tabs>
            ) : (
              <MemberList groupLoading={groupsLoading} group={group} />
            )
          ) : (
            <Tabs activeKey={pageTypeV2} onTabClick={handleTabChange}>
              <Tabs.TabPane
                tab={
                  <Tooltip
                    title={
                      !canReadMembers
                        ? "You don't have permission to view members"
                        : undefined
                    }
                  >
                    <span data-test="member-list"> Members </span>
                  </Tooltip>
                }
                key="users"
                disabled={!canReadMembers}
              >
                <MemberList groupLoading={groupsLoading} group={group} />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <Tooltip
                    title={
                      !canManageGroups
                        ? "You don't have permission to manage the group"
                        : undefined
                    }
                  >
                    <span data-test="user-detail-permissions">Permissions</span>
                  </Tooltip>
                }
                key="permissions"
                disabled={!canManageGroups}
              >
                {groupId && (
                  <ResourceList
                    principalId={groupId}
                    principalType={PrincipalTypeEnum.GROUP}
                    principalName={group?.name ?? ""}
                    roleName={groupOrgRole?.name ?? ""}
                  />
                )}
              </Tabs.TabPane>
            </Tabs>
          )}
        </MainWrapper>
      </Layout>
    </PageWrapper>
  );
};

export default GroupDetailPage;
