import {
  OrganizationUserDto,
  OrganizationUserStatus,
} from "@superblocksteam/shared";
import { Input, Modal } from "antd";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ReactComponent as WarningIcon } from "assets/icons/common/system-danger.svg";
import { PrimaryButton, SecondaryButton } from "components/ui/Button";
import { FormItem, FormWrapper } from "components/ui/Form";
import {
  FooterWrapperWide,
  ModalInnerWrapper,
  ModalWrapClass,
} from "components/ui/Modal";
import { getCurrentUser } from "legacy/selectors/usersSelectors";
import { Invitee, UserInvitee } from "pages/Permissions/constants";
import SearchAndInviteV2 from "pages/components/SearchAndInviteV2";
import { GROUP_PAGE_URL } from "pages/routes";
import { selectOnlyOrganizationId } from "store/slices/organizations";
import { colors } from "styles/colors";
import { postGroups } from "./client";
import { GroupToRender, convertToGroupToRender } from "./constants";

const NewGroupModal = ({
  isModalOpen,
  setIsModalOpen,
  setGroups,
  allUsers,
}: {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setGroups: React.Dispatch<React.SetStateAction<GroupToRender[]>>;
  allUsers: OrganizationUserDto[];
}) => {
  const [groupName, setGroupName] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [nameError, setNameError] = useState<string | undefined>(undefined);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [error, setError] = useState<string | undefined>(undefined);
  const orgId = useSelector(selectOnlyOrganizationId);
  const navigate = useNavigate();
  const currentUser = useSelector(getCurrentUser);

  const onSelectedInviteesChange = useCallback((invitees: Invitee[]) => {
    setSelectedUsers(invitees.map((invitee) => invitee.id));
  }, []);

  const toGroupDetailPage = useCallback(
    (groupId: string) => {
      navigate({
        pathname: GROUP_PAGE_URL(groupId, "users"),
      });
    },
    [navigate],
  );

  const allInvitees = useMemo(() => {
    return allUsers
      .filter(
        (user) =>
          user.id !== currentUser?.id &&
          user.status !== OrganizationUserStatus.INACTIVE,
      )
      .map(
        (user) =>
          ({
            name: user.name,
            email: user.email,
            type: "user",
            id: user.email, // use email as id based on add member endpoint
          }) as Invitee,
      )
      .sort((a, b) =>
        (a as UserInvitee).email.localeCompare((b as UserInvitee).email),
      );
  }, [allUsers, currentUser?.id]);

  const onNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setGroupName(event?.target?.value || "");
    },
    [],
  );

  const cleanStates = useCallback(() => {
    setGroupName("");
    setNameError(undefined);
    setSelectedUsers([]);
    setError(undefined);
  }, []);

  const validate = useCallback(() => {
    let validated = true;
    if (groupName === "") {
      setNameError("Name is required");
      validated = false;
    }
    return validated;
  }, [groupName]);

  const onCreate = useCallback(async () => {
    if (!validate() || !orgId) {
      return;
    }
    setIsSaving(true);
    try {
      const { group, error } = await postGroups(
        orgId,
        groupName,
        selectedUsers,
      );
      if (group) {
        setGroups((groups: GroupToRender[]) => [
          ...groups,
          convertToGroupToRender(group),
        ]);

        setIsModalOpen(false);
        cleanStates();
        toGroupDetailPage(group.id);
      }
      if (error) {
        setError(error ?? "Failed to create group");
      }
    } catch (e: any) {
      setError(e?.message ?? "Failed to create group");
    } finally {
      setIsSaving(false);
    }
  }, [
    cleanStates,
    groupName,
    orgId,
    selectedUsers,
    setGroups,
    setIsModalOpen,
    toGroupDetailPage,
    validate,
  ]);

  const onCancel = useCallback(() => {
    cleanStates();
    setIsModalOpen(false);
  }, [cleanStates, setIsModalOpen]);

  const parentRef = useRef<HTMLDivElement>(null);

  return (
    <Modal
      title="Create a new group"
      open={isModalOpen}
      onCancel={onCancel}
      destroyOnClose
      footer={null}
      width={450}
      wrapClassName={ModalWrapClass}
    >
      <div className={ModalInnerWrapper} ref={parentRef}>
        <div className={FormWrapper}>
          <FormItem label="Group's name" error={nameError} required={true}>
            <Input
              data-test="group-name-input"
              value={groupName}
              autoFocus={true}
              onChange={onNameChange}
              suffix={
                nameError ? <WarningIcon color={colors.RED_500} /> : <div></div> //need to add this to avoid relayout
              }
            />
          </FormItem>
          <FormItem label="Group's members">
            <SearchAndInviteV2
              allInvitees={allInvitees}
              onSelectedInviteesChange={onSelectedInviteesChange}
              parentRef={parentRef}
              placeholder="Enter user names or emails"
              noResultsMessage={"No matching users"}
              noGrouping={true}
              popoverProps={{
                hasBackdrop: true,
              }}
            />
          </FormItem>
          <FormItem error={error} hidden={!error} />
        </div>
        <div className={FooterWrapperWide}>
          <SecondaryButton
            data-test="cancel-create-group-button"
            onClick={onCancel}
            loading={isSaving}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            type="primary"
            onClick={onCreate}
            data-test="create-group-button"
            loading={isSaving}
            disabled={!groupName}
          >
            Create
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default NewGroupModal;
