import { call } from "redux-saga/effects";
import { updateWidgetProperties } from "legacy/actions/controlActions";
import { callWidgetHooks } from "legacy/sagas/WidgetOperationSagas";

import { WidgetProps } from "legacy/widgets/BaseWidget";
import { GeneratorReturnType } from "store/utils/types";

export function* applyWidgetHooksToAiEdits({
  changes,
  existingWidget,
}: {
  changes: Record<string, unknown>;
  existingWidget: WidgetProps;
}) {
  const previousWidget = {
    ...existingWidget,
    ...changes,
  };
  const updatedWidgets: GeneratorReturnType<typeof callWidgetHooks> =
    yield call(
      callWidgetHooks,
      {
        [existingWidget.widgetId]: previousWidget,
      },
      {
        payload: {
          widgetId: existingWidget.widgetId,
          updates: changes,
        },
        type: updateWidgetProperties.type,
      },
      existingWidget,
    );
  if (updatedWidgets?.[existingWidget.widgetId]) {
    for (const key in previousWidget) {
      if (
        (previousWidget as any)?.[key] !==
        (updatedWidgets?.[existingWidget.widgetId] as any)?.[key]
      ) {
        // copy over changes into dataTreeChanges
        changes[key] = (updatedWidgets?.[existingWidget.widgetId] as any)?.[
          key
        ];
      }
    }
  }
  return changes;
}
