import { useCallback } from "react";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";

import { getWidgetPropertiesById } from "legacy/selectors/propertyPaneSelectors";
import { useAppSelector } from "store/helpers";
import { selectAiState } from "store/slices/ai/selectors";
import { updateAiChanges } from "store/slices/ai/slice";
import { isSubProperty } from "store/slices/ai/utils";
import { AppState } from "store/types";
import { ItemKinds, ItemTypeNonWidget } from "../ItemKindConstants";
import type { ItemKindAccessor } from "../ItemKinds";

export const AiEditsAccessor: ItemKindAccessor<ItemKinds.AI_EDITS> = {
  useItemName: () => {
    return {
      name: "",
      displayName: "",
      editable: false,
      requiresValidation: false,
    };
  },
  itemType: () => ItemTypeNonWidget.AI_EDITS,
  useItemProperties: (itemId: string) => {
    const baseWidget = useAppSelector(
      useCallback(
        (state: AppState) => getWidgetPropertiesById(state, itemId),
        [itemId],
      ),
    );
    const { dataTreeChanges, dependentChangesByWidgetId, selectedWidgetId } =
      useSelector(selectAiState);
    if (itemId === selectedWidgetId) {
      return Object.assign(
        {},
        baseWidget,
        dataTreeChanges,
        dependentChangesByWidgetId,
      ) as any;
    }
    return Object.assign(
      {},
      baseWidget,
      dependentChangesByWidgetId?.[itemId] ?? {},
    ) as any;
  },
  useIsItemPropertyLoading: (itemId: string, propertyName: string) => {
    const { changedKeys, propertiesToChange } = useAppSelector(selectAiState);

    const isInChangeSet =
      changedKeys != null &&
      (changedKeys?.includes(propertyName) ||
        (changedKeys ?? [])?.some((key) => isSubProperty(propertyName, key)));
    if (isInChangeSet) return false;

    const isInExpectedSet =
      propertiesToChange != null &&
      (propertiesToChange?.includes(propertyName) ||
        (propertiesToChange ?? [])?.some((key) =>
          isSubProperty(propertyName, key),
        ));
    return isInExpectedSet;
  },
  updateItemProperties: (
    dispatch: Dispatch<any>,
    properties: any,
    updates: Record<string, unknown>,
  ) => {
    dispatch(
      updateAiChanges({
        updates,
        properties,
      }),
    );
  },
  deleteItemProperties: (
    dispatch: Dispatch<any>,
    properties: any,
    propertyPaths: string[],
  ) => {},
  deleteItem: (dispatch, widgetId, _itemScope, deleteAllSelected) => {
    // not possible
  },
  icon: () => null,
};
