import { getNextEntityName, WidgetProps } from "@superblocksteam/shared";
import { max } from "lodash";
import { type PasteInsertionIndexes } from "hooks/ui/usePasteWidget";
import { WidgetType, WidgetTypes } from "legacy/constants/WidgetConstants";
import { type CopiedWidgets } from "legacy/widgets";
import { type UIBlockConfig } from "store/slices/uiBlocks/types";
import { findFirstParent } from "./findFirstParent";
import type {
  CanvasWidgetsReduxState,
  FlattenedWidgetProps,
} from "legacy/reducers/entityReducers/canvasWidgetsReducer";

const flattenWidgetTree = (
  rootWidget: WidgetProps,
): Array<FlattenedWidgetProps> => {
  const flattenedWidgets: Array<FlattenedWidgetProps> = [];
  const traverse = (currentWidget: WidgetProps) => {
    flattenedWidgets.push({
      ...currentWidget,
      children: currentWidget.children?.map((child) => child.widgetId) ?? [],
    } as FlattenedWidgetProps);

    currentWidget.children?.forEach(traverse);
  };
  traverse(rootWidget);
  return flattenedWidgets;
};

export const createCopyDataFromUIBlock = (
  uiBlock: UIBlockConfig,
): CopiedWidgets => {
  const copiedWidgets: CopiedWidgets = [];

  for (const widget of uiBlock.children) {
    let parentType = WidgetTypes.CANVAS_WIDGET;
    switch (widget.type) {
      case WidgetTypes.PAGE_WIDGET:
        throw new Error("Unsupported widget type");
      case WidgetTypes.CANVAS_WIDGET:
        parentType = WidgetTypes.SECTION_WIDGET;
        break;
      case WidgetTypes.SECTION_WIDGET:
        parentType = WidgetTypes.PAGE_WIDGET;
        break;
      case WidgetTypes.SLIDEOUT_WIDGET:
      case WidgetTypes.MODAL_WIDGET:
        parentType = WidgetTypes.PAGE_WIDGET;
        break;
    }
    const flattenedWidgets = flattenWidgetTree(widget);
    const copiedWidget: CopiedWidgets[number] = {
      widgetId: widget.widgetId,
      type: widget.type as WidgetType,
      parentType,
      list: flattenedWidgets,
      namespace: uiBlock.namespace ?? "Block",
    };
    copiedWidgets.push(copiedWidget);
  }

  return copiedWidgets;
};

export const findClosestCanvas = (
  targetWidgetId: string,
  widgets: CanvasWidgetsReduxState,
  insertionIndexes?: PasteInsertionIndexes,
): CanvasWidgetsReduxState[string] | undefined => {
  const targetWidget = widgets[targetWidgetId];
  if (!targetWidget) return;

  let targetCanvas: CanvasWidgetsReduxState[string] | undefined;
  switch (targetWidget.type) {
    case WidgetTypes.CANVAS_WIDGET:
      targetCanvas = targetWidget;
      break;
    case WidgetTypes.PAGE_WIDGET: {
      const targetSection =
        widgets[
          targetWidget.children?.[
            insertionIndexes?.sectionInsertionPosition ?? 0
          ] || ""
        ];
      targetCanvas =
        widgets[
          targetSection?.children?.[
            insertionIndexes?.columnInsertionPosition ?? 0
          ] || ""
        ];
      break;
    }
    case WidgetTypes.SECTION_WIDGET:
    case WidgetTypes.CONTAINER_WIDGET:
    case WidgetTypes.FORM_WIDGET:
    case WidgetTypes.TABS_WIDGET:
      // gridwidget?
      targetCanvas =
        widgets[
          targetWidget.children?.[
            insertionIndexes?.columnInsertionPosition ?? 0
          ] || ""
        ];
      break;
    default:
      targetCanvas = findFirstParent(
        targetWidget.widgetId,
        widgets,
        (widget) => widget.type === WidgetTypes.CANVAS_WIDGET,
      );
      break;
  }

  return targetCanvas;
};

// can be extended to be "smarter" and find smaller unused numbers, but this is good enough for now
const getCleanNamespaceSuffix = (
  sourceWidget: CopiedWidgets[number],
  entityNames: string[],
) => {
  const namespace = sourceWidget.namespace ?? "Block";

  const candidateRegex = new RegExp(`${namespace}([0-9]+)$`);
  const largestPostfix =
    max(
      entityNames.map((name) => {
        const match = name.match(candidateRegex);
        return match ? parseInt(match[1], 10) : 0;
      }),
    ) ?? 0;

  return `${namespace}${largestPostfix + 1}`;
};

export const renameSourceWidgetsWithNamespace = (params: {
  entityNames: string[];
  sourceWidgets: CopiedWidgets;
}) => {
  const { entityNames, sourceWidgets } = params;

  const renames: Array<[old: string, new: string]> = [];
  const namespaceToSuffix: Record<string, string> = {};

  const allNames = new Set<string>(entityNames);

  sourceWidgets.forEach((sourceWidget) => {
    if (!sourceWidget.namespace) {
      return;
    }

    const suffix =
      namespaceToSuffix[sourceWidget.namespace ?? ""] ??
      getCleanNamespaceSuffix(sourceWidget, entityNames);
    namespaceToSuffix[sourceWidget.namespace ?? ""] = suffix;

    sourceWidget.list.forEach((widget) => {
      let newName = suffix
        ? `${widget.widgetName}_${suffix}`
        : widget.widgetName;
      if (allNames.has(newName)) {
        newName = getNextEntityName(newName, Array.from(allNames));
      }
      allNames.add(newName);

      if (newName !== widget.widgetName) {
        renames.push([widget.widgetName, newName]);
      }
    });
  });

  return renames;
};

export function getInsertionIndex(
  widgetType: WidgetTypes,
  insertionIndexes?: PasteInsertionIndexes,
) {
  if (!insertionIndexes) {
    return undefined;
  }

  let insertionIndexToUse: number | undefined = undefined;
  switch (widgetType) {
    case WidgetTypes.MODAL_WIDGET:
    case WidgetTypes.SLIDEOUT_WIDGET:
      insertionIndexToUse = 0;
      break;
    case WidgetTypes.SECTION_WIDGET:
      insertionIndexToUse = insertionIndexes.sectionInsertionPosition;
      break;
    case WidgetTypes.CANVAS_WIDGET:
      insertionIndexToUse = insertionIndexes.columnInsertionPosition;
      break;
    default:
      insertionIndexToUse = insertionIndexes.stackInsertionPosition;
  }

  return insertionIndexToUse;
}
