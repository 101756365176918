import { Input, InputProps, InputRef } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as SearchIcon } from "assets/icons/common/search.svg";
import { colors } from "styles/colors";

export const useListKeyboardNavigation = ({
  filteredItems,
  defaultFocusedIndex,
  onEscapeKeypress,
  onEnterKeypress,
  onArrowKeyPress,
  onFocused,
  idKey,
}: {
  filteredItems: any[];
  defaultFocusedIndex?: number;
  onEscapeKeypress?: () => void;
  onEnterKeypress?: (itemId: string, event: React.KeyboardEvent) => void;
  onArrowKeyPress?: (index: number, event: React.KeyboardEvent) => void;
  onFocused?: (index: number) => void;
  idKey: string;
}) => {
  const [focusedIndex, setFocusedIndex] = useState<number | undefined>(
    defaultFocusedIndex,
  );

  const filterLength = filteredItems.length;
  useEffect(() => {
    if ((focusedIndex ?? 0) > filterLength - 1) {
      setFocusedIndex(filterLength - 1);
    }
  }, [focusedIndex, filterLength]);

  const clearFocusedIndex = useCallback(() => {
    setFocusedIndex(undefined);
  }, [setFocusedIndex]);

  const resetFocusedIndex = useCallback(() => {
    setFocusedIndex(0);
  }, [setFocusedIndex]);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "ArrowDown") {
        const downIndex = Math.min(
          (focusedIndex ?? -1) + 1,
          filteredItems.length - 1,
        );
        setFocusedIndex(downIndex);
        onFocused?.(downIndex);
        onArrowKeyPress?.(downIndex, e);
      } else if (e.key === "ArrowUp") {
        const upIndex = Math.max(0, (focusedIndex ?? 0) - 1);
        setFocusedIndex(upIndex);
        onFocused?.(upIndex);
        onArrowKeyPress?.(upIndex, e);
      } else if (e.key === "Escape") {
        setFocusedIndex(undefined);
        onEscapeKeypress?.();
      } else if (e.key === "Enter" && typeof focusedIndex === "number") {
        setFocusedIndex(undefined);
        onEnterKeypress?.(filteredItems[focusedIndex]?.[idKey], e);
        // we don't want to do anything else, like run an API
        e.stopPropagation();
        e.preventDefault();
      }
    },
    [
      idKey,
      onFocused,
      focusedIndex,
      setFocusedIndex,
      filteredItems,
      onEscapeKeypress,
      onEnterKeypress,
      onArrowKeyPress,
    ],
  );

  return {
    handleKeyPress,
    focusedIndex,
    clearFocusedIndex,
    resetFocusedIndex,
    setFocusedIndex,
  };
};

export const useScrollToFocusedItem = (
  scrollWrapper: React.MutableRefObject<Element | null>,
) => {
  const scrollItemIntoView = useCallback(
    (itemIndex: number) => {
      if (scrollWrapper.current) {
        const item = scrollWrapper.current.querySelectorAll(
          ".searchable-list-item",
        )?.[itemIndex];
        if (item) {
          item.scrollIntoView({ block: "nearest" });
        }
      }
    },
    [scrollWrapper],
  );
  return scrollItemIntoView;
};

export const SearchableListContainer = styled.div<{
  $width?: string;
  $height?: string;
}>`
  width: ${(props) => (props.$width ? props.$width : "100%")};
  height: ${(props) => (props.$height ? props.$height : "auto")};
  overflow: auto;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const SearchableListResults = styled.div`
  width: 100%;
  height: 400px;
  gap: 2px;
  display: flex;
  flex-direction: column;
`;

const SearchInput = styled(Input)`
  outline: none;

  &&,
  &.ant-input-affix-wrapper-focused,
  &:focus {
    border: none;
    box-shadow: none;
  }

  &.ant-input-affix-wrapper {
    padding: 8px 12px;

    input {
      border-radius: 0;
      font-size: 12px;
      line-height: 16px;
    }

    input::placeholder {
      color: ${colors.GREY_300};
    }
  }
`;

const SearchIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    width: 16px;
    height: 16px;
    color: ${colors.GREY_300};
  }
`;

type SearchInputProps = InputProps & {
  value: string;
  placeholder?: string;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  ["data-test"]?: string;
  showSearchIcon?: boolean;
  style?: React.CSSProperties;
};

export const SearchableListInput = React.forwardRef(
  (
    {
      value,
      placeholder = "Search",
      onSearchChange,
      onKeyPress,
      allowClear,
      showSearchIcon,
      style,
      ...rest
    }: SearchInputProps,
    ref: React.ForwardedRef<InputRef>,
  ) => {
    return (
      <div
        style={{
          borderBottom: `1px solid ${colors.GREY_100}`,
          marginBottom: "8px",
          ...style,
        }}
        className="searchable-list-input"
      >
        <SearchInput
          ref={ref}
          value={value}
          role="search"
          prefix={
            showSearchIcon === true ? (
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
            ) : undefined
          }
          allowClear={allowClear}
          autoFocus
          placeholder={placeholder}
          onChange={onSearchChange}
          onKeyDown={onKeyPress}
          data-test={rest["data-test"]}
          {...rest}
        />
      </div>
    );
  },
);
SearchableListInput.displayName = "SearchableListInput";

export const SearchableListItem = styled.div<{
  isFocused: boolean;
  disabled?: boolean;
  hasPaidTag?: boolean;
}>`
  cursor: ${(props) =>
    props.disabled || props.hasPaidTag ? "not-allowed" : "pointer"};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 6px 10px;
  border-radius: ${({ theme }) => theme.radiuses.base};
  width: 100%;
  font-size: 12px;
  background-color: ${({ theme, isFocused }) =>
    isFocused ? theme.colors.GREY_50 : `transparent`};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  position: relative;
  &:hover {
    background-color: ${({ theme, disabled }) =>
      !disabled ? theme.colors.GREY_50 : "transparent"};
  }

  > svg {
    width: 14px;
  }
  div {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: break-spaces;
  }
`;
